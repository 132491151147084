import { Real } from './real.model';
export class Location {

  public id: string;
  public real: Real | undefined;
  public _self: string | undefined;
  public name: string | undefined;

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.name = obj && obj.name || null;

    if ( obj && obj.item ) {
      this.real =  new Real( obj.item );
    }

    if ( obj && obj.real && obj.real instanceof Real ) {
      this.real =  obj.real;
    }

    if ( obj && obj._self ) {
      this._self = obj._self;
    } else if ( obj && obj._links && obj._links.self && obj._links.self.href ) {
      this._self = obj._links.self.href;
    }

  }

}