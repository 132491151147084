import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderService as Service} from 'src/app/core/models/provider-service.model';
import Swiper from 'swiper';

@Component({
  selector: 'recommended-modal',
  templateUrl: './recommended-modal.component.html',
  styleUrls: ['./recommended-modal.component.scss'],

})
export class RecommendedModalComponent implements OnInit {
  isModalOpen = false;

  recommended!: Observable<Service[]>;
  service: Service | undefined;

  @ViewChild('modalswiper') swiperRef: ElementRef | undefined;
  modalswiper?: Swiper;

  constructor() {
  }

  swiperReady() {
  }

  ngOnInit() {
  }

  dismiss() {
    //this.modalController.dismiss();
    console.log("should dimsiss");
  }

  goToPage( pageIndex: number ) {
    this.modalswiper = this.swiperRef?.nativeElement.modalswiper;
    this.modalswiper?.slideTo( pageIndex );
  }

}
