import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})

export class VideoComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) { }
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  @Input()
  videoSource!: string;
  @Input()
  posterSource!: string;
  @Input()
  videoEmbed!: string;


  ngOnInit() { }

  toggleVideo(event: any) {
    //console.log(event);
    //console.log(this.videoplayer);
    //console.info(this.videoplayer.nativeElement);
    this.videoplayer.nativeElement.play();
  }

  videoEmbedSafeUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.videoEmbed);
  }

  toggleMute() {
    this.videoplayer.nativeElement.muted = !this.videoplayer.nativeElement.muted;
  }

  isMuted() {
    if (!this.videoplayer) return true;
    return this.videoplayer.nativeElement.muted;
  }
}