<swiper-container *ngIf="covers$ | async as covers" #swiper class="swiper" [pagination]="{clickable: true, bulletClass: 'swiper-pagination-bullet nav-bullet', bulletActiveClass:'swiper-pagination-bullet-active nav-active-bullet'}" >


  <swiper-slide *ngFor="let cover of covers" style="left:-0.7px" >
    <div class="slider">
      <div class="block_image" [style.background-color]="cover.topic?cover.topic:'#dddddd'">

        <app-video *ngIf="cover.subtitle && cover.shortLead"
        posterSource="{{ cover.shortLead }}"
        videoSource="https://streamable.com/l/{{ cover.subtitle }}/mp4.mp4"
        videoEmbed="https://streamable.com/e/{{ cover.subtitle }}?autoplay=1&nocontrols=1"></app-video>
        <img *ngIf="!cover.subtitle && cover?.avatar?.url"  src="{{ cover?.avatar?.url}}" alt="{{cover.subtitle}} - {{cover.shortLead}} | dm Studio">
      </div>
      <div class="block_text">
        <b style="width: 80%;">{{ cover?.name}}</b>
        <span class="mt-30" style="width: 80%;">{{ cover?.longLead}}</span>
        <a *ngIf="cover.linkUrl" href="{{cover.linkUrl}}"><div class="shop mt-30 clickable">PREBERITE VEČ</div></a>
      </div>
    </div>
  </swiper-slide>


</swiper-container>