import { Directive, ElementRef, HostListener } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Directive({
  selector: '[dismissModalOnClick]',
})
export class DismissModalOnClickDirective {

  constructor(private elementRef: ElementRef, private modalController: ModalController) {}

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.modalController.dismiss();
  }

}