import { BookingAttendeeBookingService } from './booking-attendee-booking-service.model';
import * as moment from 'moment';
import { Price } from "./cart/price.model"


export class BookingAttendee {
  
  public id: string;
  public bookingAttendeeBookingServices: BookingAttendeeBookingService[];
  public start: string;
  public end: string;
  public durationInSeconds: number;
  public total: Price;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;

    if ( obj && obj.bookingAttendeeBookingServices   ) {
       
       this.bookingAttendeeBookingServices = [];
       
       for (let babs of obj.bookingAttendeeBookingServices ) {

         if ( babs instanceof BookingAttendeeBookingService ) {
           this.bookingAttendeeBookingServices.push( babs );
         } else {
           this.bookingAttendeeBookingServices.push( new BookingAttendeeBookingService( babs ) );
         }

       }

       for (let a of this.bookingAttendeeBookingServices ) {

         if ( !this.start || moment(a.bookingService.start) < moment(this.start) ) {
           this.start = a.bookingService.start;
         }

         if ( !this.end || moment(a.bookingService.end) < moment(this.end) ) {
           this.end = a.bookingService.end;
         }

         let ms = moment(this.start);
         let me = moment(this.end);
         
         this.durationInSeconds = me.diff(ms, 'seconds');


         if ( !this.end || moment(a.bookingService.end) < moment(this.end) ) {
           this.end = a.bookingService.end;
         }

         let priceAmount = ( a.bookingService.price && a.bookingService.price.amount ) ?  a.bookingService.price.amount: 0.00;
         let priceCurrency = ( a.bookingService.price && a.bookingService.price.currency) ?  a.bookingService.price.currency: 'EUR';

         if (!this.total) {
           this.total = new Price( priceAmount, priceCurrency );
         } else {
           this.total.amount += priceAmount;
         }

       }


    }



  }
  
}