import { Component, OnInit, Input} from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cancellation-warning-modal',
  templateUrl: './cancellation-warning-modal.component.html',
  styleUrls: ['./cancellation-warning-modal.component.scss'],
})
export class CancellationWarningModalComponent  implements OnInit {

  //Special version of warning when a similar service booking already exists
  @Input() sameServiceWarning = false;

  constructor(private modalCtrl: ModalController ) { }

  ngOnInit() {}


  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onConfirm() {
    this.modalCtrl.dismiss(true, 'confirm');
  }

}
