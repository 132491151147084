import { Image } from './image.model';
import { SelectionItem } from './selection-item.model';

export class Employee {

  public id: string;
  public itemId: string;
  public name: string;
  public slug: string;
  public shortName: string;
  public shortDescription: string;
  public shortDescriptionTranslations: Object;
  public longDescription: string;
  public longDescriptionTranslations: Object;
  public title: string;
  public avatar: Image | null = null;
  // unverified vars
  public selectionItems: SelectionItem[] = [];
  public public: boolean = true;


  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id && obj.id.toString() || null;

    this.itemId = obj && ( obj.itemId && obj.itemId.toString() || ( obj.item && obj.item.id && obj.item.id.toString()) ) || null;
    this.name = obj && ( obj.name || ( obj.item && obj.item.name) ) || null;
    this.slug = obj && ( obj.slug || ( obj.item && obj.item.slug) ) || null;
    this.shortName = obj && ( obj.shortName || ( obj.item && obj.item.shortName) ) || null;
    this.shortDescription = obj && ( obj.shortDescription || ( obj.item && obj.item.shortDescription ) ) || null;
    this.shortDescriptionTranslations = obj && ( obj.shortDescriptionTranslations || ( obj.item && obj.item.shortDescriptionTranslations ) ) || null;
    this.longDescription = obj && ( obj.longDescription || ( obj.item && obj.item.longDescription ) ) || null;
    this.longDescriptionTranslations = obj && ( obj.longDescriptionshortTranslations || ( obj.item && obj.item.longDescriptionTranslations ) ) || null;
    this.public = obj && ( obj.public || ( obj.item && obj.item.public ) ) || true;


    if ( obj && obj.avatar ) {
      this.avatar = ( obj.avatar instanceof Image ) ? obj.avatar: new Image( obj.avatar );
    } else if ( obj && obj.item && obj.item.avatar ) {
      this.avatar = ( obj.item.avatar instanceof Image ) ? obj.item.avatar: new Image( obj.item.avatar );
    }

    if ( obj && obj.selectionItems ) {
      this.selectionItems = [];
      for (const selectionItem of obj.selectionItems ) {

         if ( selectionItem instanceof SelectionItem ) {
           this.selectionItems.push( selectionItem );
         } else {
           this.selectionItems.push( new SelectionItem( selectionItem ) );
         }

       }

    }


    //this.avatar = obj && ( (obj.avatar && obj.avatar.document && obj.avatar.document.fileName ) || ( obj.item && obj.item.avatar && obj.item.avatar.document && obj.item.avatar.document.fileName ) ) || null;
    this.title = obj?.title || null;

  }

  avatarUrl() {
    // if (this.avatarHref) {
    //   return environment.docsUrl + '/' + this.avatarHref;
    if (this.avatar && this.avatar.url) {
      return this.avatar.url;
    } else {
      return "https://assets.pricepilot.io/img/employees/blank.jpg";
    }
  }


}