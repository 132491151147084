import { Provider } from './provider.model';
import { BookingAttendee } from './booking-attendee.model';
import { BookingSelectionItem } from './booking-selection-item.model';
import { BookingProduct } from './booking-product.model';
import * as moment from 'moment';
import { Price } from "./cart/price.model"
import { User } from "./user.model"
import { Real } from "./real.model"

export class Booking {

  public id: string;
  public start: string;
  public end: string;
  public provider: Provider;
  public canceled: boolean;
  public bookingAttendees: BookingAttendee[];
  public bookingSelectionItems: BookingSelectionItem[];
  public bookingProducts: BookingProduct[];
  public durationInSeconds: number;
  public customerNotes: string;
  public partySize: number;
  public user: User;
  public total: Price;
  public paid: string;
  public isPaid: boolean;
  public paymentRequired: boolean;
  public noShowProtection: boolean;
  public noShowProtected: boolean;
  public status: number;
  public expanded: false;
  public _self: string;

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.start = obj && obj.start || null;
    this.canceled = obj && obj.canceled || null;
    this.customerNotes = obj && obj.customerNotes || null;
    this.partySize = obj && obj.partySize || null;
    this.paid = obj && obj.paid || null;
    this.isPaid = obj && obj.isPaid || null;
    this.paymentRequired = obj && obj.paymentRequired || null;
    this.noShowProtection = obj && obj.noShowProtection || null;
    this.noShowProtected = obj && obj.noShowProtected || null;
    this.status = obj && obj.status || null;

    if ( obj && obj.provider ) {
      this.provider = ( obj.provider instanceof Provider ) ? obj.provider : new Provider( obj.provider );
    }

    if ( obj && obj.user ) {
      this.user = ( obj.user instanceof User ) ? obj.user : new User( obj.user );
    }

    if ( obj && obj.bookingAttendees   ) {

       this.bookingAttendees = [];

       for (const ba of obj.bookingAttendees ) {

         if ( ba instanceof BookingAttendee ) {
           this.bookingAttendees.push( ba );
         } else {
           this.bookingAttendees.push( new BookingAttendee( ba ) );
         }

       }
    }

    if ( obj && obj.bookingSelectionItems   ) {

       this.bookingSelectionItems = [];

       for (const bsi of obj.bookingSelectionItems ) {

         if ( bsi instanceof BookingSelectionItem ) {
           this.bookingSelectionItems.push( bsi );
         } else {
           this.bookingSelectionItems.push( new BookingSelectionItem( bsi ) );
         }

       }
    }

    if ( obj && obj.bookingProducts   ) {

       this.bookingProducts = [];

       for (const bp of obj.bookingProducts ) {

         if ( bp instanceof BookingProduct ) {
           this.bookingProducts.push( bp );
         } else {
           this.bookingProducts.push( new BookingProduct( bp ) );
         }

         if (!this.total) {
           this.total = new Price( (bp.price * bp.quantity), bp.currency );
           console.log(bp);
         } else {
           this.total.amount += bp.price * bp.quantity;
         }


       }
    }


    if ( obj && obj._self ) {
      this._self = obj._self;

    } else if ( obj && obj._links && obj._links.self && obj._links.self.href ) {
      this._self = obj._links.self.href;
    }

    for (const a of this.bookingAttendees ) {

       if ( !this.end || moment(a.end) < moment(this.end) ) {
         this.end = a.end;
       }

       const ms = moment(this.start);
       const me = moment(this.end);

       this.durationInSeconds = me.diff(ms, 'seconds');

       if (!this.total) {
         this.total = new Price( a.total?.amount || 0, a.total?.currency || 'EUR' );
       } else {
         this.total.amount += a.total.amount;
       }

     }

  }

  getLocation() {

    if ( this.bookingAttendees &&
      this.bookingAttendees[0] &&
      this.bookingAttendees[0].bookingAttendeeBookingServices &&
      this.bookingAttendees[0].bookingAttendeeBookingServices[0] &&
      this.bookingAttendees[0].bookingAttendeeBookingServices[0].bookingService &&
      this.bookingAttendees[0].bookingAttendeeBookingServices[0].bookingService.location
    ) {
      return this.bookingAttendees[0].bookingAttendeeBookingServices[0].bookingService.location;
    }

    return null;

  }


  getRootReal() {

    if ( this.bookingSelectionItems &&
      this.bookingSelectionItems[0] &&
      this.bookingSelectionItems[0].selectionItem  &&
      this.bookingSelectionItems[0].selectionItem.real  &&
      this.bookingSelectionItems[0].selectionItem.real.parent &&
      this.bookingSelectionItems[0].selectionItem.real.parent.parent
    ) {
      return this.bookingSelectionItems[0].selectionItem.real.parent.parent;
    }

    return null;

  }


  // Returns full shop name also for chains with more than one location, for example: Lassana Celovška 49,
  // where Celovška 49 is a chain's location name and Lassana is a brand name
  getShopName(): string {

    const location = this.getLocation();

    if (!location || !location.real) {
      throw new Error('Location is not set.');
    }

    if (!this.provider || !this.provider.brandName) {
      throw new Error('Provider is not set.');
    }

    if ( !this.provider.brandName ) {
      throw new Error('Provider.brandName is not set.');
    }

    let shopName = location.real.name;

    if ( shopName !== this.provider.brandName && this.provider.brandName !== 'dm Studio' && this.provider.brandName !== 'DERMACENTER' ) {
      shopName = this.provider.brandName + ' ' + shopName;
    }

    return shopName;

  }

  getShopImage(): string | null {

    const real = new Real( this.getLocation() );

    if (!real) {
      throw new Error('Location is not set.');
    }

    if (!real.avatar || !real.avatar.url) {
      return 'https://assets.pricepilot.io/img/locations/blank.jpg';
    }

    return real.avatar.url;

  }


}