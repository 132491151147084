import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, Inject, HostListener } from '@angular/core';
import { Provider } from '../../core/models/provider.model';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, tap } from 'rxjs';
import { ProviderService } from 'src/app/core/models/provider-service.model';
import { CartService } from 'src/app/core/services/cart.service';
import { PPAuthService } from '../../core/services/auth.service';
import { User } from '../../core/models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent  implements OnInit {
  @Input() provider?: Provider;

  me$: Observable<User | null>;

  currentFieldSlug: string | null = null;
  currentPathSlug: string | null = null;

  private paramMapSubscription: Subscription;

  private routeSub: Subscription;

  constructor(
    private route: ActivatedRoute, private cartService: CartService,
    private authService: PPAuthService,
    private router: Router) {

    this.authService.setMe();
    //console.log('Set me in constructor of header');
    this.me$ = authService.me2$;

    this.paramMapSubscription = this.route.paramMap.subscribe(params => {
      this.currentFieldSlug= params.get('fieldSlug');
      //console.log(params);
    });

    this.routeSub = this.route.url.subscribe(urlSegments => {
      this.currentPathSlug = urlSegments.map(segment => segment.path).join('/');
    });

  }

  ionViewWillEnter() {
    this.authService.setMe();
  }

  logout(event: any) {
    //console.log('logout');
    this.authService.logout();
    this.router.navigate( [''] );
  }


  ngOnInit() {
    //console.log('ng on init in header');
    this.authService.setMe();
  }

  ngOnDestroy() {
    this.paramMapSubscription.unsubscribe();
    this.routeSub.unsubscribe();
  }

}