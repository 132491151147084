export class Links {
  public self: { href: string; } | undefined;

  constructor(
    obj?: any
  ) {
    this.self = obj && obj._self || null;
  }

  static revive(json: any): Links {
    return new Links(json);
  }
}