import { Price } from "./price.model"

import { CombinationAttendee } from "./combination-attendee.model"

export class Combination {
  public start: string;
  public end: string;
  public durationInSeconds: number;
  public price: Price | undefined;
  // public selectionItems: CombinationSelectionItem[] | undefined; //think about renaming the field... table vs combinationSelectionItem...
  // public products: CombinationProduct[] | undefined;
  public attendees: CombinationAttendee[] | undefined;

  public eventPreference: string; //0 cannot decide yet
  public regularOfferAvailable: boolean;

  public events: string[];


  setEventPreference( eventPreference: string ) {
    this.eventPreference = eventPreference;
  }

  setRegularOfferAvailable( regularOfferAvailable: boolean ) {
    this.regularOfferAvailable = regularOfferAvailable;
  }

  constructor(
    obj?: any
  ) {
    // console.log( 'Combination constructor', obj );
    this.start = obj && obj.start || null;
    this.end = obj && obj.end || null;
    this.durationInSeconds = obj && obj.durationInSeconds || null;

    this.eventPreference = obj && obj.eventPreference || null;
    this.regularOfferAvailable = obj && obj.regularOfferAvailable || null;

    this.events = obj && obj.events || null;

    if ( obj && (obj.price || obj.price === 0) ) {
      this.price = new Price( obj.price, obj.currency ? obj.currency : 'EUR' );
    }

    if ( obj && obj.attendees ) {
       this.attendees = [];
       for (const attendee of obj.attendees ) {
        // console.log( 'attendee', attendee );
         if ( attendee instanceof CombinationAttendee ) {
           this.attendees.push( attendee );
         } else {
           this.attendees.push( new CombinationAttendee( attendee ) );
         }
       }
      //  console.log( 'this.attendees', this.attendees );
    }

    // if ( obj && obj.selectionItems ) {
    //    this.selectionItems = [];
    //    for (let selectionItem of obj.selectionItems ) {

    //      if ( selectionItem instanceof CombinationSelectionItem ) {
    //        this.selectionItems.push( selectionItem );
    //      } else {
    //        this.selectionItems.push( new CombinationSelectionItem( selectionItem ) );
    //      }

    //    }
    // }

    // if ( obj && obj.products ) {
    //    this.products = [];

    //    for (let selectionItem of obj.selectionItems ) {

    //      if ( selectionItem instanceof CombinationSelectionItem ) {
    //        this.selectionItems.push( selectionItem );
    //      } else {
    //        this.selectionItems.push( new CombinationSelectionItem( selectionItem ) );
    //      }

    //    }

    // }

  }

  static revive( json: any ): Combination {
    const attendees = json.attendees.map( ( attendee: CombinationAttendee ) => CombinationAttendee.revive( attendee ) );
    const price:Price | undefined = json.price ? Price.revive( json.price ) : undefined;
    const combination = new Combination( {...json} );
    //console.log( 'combination', combination);
    combination.attendees = attendees;
    combination.price = price;
    //console.log( 'combination', combination);
    return combination;
    //return new Combination( {...json, attendees, price} );
  }
}
