<ng-container *ngIf="pick?.combination as combination">

  <div class="content nopadding">
    <div class="smallblock yellow">
      <div class="info">
        <div class="date">03/03</div>
        <div class="circle"><b class="checked clickable" [routerLink]="['/', 'narocanje', 'izberi-lokacijo']"></b><b class="checked clickable" [routerLink]="['/', 'narocanje', 'izberi-termin']"></b><b class="sel"></b></div>
        <div class="name">PREDOGLED REZERVACIJE</div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="saloni">
      <h1 class="left mb-30">Predogled rezervacije</h1>

      <div class="calendar">
        <div class="info_2 pin"><label>IZBRANA LOKACIJA</label>{{ real?.name || '' }}</div>
        <div class="info_2 calend mt-20 mb-40"><label>IZBRAN TERMIN</label>{{ getDate(combination && combination.attendees && combination.attendees[0].start) }}</div>
        <ng-container *ngFor="let attendee of combination.attendees; index as i">

          <div class="calendartitle mb-20">{{ pick.attendees[i].name }}<b>Začetek ob: {{ getTime(attendee.start) }}</b></div>

          <div class="calendarblock blue mb-30" *ngFor="let service of attendee.services; index as j" [style.border-color]="pick.attendees[i].findService(service.id).borderColor">
            <div class="header">
              <img ngSrc="{{pick.attendees[i].findService(service.id).avatarUrl()}}" width="56" height="56" alt="">
              <div class="info infoflex">
                <b>{{ pick.attendees[i].findService(service.id).name }}</b>
                <ng-container *ngIf="real?.lastMinute && !service.specialOffer; then lastMinute; else notLastMinute">
                </ng-container>
                <ng-template #lastMinute>
                  <div class="discount" *ngIf="service.discount>0">{{pick.attendees[i].findService(service.id).regularPrice1}} EUR</div>
                  <div class="price"><div>{{((service.price?.amount || 0) * (100 - service.discount) / 100) | currency: service.price?.currency:'':'1.2-2':'sl'}}</div> {{service.price?.currency}}</div>
                </ng-template>
                <ng-template #notLastMinute>
                  <div class="discount" *ngIf="pick.attendees[i].findService(service.id).isDiscountAvailable()">{{pick.attendees[i].findService(service.id).regularPrice1}} EUR</div>
                  <div class="price"><div>{{((service.price?.amount || 0) * (100 - pick.attendees[i].findService(service.id).discount) / 100) | currency: service.price?.currency:'':'1.2-2':'sl'}}</div> {{service.price?.currency}}</div>
                </ng-template>
              </div>
            </div>
            <div class="line" *ngIf="getEmployeeFromEmployeeHref( service.employeeHref ) as employee" style="width:100%;display:block;"><b>{{getTime(service.start)}}</b><div style="line-height: 48px;">
              <img src="{{employee?.avatar?.url}}" style="display: block;">{{employee.name}}</div>
            </div>
          </div>

        </ng-container>

        <div class="calendarblock mt-10" style="min-height: 100px;">
          <div class="total">
            <b>KONČNI ZNESEK</b>
            <div class="price"><div>{{combination.price?.amount | currency: combination.price?.currency:'':'1.2-2':'sl'}}</div> {{combination.price?.currency}}</div>
            <div class="discount">Prihranili ste: {{getFullDiscount() | currency: combination.price?.currency:'':'1.2-2':'sl' }} EUR</div>
          </div>
        </div>

        <div class="mt-20" style="clear: both;">
          <ion-textarea label="Vaše opombe" class="custom"  labelPlacement="floating" fill="outline" placeholder="Vaše opombe" [(ngModel)]="pick.customerNotes" name="customer_notes" id="customer_notes"></ion-textarea>
        </div>

        <div class="mt-20 replacement">
          <input [(ngModel)]="pick.allowReplacement" type="checkbox" name="allow_replacement" id="allow_replacement">
          <label for="allow_replacement">Dovolim menjavo stilista v primeru odsotnosti?</label>
        </div>

      </div>
    </div>
  </div>

  <!--ion-button [routerLink]="['/', 'narocanje', 'uspesno']">Rezerviraj</!--ion-button-->

</ng-container>
