export class Price {
  public amount: number;
  public currency: string;

  constructor( amount: number, currency: string = 'EUR' ) {
    this.amount = amount;
    this.currency = currency;
  }

  static revive(json: any): Price {
    const price: Price = new Price( json.amount, json.currency );
    return price;
  }
}