import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import {BehaviorSubject, filter, map, Observable, Subscription} from 'rxjs';

import * as moment from 'moment';
import 'moment/locale/sl';
import Swiper from 'swiper';
import {Real} from "../../../core/models/real.model";
import {Day} from "../../../core/models/day.model";
import {List} from "../../../core/models/list.model";
import {tap} from "rxjs/operators";


@Component({
  selector: 'app-days-calendar',
  templateUrl: './days-calendar.component.html',
  styleUrls: ['./days-calendar.component.scss'],
})
export class DaysCalendarComponent implements OnInit {

  // @Input() days$: Observable<List> | undefined;
  private subscription: Subscription;
  @Input() set days$(value: Observable<List> | undefined) {
    this._days$.next(value);
  }
  get days$(): Observable<List> | undefined {
    return this._days$.getValue();
  }
  private _days$ = new BehaviorSubject<Observable<List> | undefined>(undefined);

   currentList: List | null = null;
  // private fakeCurrentList = {
  //   "totalCount": 10,
  //   "latitude": null,
  //   "longitude": null,
  //   "radius": null,
  //   "items": [
  //     {
  //       "events": null,
  //       "date": "2024-10-15",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-15T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-16",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-16T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-17",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-17T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-18",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-18T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-19",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-19T14:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-20",
  //       "hasSlots": null,
  //       "isOpen": null,
  //       "priceRange": {},
  //       "workingUntil": null,
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-21",
  //       "hasSlots": null,
  //       "isOpen": null,
  //       "priceRange": {},
  //       "workingUntil": null,
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-22",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-22T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-23",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-23T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     },
  //     {
  //       "events": null,
  //       "date": "2024-10-24",
  //       "hasSlots": true,
  //       "isOpen": true,
  //       "priceRange": {
  //         "min": 130,
  //         "max": 130
  //       },
  //       "workingUntil": "2024-10-24T20:00:00+0200",
  //       "hourMin": null,
  //       "hourMax": null
  //     }
  //   ],
  //   "_embedded": null
  // }

  @Input() selectedDay: string | undefined;
  private lastSelectedDay: string | undefined;
  @Input() real: Real | undefined;
  @Input() hasLastMinuteSlots: Map<string, boolean> | undefined;
  @Output() onDaySelected = new EventEmitter<Day>();
  @Output() waitlistChanged = new EventEmitter<Day[]>();
  @Output() clearWaitlistEvent = new EventEmitter<void>();

  @ViewChild('slidesElem') swiperRef: ElementRef | undefined;
  swiper?: Swiper;


  ionChangeTriggered = false;
  loading = false;

  selectedWaitlist: Day[] = [];

  slideOpts = {

    scrollbar: false,
    navigation: false,
    pagination: false,
    //slidesPerView: 11,
    //spaceBetween: 40,
    slidesPerGroup: 3,
    //paginationClickable: true,

    breakpoints: {
      1000: {
        slidesPerView: 8,
        spaceBetween: 10
      },
      500: {
        slidesPerView: 4,
        spaceBetween: 10
      },
      359: {
        slidesPerView: 3,
        spaceBetween: 10
      },
      319: {
        slidesPerView: 2,
        spaceBetween: 10
      }
    }

  };


  constructor() { }

  ngOnInit() {
    this._days$.subscribe(days$ => {
      if (days$) {
        days$.subscribe(list => {

          this.currentList = list;

          // // fake hasSlots
          // try {
          //
          //   // for (let i = 0; i < 6; i++) {
          //   //   list.items[i].hasSlots = false;
          //   // }
          // } catch (e) {
          //
          // }

          // console.log('days', list);
        });

      }
    });



    if (this.selectedDay) {
      this.lastSelectedDay = this.selectedDay;
    }
  }

  onWaitlistChange(event: any, day: Day) {
    event.stopPropagation();
    this.ionChangeTriggered = true;
    if (this.selectedDay) {
      this.lastSelectedDay = this.selectedDay;
      this.selectedDay = undefined;
    }
    if (event.detail.checked) {
      this.selectedWaitlist.push(day);
    } else {
      this.selectedWaitlist = this.selectedWaitlist.filter(d => d !== day);
    }
    if (this.selectedWaitlist.length === 0) {
      this.selectedDay = this.lastSelectedDay;
    }
    this.waitlistChanged.emit(this.selectedWaitlist);
  }

  clearWaitlist() {
    this.selectedWaitlist = [];
    this.waitlistChanged.emit(this.selectedWaitlist);
    console.log('clearWaitlist',this.selectedDay, this.lastSelectedDay);
    this.selectedDay = this.lastSelectedDay;
  }


  isDayInWaitlist(day: Day): boolean {
    return this.selectedWaitlist.includes(day);
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('waitlist')) {
      event.stopPropagation();
    }
  }

  getFirstAvailableDay(): Day | undefined {
    if (!this.currentList || !this.currentList.items) {
      return undefined;
    }

    return (this.currentList.items as Day[]).find(day => day.isOpen && day.hasSlots);
  }

  selectDay( day: Day ) {
    if (this.ionChangeTriggered) {
      this.ionChangeTriggered = false;
      return;
    }
    this.clearWaitlist();
    this.selectedDay = day.date;
    // console.log(this.selectedDay);
    // console.log(day);
    this.onDaySelected.emit( day );
  }

  getDayOfWeek( dateString: moment.MomentInput ) {
    return moment( dateString ).format('ddd').substr(0, 3);
  }

  getDayOfMonth( dateString: moment.MomentInput ) {
    return moment( dateString ).format('D');
  }

  getMonth( dateString: moment.MomentInput ) {
    return moment( dateString ).format('MMM');
  }

  getMonthAndYear( dateString: moment.MomentInput ) {
    return moment( dateString ).format('MMMM') + ' ' + moment( dateString ).format('Y');
  }

  slideRight() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper?.slideNext();
  }

  slideLeft() {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper?.slidePrev();
  }

  isLastMinute(day: Day) {
    if (this.real) {
      if (this.real.lastMinute) {
        return moment(day.date).isSame(moment(), 'day');
      }
      return false;
      // return this.real.lastMinute;
    } else {
      return false;
    }
    // return moment(day.date).isBefore(moment(moment().add(this.real.lastMinutePeriodInMinutesFromNow,'minutes')));
    // return moment(day.date).isSame(moment(), 'day');
  }

  title(day: Day) {
    if (day.isOpen) {
      if (day.hasSlots) {
        if (this.isLastMinute(day)) {
          return 'Last Minute';
        } else {
          return "Na voljo";
        }
      } else {
        return "Zasedeno";
      }
    } else {
      return "Zaprto";
    }
  }

  isBeforeFirstAvailableDay(day: Day) {
    const firstAvailableDay = this.getFirstAvailableDay();
    if (firstAvailableDay) {
      return moment(day.date).isBefore(moment(firstAvailableDay.date));
    } else {
      return false;
    }
  }

  hasUnavailableSlots() {
    return this.currentList?.items?.some(day => day.isOpen && !day.hasSlots);
  }
}
