import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { PlacesService } from '../../core/services/places.service';
import { Place } from '../../core/models/search-preferences/place.model';
import { SearchPreferences } from '../../core/models/search-preferences/search-preferences.model';


import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';

@Component({
  selector: 'app-place-pick-modal',
  templateUrl: './place-pick-modal.component.html',
  styleUrls: ['./place-pick-modal.component.scss'],
})
export class PlacePickModalComponent implements OnInit, OnDestroy {

  places$: Observable<Array<any>>;

  @Input() searchPreferences: SearchPreferences | undefined;

  searchFilter$ = new BehaviorSubject<string>('');

  constructor(
    private modalCtrl: ModalController,
    private placesService: PlacesService,
  ) {

     /*this.geolocation.getCurrentPosition().then((resp) => {
       console.log(resp);
       // resp.coords.latitude
       // resp.coords.longitude
      }).catch((error) => {
        console.log('Error getting location', error);
      });*/


    this.places$ = this.searchFilter$.pipe(
      flatMap( searchString =>  {
        return this.placesService.getBySearchString( searchString );
      })
    )

  }

  ngOnInit() {
    console.log('ngOnInit');
  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onSelected( place: Place | null ) {
    this.modalCtrl.dismiss( place, 'picked' );
  }

  ngOnDestroy() {
  }

}