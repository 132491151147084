import { Real } from './real.model';
import { Selection } from './selection.model';
import { Location } from './location.model';
import { Employee } from './employee.model';

export class SelectionItem {

  public id: string;
  public title: string | undefined;
  public _self: string | undefined;
  public item: Employee | undefined;
  public selection: Selection | undefined;
  public locations: Location[] = [];

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.title = obj?.title || null;
    if (obj?.item instanceof Employee) {
      this.item = obj.item;
    } else {
      this.item = new Employee(obj?.item);
    }
    if (obj?.selection instanceof Selection) {
      this.selection = obj.selection;
    } else {
      this.selection = new Selection(obj?.selection);
    }

    if ( obj && obj._self ) {
      this._self = obj._self;
    } else if ( obj && obj._links && obj._links.self && obj._links.self.href ) {
      this._self = obj._links.self.href;
    }

    if ( obj.locations ) {
      this.locations = [];
      for (let location of obj.locations ) {

        if ( location instanceof Location ) {
          this.locations.push( location );
        } else {
          this.locations.push( new Location( location ) );
        }
      }
    }

  }

}