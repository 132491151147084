<ion-content>
    <ion-buttons class="ion-float-right">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>


  <div class="phone-modal" *ngIf="sameServiceWarning">
    <div  class="ion-padding">

      <h1 class="phone-modal__form-title" style="padding:12px">Podobno naročilo že obstaja!</h1>

      <p class="phone-modal__form-desc" style="padding:12px"> Na to storitev ste že naročeni. Ste prepričani, da želite opraviti še eno rezervacijo?</p>

      <p class="phone-modal__form-desc" style="padding:12px">Če na prvi termin ne utegnete, vas prosimo, da ga čim prej prekličete in sprostite mesto za druge stranke. To storite v svojem profilu.</p>


      <!--ion-button class="btn--large" style="--border-radius: 0;" >Moja pomota, prav imate.</ion-button>

      <ion-button class="btn--large" style="--border-radius: 0;" color="secondary">Vseeno se želim naročiti</ion-button-->

      <div class="content">
        <div class="saloni mt-0 pt-0">
          <div class="buttons" style="flex-wrap: wrap;">
            <div class="button_1 mt-30 clickable" style="white-space: nowrap;"  (click)="onCancel()">MOJA POMOTA, PRAV IMATE</div>
            <div class="button_text mt-30"></div>
            <!--div class="button_2 mt-30 sel clickable"  [routerLink]="['/', 'narocanje', 'uspesno']">ZAKLJUČI REZERVACIJO</!--div-->
            <div class="button_2 mt-30 sel clickable" style="white-space: nowrap;"  (click)="onConfirm()">VSEENO SE ŽELIM NAROČITI</div>
          </div>
        </div>
      </div>

    </div>
  </div>


<div class="phone-modal" *ngIf="false && !sameServiceWarning">
    <div  class="ion-padding">

      <h1 class="phone-modal__form-title" style="padding:12px">POLITIKA ODPOVEDI</h1>

      <p class="phone-modal__form-desc" style="padding:12px">V primeru, da na termin ne boste utegnili, vas prosimo, da se pravočasno odjavite – vsaj 2 dni pred terminom.</p>

      <p class="phone-modal__form-desc" style="padding:12px">Tako sprostite mesto za druge stranke.</p>

      <!--ion-button class="btn--large" style="--border-radius: 0;" >Moja pomota, prav imate.</ion-button>

      <ion-button class="btn--large" style="--border-radius: 0;" color="secondary">Vseeno se želim naročiti</ion-button-->

      <div class="content">
        <div class="saloni mt-0 pt-0">
          <div class="buttons">
            <!--div class="button_1 mt-30 clickable" (click)="onCancel()">Moja pomota, prav imate.</div-->
            <div class="button_text mt-30"></div>
            <!--div class="button_2 mt-30 sel clickable"  [routerLink]="['/', 'narocanje', 'uspesno']">ZAKLJUČI REZERVACIJO</!--div-->
            <div class="button_2 mt-30 sel clickable" style="white-space: nowrap;" (click)="onConfirm()">RAZUMEM IN SE STRINJAM</div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="phone-modal" *ngIf="!sameServiceWarning">
    <div  class="ion-padding">

      <h1 class="phone-modal__form-title" style="padding:12px">POKLIČITE PO TELEFONU</h1>

      <p class="phone-modal__form-desc" style="padding:12px">Zaradi velike zasedenosti prosimo pokličite na <a href="tel:+38615132229">01 513 22 29</a> za rezervacijo termina!</p>

      <!--ion-button class="btn--large" style="--border-radius: 0;" >Moja pomota, prav imate.</ion-button>

      <ion-button class="btn--large" style="--border-radius: 0;" color="secondary">Vseeno se želim naročiti</ion-button-->

      <div class="content">
        <div class="saloni mt-0 pt-0">
          <div class="buttons">
            <!--div class="button_1 mt-30 clickable" (click)="onCancel()">Moja pomota, prav imate.</div-->
            <div class="button_text mt-30"></div>
            <!--div class="button_2 mt-30 sel clickable"  [routerLink]="['/', 'narocanje', 'uspesno']">ZAKLJUČI REZERVACIJO</!--div-->
            <a href="tel:+38615132229"><div class="button_2 mt-30 sel clickable" style="white-space: nowrap;">01 513 22 29</div></a>
          </div>
        </div>
      </div>

    </div>
  </div>

</ion-content>