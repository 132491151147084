import { Component, OnInit, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import Swiper from 'swiper';

import { HttpClientModule } from '@angular/common/http';
import { Article } from '../../core/models/article.model';
import { ArticleService } from '../../core/services/article.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [ ArticleService ]
})
export class SliderComponent  implements OnInit {

  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  covers$: Observable<Article[]>;

  constructor(
    private articleService: ArticleService,
  ) {
    this.covers$ = this.articleService.getArticles$( null, true );
  }

  ngOnInit() {}

  swiperReady() {

    console.log('fooooo');
    //this.swiper = this.swiperRef?.nativeElement;
    //console.log( this.swiper );
  }

  goToPage( pageIndex: number ) {

    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper?.slideTo( pageIndex );

  }

}
