import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'removeEmptyParagraphs'
})
export class RemoveEmptyParagraphsPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string | undefined): SafeHtml {
    if (!value) {
      return '';
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;

    Array.from(tempDiv.querySelectorAll('p')).forEach(p => {
      if (p.innerHTML.trim() === '<br>') {
        p.remove();
      }
    });

    return this.sanitizer.bypassSecurityTrustHtml(tempDiv.innerHTML);
  }

}
