import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';

@Component({
  selector: 'app-salon-slider',
  templateUrl: './salon-slider.component.html',
  styleUrls: ['./salon-slider.component.scss'],
})
export class SalonSliderComponent implements OnInit {
  @ViewChild('salon-swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  @Input() avatar;
  @Input() images;

  constructor() {}

  ngOnInit() {}

  swiperReady() {
    console.log('fooooo');
    //this.swiper = this.swiperRef?.nativeElement;
    //console.log( this.swiper );
  }

  goToPage(pageIndex: number) {
    this.swiper = this.swiperRef?.nativeElement.swiper;
    this.swiper?.slideTo(pageIndex);
  }
}
