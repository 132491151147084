
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button dismissModalOnClick>✖</ion-button>
    </ion-buttons>
    <ion-title>PRIPOROČENE STORITVE</ion-title>
  </ion-toolbar>
</ion-header>
<div class=" modal-content nopadding">
  <div class="items">
      <swiper-container #modalswiper class="modalswiper" [slidesPerView]="2" navigation="true" scrollbar="false" mousewheel="true">
        <ng-container *ngIf="service?.recommendations as items">
          <swiper-slide *ngFor="let item of items">
            <app-service-card dismissModalOnClick  class="item"
            [style.border-color]="item.borderColor"
            [service]="item"></app-service-card>
          </swiper-slide>
        </ng-container>
      </swiper-container>
  </div>
</div>
