// substring.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substring'
})
export class SubstringPipe implements PipeTransform {
  transform(value: string, start: number, end?: number): string {
    if (!value) return '';

    // If end is not provided, use the length of the string
    end = end || value.length;

    return value.substring(start, end);
  }
}
