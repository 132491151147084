export class PriceRange   {

  public lowestPrice: number;
  public varyPrices: boolean;

  constructor(
    obj?: any
  ) {
    this.lowestPrice = obj && obj.lowestPrice || 0;
    this.varyPrices = obj && obj.varyPrices || null;

  }

  static revive(json: any): any {
    return new PriceRange(json);
  }

}