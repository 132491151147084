import { Location } from './location.model';
import { Price } from './cart/price.model';
import { Worker } from './worker.model';
import { Interval } from './interval.model';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';

export class BookingSelectionItem {
  
  public id: string;
  public price: Price;
  public start: string;
  public end: string;
  public selectionItem: Location;

  
  public discountPercentage: number;

  //derived data
  public durationInSeconds: number;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.start = obj && obj.start || null;
    this.end = obj && obj.end || null;
    this.discountPercentage = obj && obj.discountPercentage || null;

    if ( obj && undefined !== obj.price ) {
      this.price = ( obj.price instanceof Price ) ? obj.price : ( new Price( obj.price, obj.currency ? obj.currency : 'EUR' ) );
    }

    if ( obj && obj.selectionItem ) {
      this.selectionItem = ( obj.selectionItem instanceof Location ) ? obj.selectionItem: new Location( obj.selectionItem );
    }

    this.refreshDerivedData();
   
  }


  setStart( newStart, fixedDuration = false ) {

    if ( !this.start || !this.end ) {
      throw new Error('You can only set a new start if previous start and end are set!');
    }


    this.start = newStart;

    if ( fixedDuration ) {

      const ms = moment(this.start);
      const newEndM = ms.add( this.durationInSeconds, 'seconds' );
      this.end = newEndM.toISOString();

    }


    this.refreshDerivedData();

  }

  setInterval( interval: Interval ) {

    this.start = interval.from; 
    this.end = interval.to; 
    this.refreshDerivedData();

  }

  refreshDerivedData() {

    if ( this.start && this.end ) {
      let ms = moment(this.start);
      let me = moment(this.end);
      this.durationInSeconds =  me.diff(ms, 'seconds');
    }

  }

  
}