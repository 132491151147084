import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modals = new Array<any>;

  constructor(
    private modalController: ModalController,
 ) {}

  async presentModal(component: any, cssClass?: any, componentProps?: any): Promise<void> {
    const modal = await this.modalController.create({
      component,
      cssClass,
      componentProps
    });

    this.modals.push(modal);

    await modal.present();
  }

  async closeAllModals() {
    console.dir(this.modals);
    for (var i=0; i<this.modals.length; i++) {
      this.modals[i].dismiss();
    }
  }
}
