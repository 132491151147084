export class ProviderUser {

  public id: string;
  public promotionsViaEmail: boolean
  public promotionsViaSms: boolean
  public cancellationWarning: boolean = false

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.promotionsViaEmail = obj && obj.promotionsViaEmail || false;
    this.promotionsViaSms = obj && obj.promotionsViaSms || false;
    this.cancellationWarning = obj && obj.cancellationWarning || false;
  }
}