import { Component, OnInit, Input } from '@angular/core';
import { Pick } from '../../../core/models/cart/pick.model';
import { Real } from '../../../core/models/real.model';
import { ShopDetailService } from '../../../core/services/shop-detail.service';
import { Employee } from '../../../core/models/employee.model';
import * as moment from 'moment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit {

  @Input() pick: Pick;
  @Input() real: Real;
  employees: Employee[];
  employeesMap: Map<string,Employee> = new Map();

  constructor( private shopDetailService: ShopDetailService ) {
    shopDetailService.employees$.subscribe(
      employees => {
        this.employees = employees;
        this.employeesMap = this.shopDetailService.generateEmployeesMap( employees );
      }
    );
  }


  //@todo refactor -> similar method in slots.component
  getEmployeeFromEmployeeHref( href: string ) {

    if (!this.employeesMap) {
      return null;
    }

    const employeeId = href.substr( href.lastIndexOf("/") + 1 );
    if ( this.employeesMap?.get(employeeId)) {
      return this.employeesMap?.get(employeeId);
    }

    return null;
  }

  ngOnInit() {
  }

  getFullDiscount() {
    let discount = 0;
    if(this.isLastMinuteAtTime(this.pick.combination?.start || '')) {
      // last minute
      this.pick.combination?.attendees?.forEach((attendee) => {
        attendee.services?.forEach((combinationservice) => {
          if (
            this.isLastMinuteAtTime(combinationservice.start) &&
            combinationservice.discount > 0
          ) {
            discount +=
              (combinationservice?.price?.amount || 0) -
              ((combinationservice?.price?.amount || 0) *
                (100 - combinationservice?.discount)) /
                100;
          }
        });
      });
    } else {
      this.pick.attendees.forEach((attendee) => {
        attendee.services.forEach((attendeeservice) => {
          if (attendeeservice.isDiscountAvailable()) {
            discount +=
              attendeeservice.regularPrice1 -
              (((attendeeservice?.minimalPrice?.lowestPrice || 0) *
                (100 - attendeeservice?.discount)) /
                100 ?? 0);
          } else if (attendeeservice.discount > 0) {
            discount +=
              ((attendeeservice?.minimalPrice?.lowestPrice || 0) *
                attendeeservice.discount) /
              100;
          }
        });
      });
    }

    return discount;
  }

  public getTime(dateString: moment.MomentInput) {
    return moment( dateString ).format('HH:mm');
  }

  public getDate(dateString: moment.MomentInput) {
    return moment( dateString ).format('DD.MM.YYYY');
  }

  public isLastMinute() {
    return this.real?.lastMinute;
  }

  public isLastMinuteAtTime(time: string) {
    return this.real?.lastMinute && moment(time).isBefore(moment().add(this.real?.lastMinutePeriodInMinutesFromNow, 'minutes'));
  }
}
