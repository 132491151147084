import { NgModule, LOCALE_ID } from '@angular/core';
import {CommonModule, NgOptimizedImage, registerLocaleData} from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { IonicModule } from '@ionic/angular';
import { PpPhonePipe } from './pipes/pp-phone.pipe';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VideoComponent } from './common/video.component';
import { CartComponent } from './cart/cart.component';
import { ServiceCardComponent } from './service-card/service-card.component';
import { RecommendedModalComponent } from './cart/recommended-modal/recommended-modal.component';
import { RelevantComponent } from './relevant/relevant.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DismissModalOnClickDirective } from '../dismiss-modal-on-click.directive';
import { EmployeeCardComponent } from '../team/employee-card.component';
import { PaginationPipe } from './pipes/pagination.pipe';
import { PaginatorComponent } from '../team/paginator.component';
import { FilterByIdPipe } from './pipes/filter-by-id.pipe';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { PhoneModalComponent } from './phone-modal/phone-modal.component';
import { CancellationWarningModalComponent } from './cancellation-warning-modal/cancellation-warning-modal.component';
import { PhoneFormComponent } from './phone-modal/phone-form/phone-form.component';
import { GalleryLightboxComponent } from './gallery-lightbox/gallery-lightbox.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ServicePickModalComponent } from './service-pick-modal/service-pick-modal.component';
import { PlacePickModalComponent } from './place-pick-modal/place-pick-modal.component';
import { DatePickModalComponent } from './date-pick-modal/date-pick-modal.component';
import { HoursPickModalComponent } from './hours-pick-modal/hours-pick-modal.component';
import { DaysCalendarComponent } from './cart/days-calendar/days-calendar.component';
import { SubstringPipe } from './pipes/substring.pipe';
import { SlotsComponent } from './cart/slots/slots.component';
import { ImagePipe } from './pipes/image.pipe';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SummaryComponent } from './cart/summary/summary.component';
import { BrandsComponent } from './brands/brands.component';
import { GalleryPaginatorComponent } from '../gallery/gallery-paginator.component';
import { HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { OnlyVisibleForCustomerPipe } from './pipes/only-visible-for-customer.pipe';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { DomChangeDirective } from '../booking-steps/slot-pick/dom-change.directive';
import { OnlyServicesPipe } from './pipes/only-services.pipe';
import { UniquePipe } from './pipes/unique.pipe';
import { FormsModule } from '@angular/forms';
import { RemoveEmptyParagraphsPipe } from './pipes/remove-empty-paragraphs.pipe';
import localeSl from '@angular/common/locales/sl';
import { RemoveZerosPipe } from './pipes/remove-zeros.pipe';
import { TooltipDirective } from './tooltip.directive';
import { JsonLdComponent } from './json-ld/json-ld.component';
import { SalonSliderComponent } from './salon-slider/salon-slider.component';
import {PluralizePipe} from "./pipes/pluralize.pipe";
registerLocaleData(localeSl, 'sl');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { enable:true, direction: Hammer.DIRECTION_ALL },
  };
}

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "dmstudio.si"
  },
  "position": "bottom",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#ffffff",
      "text": "#000000",
      "border": "#000000"
    }
  },
  "type": "info",
  "content": {
    "message": "To spletno mesto uporablja piškotke<br><br>Naše spletno mesto uporablja več vrst piškotkov, ki uporabnikom pomagajo pri boljši uporabniški izkušnji, nam pa omogočajo boljši vpogled v način uporabe spletnega mesta ter izboljšave naših storitev in produktov. Vrste piškotkov, ki jih uporabljamo: nujni piškotki, sejni oz. začasni piškotki, analitični piškotki, marketinški piškotki.",
    "allow": "Sprejmi piškotke",
    "dismiss": "Sprejmi",
    "deny": "Prekliči",
    "link": "Več o tem",
    "href": "/piskotki",
    "policy": "Piškotki za rabo spletne strani dmstudio.si"
  }
};


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    PpPhonePipe,
    PluralizePipe,
    SliderComponent,
    VideoComponent,
    ServiceCardComponent,
    CartComponent,
    RecommendedModalComponent,
    DismissModalOnClickDirective,
    FilterByIdPipe,
    RelevantComponent,
    EmployeeCardComponent,
    PaginationPipe,
    PaginatorComponent,
    MobileMenuComponent,
    GalleryLightboxComponent,
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    DaysCalendarComponent,
    SlotsComponent,
    SubstringPipe,
    ImagePipe,
    SummaryComponent,
    BrandsComponent,
    GalleryPaginatorComponent,
    OnlyVisibleForCustomerPipe,
    OnlyServicesPipe,
    DomChangeDirective,
    UniquePipe,
    PhoneModalComponent,
    PhoneFormComponent,
    RemoveEmptyParagraphsPipe,
    RemoveZerosPipe,
    CancellationWarningModalComponent,
    TooltipDirective,
    JsonLdComponent,
    SalonSliderComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PpPhonePipe,
    PluralizePipe,
    SliderComponent,
    RouterModule,
    VideoComponent,
    ServiceCardComponent,
    CartComponent,
    RecommendedModalComponent,
    FilterByIdPipe,
    RelevantComponent,
    EmployeeCardComponent,
    PaginationPipe,
    PaginatorComponent,
    MobileMenuComponent,
    GalleryLightboxComponent,
    ServicePickModalComponent,
    PlacePickModalComponent,
    DatePickModalComponent,
    HoursPickModalComponent,
    DaysCalendarComponent,
    SlotsComponent,
    SubstringPipe,
    ImagePipe,
    TranslateModule,
    SummaryComponent,
    BrandsComponent,
    GalleryPaginatorComponent,
    OnlyVisibleForCustomerPipe,
    OnlyServicesPipe,
    DomChangeDirective,
    UniquePipe,
    PhoneModalComponent,
    CancellationWarningModalComponent,
    PhoneFormComponent,
    RemoveEmptyParagraphsPipe,
    RemoveZerosPipe,
    TooltipDirective,
    JsonLdComponent,
    SalonSliderComponent
  ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        HttpClientModule,
        GoogleMapsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        HammerModule,
        FormsModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        NgOptimizedImage
    ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: LOCALE_ID, useValue: 'sl'}
  ],
})
export class SharedModule {
  constructor(private translate: TranslateService) {
    // Add available languages
    this.translate.addLangs(['sl', 'en']);

    // Set default language
    this.translate.setDefaultLang('sl');
  }
}
