<video autoplay playsinline loop muted [muted]="'muted'" poster="{{ posterSource }}" (click)="toggleVideo($event)" #videoPlayer>
  <source src="{{ videoSource }}" type="video/mp4" />
</video>
<div id="video-controls" class="controls" data-state="visible">
  <!-- <button id="playpause" type="button" data-state="play">Play/Pause</button>
  <button id="stop" type="button" data-state="stop">Stop</button> -->
  <!-- <div class="progress">
    <progress id="progress" value="0" min="0">
      <span id="progress-bar"></span>
    </progress>
  </div> -->
  <button id="mute" type="button" data-state="mute" (click)="toggleMute()" [ngClass]="isMuted() ? 'muted' : 'unmuted'">Mute/Unmute</button>
  <!-- <button id="volinc" type="button" data-state="volup">Vol+</button>
  <button id="voldec" type="button" data-state="voldown">Vol-</button>
  <button id="fs" type="button" data-state="go-fullscreen">Fullscreen</button> -->
</div>

<!--div class="video"><iframe [src]="videoEmbedSafeUrl()" frameborder="0" width="100%" height="100%" allowfullscreen allow="autoplay" style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></!--div-->

