import {
    Injector,
    Pipe,
    PipeTransform
} from '@angular/core';

@Pipe({
  name: 'filterById'
})
export class FilterByIdPipe implements PipeTransform {

    public constructor(private readonly injector: Injector) {
    }

    transform(items: Array<any>, id: string): any {
      if (!items || !id) {
        return items;
      }

      return items.filter(item => parseInt(item.id) === parseInt(id));
    }
}