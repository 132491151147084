import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { Article } from "../models/article.model";

@Injectable({
  providedIn: "root",
})

export class ArticleService {
  constructor( private http: HttpClient ) {  }

  getArticleBySlug$( slug: string | null ) {


      if (!slug) {
        const err = new Error('Invalid slug provided');
        throwError(() => err);
      }

      let url = environment.apiUrl + '/providers/' + environment.providerId + '/articles?slug=' + slug;

      const responseToModel = ( response: any): any =>  {
        if ( response._embedded && response._embedded.article && response._embedded.article[0] ) {
          return new Article( response._embedded.article[0] );
        }
      }

      return this.http.get( url ).pipe(
        map( response => responseToModel( response ) )
      );
  }


  getArticles$( topic?: string | null, cover?: boolean | null ) {


      let url = environment.apiUrl + '/providers/' + environment.providerId + '/articles?pageSize=100&visibleForCustomers=1';

      if (topic) {
        url += '&topic=' + topic;
      }


      if (cover) {
        url += '&cover=1';
      }

      const responseToModel = ( response: any): any =>  {

        if ( response._embedded && response._embedded.article ) {
          return response._embedded.article.map(
            ( articleApiData: any ) => new Article( articleApiData )
          )

        } else {
          return [];
        }

      } 

      return this.http.get( url ).pipe(
        map( response => responseToModel( response ) )
      );
  }
}