export class Selection   {

  public id: string;
  public resourceType: string;

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.resourceType = obj && obj.resourceType || null;
  }

}