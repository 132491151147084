<ng-container *ngIf="service;else skeleton">
    <a href="/{{service.fieldSlug}}/{{service.categorySlug}}/{{service.slug}}">
      <div [routerLink]="['/', service.fieldSlug, service.categorySlug, service.slug]" class="clickable" *ngIf="isReady" title="{{service.name}} - {{service.longDescription}} | dm Studio">
        <div class="new" *ngIf="service.newService">NOVO</div>
        <div class="img">
          <img src="{{ service.avatarUrl() || 'https://docs.api.pricepilot.io/652dadba274e4.jpeg' }}" alt="{{service.name}} - {{service.longDescription}} | dm Studio" loading="lazy" />
        </div>
        <div class="vertical" *ngIf="!!service.field">{{ service.field }}</div>
        <b>{{ service.name }}</b>
        <div class="desc" *ngIf="service.longDescription">{{service.longDescription}}</div>
        <ng-container *ngIf="service.isService()">
          <ng-container *ngIf="recommendedDiscount>0 || service.loyaltyCycle;else regularPrice">
            <ng-container *ngIf="service.loyaltyCycle && loyaltyCycleDiscountedPrice>0; else recommended">
              <div class="discount" *ngIf="service.isDiscountAvailable()">{{ service.getHigherPrice(service.regularPrice1) }} {{ service.currency }}</div>
              <div class="discount recommendedDiscount">
                {{service.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}} EUR
                <div class="discountLabel">
                  - {{service.loyaltyCycleDiscountPercentage}}%
                </div>
                <span appTooltip="Skrajni čas za izkoristek popusta za zveste stranke:<br> {{service.loyaltyExpirationDate}}"
                  style="display: inline-block;margin: 5px;">ⓘ</span>
              </div>
              <div class="price">
                <span>{{loyaltyCycleDiscountedPrice | currency:'EUR':'':'1.2-2':'sl' | removeZeros }}</span> {{ service.currency }}
              </div>
            </ng-container>
            <ng-template #recommended>
              <div class="discount" *ngIf="service.isDiscountAvailable()">{{ service.getHigherPrice(service.regularPrice1) }} {{ service.currency }}</div>
              <div class="discount recommendedDiscount" *ngIf="service.isDiscountAvailable()">
                {{service.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}} EUR
                <div class="discountLabel">
                  - {{recommendedDiscount}}%
                </div>
              </div>
              <div class="price">
                <span>{{recommendedDiscountedPrice | currency:'EUR':'':'1.2-2':'sl' | removeZeros }}</span> {{ service.currency }}
              </div>
            </ng-template>

          </ng-container>
          <ng-template #regularPrice>
            <div class="discount" style="z-index: 2;" *ngIf="service.isDiscountAvailable()">{{ service.getHigherPrice(service.regularPrice1) }} {{ service.currency }}</div>
            <div class="price">
              <span>{{service.minimalPrice?.lowestPrice | currency:'EUR':'':'1.2-2':'sl' | removeZeros }}</span> {{ service.currency }}
            </div>
          </ng-template>

        </ng-container>
      </div>
    </a>

    <ng-container *ngIf="service.isService()">
      <div class="bag regularbag" *ngIf="!!service?.name" (click)="$event.stopPropagation();cartService.addService( service! )" ></div>

      <ion-fab class="bag mobilebag" *ngIf="!!service?.name" >
        <ion-fab-button>
          <div class="inner" *ngIf="cartService.getAttendeeSize()===0" (click)="$event.stopPropagation();cartService.addService(service!);animateAddToCart()">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2.56055C12.9003 2.56055 10.4 5.06086 10.4 8.16055V9.28055H5.97249L5.91999 9.77055L3.67999 29.3705L3.60999 30.0005H28.39L28.32 29.3705L26.08 9.77055L26.0275 9.28055H21.6V8.16055C21.6 5.06086 19.0997 2.56055 16 2.56055ZM16 3.68055C18.5003 3.68055 20.48 5.66023 20.48 8.16055V9.28055H11.52V8.16055C11.52 5.66023 13.4997 3.68055 16 3.68055ZM6.98749 10.4005H10.4V11.678C10.0653 11.8727 9.83999 12.2271 9.83999 12.6405C9.83999 13.2596 10.3409 13.7605 10.96 13.7605C11.579 13.7605 12.08 13.2596 12.08 12.6405C12.08 12.2271 11.8547 11.8727 11.52 11.678V10.4005H20.48V11.678C20.1453 11.8727 19.92 12.2271 19.92 12.6405C19.92 13.2596 20.4209 13.7605 21.04 13.7605C21.659 13.7605 22.16 13.2596 22.16 12.6405C22.16 12.2271 21.9347 11.8727 21.6 11.678V10.4005H25.0125L27.13 28.8805H4.86999L6.98749 10.4005Z" fill="white"/>
            </svg>
          </div>
          <div class="inner" *ngIf="cartService.getAttendeeSize()!==0" >
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 2.56055C12.9003 2.56055 10.4 5.06086 10.4 8.16055V9.28055H5.97249L5.91999 9.77055L3.67999 29.3705L3.60999 30.0005H28.39L28.32 29.3705L26.08 9.77055L26.0275 9.28055H21.6V8.16055C21.6 5.06086 19.0997 2.56055 16 2.56055ZM16 3.68055C18.5003 3.68055 20.48 5.66023 20.48 8.16055V9.28055H11.52V8.16055C11.52 5.66023 13.4997 3.68055 16 3.68055ZM6.98749 10.4005H10.4V11.678C10.0653 11.8727 9.83999 12.2271 9.83999 12.6405C9.83999 13.2596 10.3409 13.7605 10.96 13.7605C11.579 13.7605 12.08 13.2596 12.08 12.6405C12.08 12.2271 11.8547 11.8727 11.52 11.678V10.4005H20.48V11.678C20.1453 11.8727 19.92 12.2271 19.92 12.6405C19.92 13.2596 20.4209 13.7605 21.04 13.7605C21.659 13.7605 22.16 13.2596 22.16 12.6405C22.16 12.2271 21.9347 11.8727 21.6 11.678V10.4005H25.0125L27.13 28.8805H4.86999L6.98749 10.4005Z" fill="white"/>
            </svg>
          </div>
        </ion-fab-button>
        <ion-fab-list side="bottom">
          <ion-fab-button color="light" (click)="$event.stopPropagation();cartService.addService( service!,1 );animateAddToCart()" *ngIf="cartService.getAttendeeSize()>=1">
            Oseba 1
          </ion-fab-button>
          <ion-fab-button color="light" (click)="$event.stopPropagation();cartService.addService( service!,2 );animateAddToCart()"  *ngIf="cartService.getAttendeeSize()>=2">
            Oseba 2
          </ion-fab-button>
          <ion-fab-button color="light" (click)="$event.stopPropagation();cartService.addService( service!,3 );animateAddToCart()"  *ngIf="cartService.getAttendeeSize()>=3">
            Oseba 3
          </ion-fab-button>
          <ion-fab-button color="light" (click)="$event.stopPropagation();cartService.addService( service!,4 );animateAddToCart()"  *ngIf="cartService.getAttendeeSize()==4">
            Oseba 4
          </ion-fab-button>
          <ion-fab-button color="light" (click)="$event.stopPropagation();cartService.addAttendee();cartService.addService(service!);animateAddToCart()"  *ngIf="cartService.getAttendeeSize()<4" >
            <ion-icon name="add-outline"></ion-icon> Dodaj osebo
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <app-json-ld [itemLD]="jsonLD"></app-json-ld>
    </ng-container>

  </ng-container>
  <!-- </ng-container> -->

<ng-template #skeleton>
  <div>
    <div class="img">
      <ion-skeleton-text [animated]="true" style="width:100%; height:85%; aspect-ratio: 4/3;"></ion-skeleton-text>
    </div>
    <b><ion-skeleton-text [animated]="true" style="width:15ch"></ion-skeleton-text></b>
    <div class="discount" ><ion-skeleton-text [animated]="true" style="width:4ch"></ion-skeleton-text></div>
    <div class="price"><span><ion-skeleton-text [animated]="true" style="width:1.5ch;display: inline-block;height: 42px;"></ion-skeleton-text></span> {{ currency }}</div>
  </div>
</ng-template>
