import { Location } from './location.model';
import { Price } from './cart/price.model';
import { Worker } from './worker.model';
import { ProviderService } from './provider-service.model';
import * as moment from 'moment';

export class BookingProduct {
  
  public id: string;
  public price: Price;
  public service: ProviderService;
  public quantity: number;
  public discountPercentage: number;
  public customName: string;

  constructor(
    obj?: any 
  ) {

    this.id = obj && obj.id || null;
    
    this.discountPercentage = obj && obj.discountPercentage || null;

    this.quantity = obj && obj.quantity || null;

    this.customName = obj && obj.customName || null;

    if ( obj && undefined !== obj.price ) {
      this.price = ( obj.price instanceof Price ) ? obj.price : ( new Price( obj.price, obj.currency ? obj.currency : 'EUR' ) );
    }

    if ( obj && obj.service ) {
      this.service = ( obj.service instanceof ProviderService ) ? obj.service : ( new ProviderService( obj.service ) );
    }

  }
  
}