import { Image } from "./image.model";
import { ProviderService } from "./provider-service.model";

export class Article {
  public id: string;

  public slug: string;
  public name: string;
  public shortName: string;
  public shortDescription: string;
  public longDescription: string;
  public subtitle: string;
  public topic: string;
  public shortLead: string;
  public longLead: string;
  public avatar: Image | undefined;
  public linkUrl: string;


  public section1Heading: string;
  public section1Body: string;
  public section1Image1: Image | undefined;
  public section1Image2: Image | undefined;
  public section1Image3: Image | undefined;
  public section1Image4: Image | undefined;
  public section1Service: ProviderService | undefined;

  public section2Heading: string;
  public section2Body: string;
  public section2Image1: Image | undefined;
  public section2Image2: Image | undefined;
  public section2Image3: Image | undefined;
  public section2Image4: Image | undefined;
  public section2Service: ProviderService | undefined;

  public section3Heading: string;
  public section3Body: string;
  public section3Image1: Image | undefined;
  public section3Image2: Image | undefined;
  public section3Image3: Image | undefined;
  public section3Image4: Image | undefined;
  public section3Service: ProviderService | undefined;

  public section4Heading: string;
  public section4Body: string;
  public section4Image1: Image | undefined;
  public section4Image2: Image | undefined;
  public section4Image3: Image | undefined;
  public section4Image4: Image | undefined;
  public section4Service: ProviderService | undefined;

  public section5Heading: string;
  public section5Body: string;
  public section5Image1: Image | undefined;
  public section5Image2: Image | undefined;
  public section5Image3: Image | undefined;
  public section5Image4: Image | undefined;
  public section5Service: ProviderService | undefined;

  public visibleForCustomers: boolean;

  public service1: ProviderService | undefined;
  public service2: ProviderService | undefined;
  public service3: ProviderService | undefined;
  public service4: ProviderService | undefined;

  constructor(
    obj?: any
  ) {
    this.id = obj?.id || null;
    this.slug = obj?.slug || null;
    this.name = obj?.name || null;
    this.shortName = obj?.shortName || null;
    this.shortDescription = obj?.shortDescription || null;
    this.longDescription = obj?.longDescription || null;
    this.subtitle = obj?.subtitle || null;
    this.topic = obj?.topic || null;
    this.shortLead = obj?.shortLead || null;
    this.longLead = obj?.longLead || null;
    this.linkUrl = obj?.linkUrl || null;

    if ( obj && obj.avatar ) {
      this.avatar = ( obj.avatar instanceof Image ) ? obj.avatar: new Image( obj.avatar );
    }

    if ( obj && obj.service1 ) {
      this.service1 = ( obj.service1 instanceof ProviderService ) ? obj.service1: new ProviderService( obj.service1 );
    }

    if ( obj && obj.service2 ) {
      this.service2 = ( obj.service2 instanceof ProviderService ) ? obj.service2: new ProviderService( obj.service2 );
    }

    if ( obj && obj.service3 ) {
      this.service3 = ( obj.service3 instanceof ProviderService ) ? obj.service3: new ProviderService( obj.service3 );
    }

    if ( obj && obj.service4 ) {
      this.service4 = ( obj.service4 instanceof ProviderService ) ? obj.service4: new ProviderService( obj.service4 );
    }


    this.section1Heading = obj?.section1Heading || null;
    this.section1Body = obj?.section1Body || null;

    if ( obj && obj.section1Image1 ) {
      this.section2Image1 = ( obj.section2Image1 instanceof Image ) ? obj.section2Image1: new Image( obj.section2Image1 );
    }

    if ( obj && obj.section1Image2 ) {
      this.section1Image2 = ( obj.section1Image2 instanceof Image ) ? obj.section1Image2: new Image( obj.section1Image2 );
    }

    if ( obj && obj.section1Image3 ) {
      this.section1Image3 = ( obj.section1Image3 instanceof Image ) ? obj.section1Image3: new Image( obj.section1Image3 );
    }

    if ( obj && obj.section1Image4 ) {
      this.section1Image4 = ( obj.section1Image4 instanceof Image ) ? obj.section1Image4: new Image( obj.section1Image4 );
    }

    if ( obj && obj.section1Service ) {
      this.section1Service = ( obj.section1Service instanceof ProviderService ) ? obj.section1Service: new ProviderService( obj.section1Service );
    }


    this.section2Heading = obj?.section2Heading || null;
    this.section2Body = obj?.section2Body || null;

    if ( obj && obj.section2Image1 ) {
      this.section2Image1 = ( obj.section2Image1 instanceof Image ) ? obj.section2Image1: new Image( obj.section2Image1 );
    }

    if ( obj && obj.section2Image2 ) {
      this.section2Image2 = ( obj.section2Image2 instanceof Image ) ? obj.section2Image2: new Image( obj.section2Image2 );
    }

    if ( obj && obj.section2Image3 ) {
      this.section2Image3 = ( obj.section2Image3 instanceof Image ) ? obj.section2Image3: new Image( obj.section2Image3 );
    }

    if ( obj && obj.section2Image4 ) {
      this.section2Image4 = ( obj.section2Image4 instanceof Image ) ? obj.section2Image4: new Image( obj.section2Image4 );
    }

    if ( obj && obj.section2Service ) {
      this.section2Service = ( obj.section2Service instanceof ProviderService ) ? obj.section2Service: new ProviderService( obj.section2Service );
    }

    this.section3Heading = obj?.section3Heading || null;
    this.section3Body = obj?.section3Body || null;

    if ( obj && obj.section3Image1 ) {
      this.section3Image1 = ( obj.section3Image1 instanceof Image ) ? obj.section3Image1: new Image( obj.section3Image1 );
    }

    if ( obj && obj.section3Image2 ) {
      this.section3Image2 = ( obj.section3Image2 instanceof Image ) ? obj.section3Image2: new Image( obj.section3Image2 );
    }

    if ( obj && obj.section3Image3 ) {
      this.section3Image3 = ( obj.section3Image3 instanceof Image ) ? obj.section3Image3: new Image( obj.section3Image3 );
    }

    if ( obj && obj.section3Image4 ) {
      this.section3Image4 = ( obj.section3Image4 instanceof Image ) ? obj.section3Image4: new Image( obj.section3Image4 );
    }

    if ( obj && obj.section3Service ) {
      this.section3Service = ( obj.section3Service instanceof ProviderService ) ? obj.section3Service: new ProviderService( obj.section3Service );
    }

    this.section4Heading = obj?.section4Heading || null;
    this.section4Body = obj?.section4Body || null;

    if ( obj && obj.section4Image1 ) {
      this.section4Image1 = ( obj.section4Image1 instanceof Image ) ? obj.section4Image1: new Image( obj.section4Image1 );
    }

    if ( obj && obj.section4Image2 ) {
      this.section4Image2 = ( obj.section4Image2 instanceof Image ) ? obj.section4Image2: new Image( obj.section4Image2 );
    }

    if ( obj && obj.section4Image3 ) {
      this.section4Image3 = ( obj.section4Image3 instanceof Image ) ? obj.section4Image3: new Image( obj.section4Image3 );
    }

    if ( obj && obj.section4Image4 ) {
      this.section4Image4 = ( obj.section4Image4 instanceof Image ) ? obj.section4Image4: new Image( obj.section4Image4 );
    }

    if ( obj && obj.section4Service ) {
      this.section4Service = ( obj.section4Service instanceof ProviderService ) ? obj.section4Service: new ProviderService( obj.section4Service );
    }

    this.section4Heading = obj?.section4Heading || null;
    this.section4Body = obj?.section4Body || null;

    if ( obj && obj.section4Image1 ) {
      this.section4Image1 = ( obj.section4Image1 instanceof Image ) ? obj.section4Image1: new Image( obj.section4Image1 );
    }

    if ( obj && obj.section4Image2 ) {
      this.section4Image2 = ( obj.section4Image2 instanceof Image ) ? obj.section4Image2: new Image( obj.section4Image2 );
    }

    if ( obj && obj.section4Image3 ) {
      this.section4Image3 = ( obj.section4Image3 instanceof Image ) ? obj.section4Image3: new Image( obj.section4Image3 );
    }

    if ( obj && obj.section4Image4 ) {
      this.section4Image4 = ( obj.section4Image4 instanceof Image ) ? obj.section4Image4: new Image( obj.section4Image4 );
    }

    this.section5Heading = obj?.section5Heading || null;
    this.section5Body = obj?.section5Body || null;

    if ( obj && obj.section5Image1 ) {
      this.section5Image1 = ( obj.section5Image1 instanceof Image ) ? obj.section5Image1: new Image( obj.section5Image1 );
    }

    if ( obj && obj.section5Image2 ) {
      this.section5Image2 = ( obj.section5Image2 instanceof Image ) ? obj.section5Image2: new Image( obj.section5Image2 );
    }

    if ( obj && obj.section5Image3 ) {
      this.section5Image3 = ( obj.section5Image3 instanceof Image ) ? obj.section5Image3: new Image( obj.section5Image3 );
    }

    if ( obj && obj.section5Image4 ) {
      this.section5Image4 = ( obj.section5Image4 instanceof Image ) ? obj.section5Image4: new Image( obj.section5Image4 );
    }


    if ( obj && obj.section1Image1 ) {
      this.section1Image1 = ( obj.section1Image1 instanceof Image ) ? obj.section1Image1: new Image( obj.section1Image1 );
    }
   

  }
}

