import { Component, OnInit } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { SharedModule } from "./shared/shared.module";
import { NavigationEnd, Router } from '@angular/router';
import { PPAuthService } from './core/services/auth.service';
import { User } from './core/models/user.model';
import { Observable, Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { MyBookingsService } from './core/services/my-bookings.service';
import { List } from './core/models/list.model';
import { ProviderService } from './core/services/provider.service';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, SharedModule],
})
export class AppComponent implements OnInit {
  currentURL: any;
  me$: Observable<User | null>;

  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  private providerSubscription!: Subscription;

  me2Subscribtion: Subscription;
  archiveBookings$: Observable<List>;

  constructor(
    private router: Router,
    private authService: PPAuthService,
    private cookieService: NgcCookieConsentService,
    private myBookingsService: MyBookingsService
  ,
  private providerService: ProviderService,) {
    this.authService.setMe();
    //this.authService.setMe();

    /*this.authService.me2$.subscribe( (user) =>
      console.log( user )
    )*/
    this.subscribeToMe2();
    console.log('AppComponent constructor');
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter in app component');
    this.authService.setMe();

    this.subscribeToMe2();
  }

  subscribeToMe2() {
    this.me2Subscribtion = this.authService.me2$.subscribe((user) => {
      if (user) {
        this.archiveBookings$ = this.myBookingsService.getArchiveBookings$(
          user.id
        );
      }
    });
  }

  ngOnInit(): void {

    this.providerSubscription = this.providerService.getProvider$().subscribe((provider) => {
      // console.log('provider in app component', provider);
    } );
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.urlAfterRedirects;
        //console.warn(this.currentURL);
      }
    });

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.initializingSubscription = this.cookieService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      }
    );

    this.initializedSubscription = this.cookieService.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      }
    );

    this.initializationErrorSubscription =
      this.cookieService.initializationError$.subscribe(
        (event: NgcInitializationErrorEvent) => {
          // the cookieconsent has failed to initialize...
          console.log(
            `initializationError: ${JSON.stringify(event.error?.message)}`
          );
        }
      );

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(
      () => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieService.getConfig() to do stuff...
      }
    );
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.providerSubscription.unsubscribe();
  }
}
