<!-- <div class="line" style="width: 100%;flex-basis: 100%;padding-inline: 15px;">{{segment}}</div> -->
<ng-container *ngIf="slots$ | async as slots">
  <ng-container *ngIf="slots.length > 0;else noSlots">
    <ng-container *ngFor="let slot of slots; index as idx" >
<!--      {{slot | json}}-->
      <div class="line clickable" [ngClass]="{'sel': isSlotPicked(slot),'collapsed': !isDisplayedImmediately(slot,idx)}" (click)="toggleSlot(slot,$event)" [attr.data-hour]="hour(slot)">
        <b>{{ getTime(slot.start) }}</b><span *ngIf="isLastMinute(slot)" class="lastMinute" title="Last minute ponudba -{{real?.lastMinuteDiscountPercentage}}%"></span>
      </div>

    </ng-container>
    <div class="quick" style="height: 0px;opacity: 0;padding: 0px; border: none;"></div>
  </ng-container>

</ng-container>
<ng-template #noSlots>
  <div class="line" style="grid-column: 1 / -1;font-weight: 300;">
    <b>Na izbrani dan ni prostih terminov, ali zaposleni te storitve ne opravljajo.</b>
    <p>Izberite drug dan, ali se postavite v čakalno vrsto s klikom na kvadratek pod dnevom.</p>
  </div>
</ng-template>
