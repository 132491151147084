import { environment } from '../../../environments/environment';
import { Document } from './document.model';

export class Image   {

  public id: string;
  public document: Document | null = null;
  public url: string | null = null

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;

    if ( obj && obj.document ) {
      this.document = ( obj.document instanceof Document ) ? obj.document: new Document( obj.document );
    }

    if ( this.document && this.document.fileName ) {
      this.url = environment.docsUrl + '/' + this.document.fileName;
    }

  }

  static revive(json: any): any {
    const document = json.document ? Document.revive(json.document) : undefined;
    return new Image({...json, document});
  }

}