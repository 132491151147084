import { Injectable } from '@angular/core';
import { NormalizedService } from '../models/normalized-service.model';

import { HttpClient } from '@angular/common/http';

import { map, switchAll, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NormalizedServicesService {

  constructor( private http: HttpClient ) {
  }


  getBySearchString( searchString: string ) {
    return this.getNormalizedServices( searchString );
  }

  getBySlug( slug: string ) {
    return this.getNormalizedServices( null, slug ).pipe(
      map ( normalizedServiceArr => normalizedServiceArr[0] )
    );
  }

  getNormalizedServices( searchString: string | null, slug:string | null = null ) {

    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.taxonomyservice ) {
        return response._embedded.taxonomyservice.map(
          (taxonomyServiceApiData: any) => new NormalizedService( taxonomyServiceApiData )
        )

      } else {
        return [];
      }

    }

    const apiUrl = environment.apiUrl;
    const endpoint = 'taxonomyservices';

    let query: string[] = [];

    if ( searchString ) {
      query.push('searchString=' + searchString);
    } else {
      query.push('default=true');
    }

    if ( slug ) {
      query.push('slug=' + slug);
    }

    query.push('pageSize=200');

    const url = apiUrl + endpoint + '?' + query.join('&');

    return this.http.get( url ).pipe(
       map ( response => responseToModel( response ) )
    );

  }

}