import { Component, Input, OnInit } from '@angular/core';
import { Provider } from 'src/app/core/models/provider.model';
//import { NewCartService } from '../../core/services/new-cart.service';
import { CartService } from 'src/app/core/services/cart.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent  implements OnInit {
  expandMobile: boolean = false;
  hiddenRecommended: number = 0;
  @Input() provider?: Provider | undefined;

  constructor(public cartService: CartService) {
  }

  ngOnInit() {
  }

  addAttendee() {
    this.cartService.addAttendee();
  }
  removeAttendee(i: number) {
    this.cartService.removeAttendee(i);
  }

  isDiscountAvailable(service: any): boolean {
    return service?.regularPrice1 && service?.minimalPrice?.lowestPrice && service?.minimalPrice?.lowestPrice < service?.regularPrice1;
  }

  getHigherPrice(price: any): number {
    return +price;
  }

  setSelectedPerson(index:number) {
    this.cartService.setSelectedPerson(index);
  }

  getTotalItems() {
    return this.cartService.getTotalItemsInCart();
  }

  getAttendeeItems(attendeeIndex) {
    return this.cartService.getAttendeeItemsInCart(attendeeIndex);
  }

  getAttendeeSize() {
    return this.cartService.getAttendeeSize();
  }

  toggleExpandMobile() {
    //console.log("toggleExpandMobile",this.expandMobile);
    this.expandMobile = !this.expandMobile;
  }

  hideRecommended(event: Event) {
    const target = event.target as HTMLElement;
    target.closest('.cartSuggestionsItem')?.classList.add('hidden');
    target.closest('.cart_item')?.classList.add('hidden');
    this.hiddenRecommended++;
    if (this.hiddenRecommended===2) {
      this.cartService.hideRecommended();
    }
  }
}
