<div class="content nosel">
  <header style="z-index: 200;">
    <div class="logo"><a [routerLink]="['/']" target="_blank"><img class="clickable" [routerLink]="['/']" src="assets/img/logo_v1.png" alt="dm Studio"></a></div>
    <div class="menu">
      <div class="menu1">
        <a [routerLink]="['/frizerske-storitve']" [ngClass]="{'sel': currentFieldSlug === 'frizerske-storitve' }" >Frizerske storitve <b></b></a>
        <div class="menu2 options">
          <!-- <a [routerLink]="['/frizerske-storitve', 'svetovanje-5']" href="#">Svetovanje</a> -->
          <a [routerLink]="['/frizerske-storitve', 'strizenje']" href="#">Striženje</a>
          <a [routerLink]="['/frizerske-storitve', 'oblikovanje-priceske']" href="#">Oblikovanje pričeske</a>
          <a [routerLink]="['/frizerske-storitve', 'nega-las-in-lasisca']" href="#">Nega las in lasišča</a>
          <a [routerLink]="['/frizerske-storitve', 'koloristika']" href="#">Koloristika</a>
          <a [routerLink]="['/frizerske-storitve', 'trajno-oblikovanje-priceske']" href="#">Trajno oblikovanje pričeske</a>
          <a [routerLink]="['/frizerske-storitve', 'podaljsevanje-las']" href="#">Podaljševanje las</a>
        </div>
      </div>
      <div class="menu1">
        <a [routerLink]="['/kozmeticne-storitve']"  [ngClass]="{'sel': currentFieldSlug === 'kozmeticne-storitve' }"  >Kozmetične storitve <b></b></a>
        <div class="menu2 options">
          <!-- <a [routerLink]="['/kozmeticne-storitve', 'svetovanje-6']" href="#">Svetovanje</a> -->
          <a [routerLink]="['/kozmeticne-storitve', 'manikura']" href="#">Manikura</a>
          <a [routerLink]="['/kozmeticne-storitve', 'podaljsevanje-nohtov']" href="#">Podaljševanje nohtov</a>
          <a [routerLink]="['/kozmeticne-storitve', 'pedikura']" href="#">Pedikura</a>
          <a [routerLink]="['/kozmeticne-storitve', 'napredna-kozmeticna-analiza-koze']" href="#">Analiza kože</a>
          <a [routerLink]="['/kozmeticne-storitve', 'nega-obraza']" href="#">Nega obraza</a>
          <a [routerLink]="['/kozmeticne-storitve', 'obrvi-in-trepalnice']" href="#">Obrvi in trepalnice</a>
          <a [routerLink]="['/kozmeticne-storitve', 'depilacija']" href="#">Depilacija</a>
          <a [routerLink]="['/kozmeticne-storitve', 'masaze-in-preoblikovanje-telesa']" href="#">Masaže in preoblikovanje telesa</a>
          <a [routerLink]="['/kozmeticne-storitve', 'licenje']" href="#">Ličenje</a>
          <!-- <a [routerLink]="['/kozmeticne-storitve', 'mikropigmentacija-long-time-liner']" href="#">Mikropigmentacija</a>
          <a [routerLink]="['/kozmeticne-storitve', 'vitaminski-mayerjev-koktajl-za-krepitev-imunskega-sistema']" href="#">Vitaminski koktajl</a> -->

        </div>
      </div>
      <a [ngClass]="{'sel': currentPathSlug === 'aktualno' }" [routerLink]="['/aktualno']" href="#">Aktualno</a>
      <a [ngClass]="{'sel': currentPathSlug === 'galerija' }" [routerLink]="['/galerija']" href="#">Galerija</a>
      <a [ngClass]="{'sel': currentPathSlug === 'ekipa' }" [routerLink]="['/ekipa']" href="#">Ekipa</a>
      <a [ngClass]="{'sel': currentPathSlug === 'saloni' }" [routerLink]="['/saloni']" href="#">Saloni</a>

    </div>
    <div class="phone"><a href="{{ 'tel:'+(provider?.phone1 | ppPhone) }}">{{ provider?.phone1 | ppPhone }}</a></div>
    <!--div class="search" ></div-->

    <!--ng-container *ngIf="me$ | async as me;">
      aa{{ me|json }}bb
    </ng-container-->


    <!--ng-container *ngIf="me$ | async as me; else loggedOut">
    <ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil', 'moje-rezervacije']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="calendar" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.MY_BOOKINGS' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item [routerLink]="['/moj-profil']">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="person" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.MY_PROFILE' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle>
      <ion-item (click)="logout()">
        <ion-icon style="color:black; font-size: 20px;" slot="end" name="power" button></ion-icon>
        <ion-label style="color:black;">{{ 'MENU.LOGOUT' | translate }}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    </ng-container>

    <ng-template #loggedOut>
      <ion-menu-toggle>
        <ion-item [routerLink]="['/auth', 'prijava']">
          <ion-icon style="color:black; font-size: 20px;" slot="end" name="person"></ion-icon>
          <ion-label style="color:black;">{{ 'MENU.LOGIN' | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ng-template-->

    <ng-container *ngIf="me$ | async as me; else loggedOut">
      <div *ngIf="me" class="me visible clickable" [routerLink]="['/moj-profil']">
        <a class="logged-in-user" href="/moj-profil"></a><!--{{ me.firstName }}-->
      </div>
    </ng-container>

    <ng-template #loggedOut>
      <!-- <div class="user clickable" [routerLink]="['/auth', 'prijava']"></div> -->
      <div class="user visible clickable" [routerLink]="['/moj-profil']"></div>
    </ng-template>

    <!--ng-template #loggedOut>
      <div>ODJAVLJEN</div>
    </ng-template-->

    <!--div (click)="populateCart()" title="Helper to quickly add service to cart"> XXX </!--div-->
    <ion-menu-toggle>
      <div class="mobmenu">
      </div>
    </ion-menu-toggle>
  </header>
</div>