 export class Dependencies {
  public fullfilledBy: Array<number>;
  public text: string;
  public text_translations: {};

  constructor(
    obj?: any
  ) {
    this.fullfilledBy = obj && obj.fullfilledBy || null;
    this.text = obj && obj.text || null;
    this.text_translations = obj && obj.text_translations || null;
  }

  static revive(json: any): any {
    return new Dependencies(json);
  }
}
