import { BookingService } from './booking-service.model';

export class BookingAttendeeBookingService {
  
  public id: string;
  public bookingService: BookingService;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;

    if ( obj && obj.bookingService ) {
      this.bookingService = ( obj.bookingService instanceof BookingService ) ? obj.bookingService : new BookingService( obj.bookingService );
    }
    
  }
  
}