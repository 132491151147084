export class NormalizedService {

  public id: string;
  public slug: string;
  public name: string;
  public order: number;
  public level: number;
  public urlParentSlug: string;
  public default: boolean;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.slug = obj && obj.slug || null;
    this.name = obj && obj.name || null;
    this.order = obj && obj.order || null;
    this.level = ( obj && obj.level !== undefined ) ? obj.level: null;
    this.urlParentSlug = obj && obj.urlParentSlug || null;
    this.default = obj && obj.default || null;
  }

}