import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { SearchPreferences } from '../../core/models/search-preferences/search-preferences.model';

import { HoursPeriod } from '../../core/models/search-preferences/hours-period.model';
import { RangeValue } from '@ionic/core';

@Component({
  selector: 'app-hours-pick-modal',
  templateUrl: './hours-pick-modal.component.html',
  styleUrls: ['./hours-pick-modal.component.scss'],
})
export class HoursPickModalComponent implements OnInit {

  @Input() searchPreferences: SearchPreferences | undefined;

  hours: RangeValue = { lower: 0, upper: 24 } ;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  onConfirmedChange( values: RangeValue ) {
    if (typeof values !== 'number') {
      console.log(values);
      this.modalCtrl.dismiss(
        new HoursPeriod(values.lower, values.upper), 'picked'
      );
    }
  }

  get hoursValue(): { lower: number; upper: number; } | undefined {
    return typeof this.hours === 'number' ? undefined : this.hours;
  }

}
