import { Dependencies } from "./dependencies.model";
import { Service } from "./service.model"

export class Attendee {

  public name: string;
  public services: Service[];
  public depsIssue: boolean = false;

  constructor(
    obj?: any
  ) {
    this.name = obj && obj.name || null;
    this.services = obj && obj.services || [];
  }


  getCategoriesPicked(): Array<number> {
    const ids = this.services.map( (value, index, array) => {
      return value.parentId;
    });

    const uniqueIds = [...new Set(ids)];
    return uniqueIds;
  }

  setDepsIssue( hasIssue: boolean ) {
    this.depsIssue = hasIssue;
  }

  areServicesPicked() {
    return ( this.services.length > 0 );
  }

  checkIfDepsAreOk() {
    const servicesInCart = this.services.map((service) => service.id);

    const checkIfElementsExist = (element) => {
      return servicesInCart.includes(element);
    };

    let hasDepsIssue = false;

    this.services.some((service) => {

      if ( service.dependencies && service.dependencies.fullfilledBy ) {

        if (service.dependencies.fullfilledBy.some(checkIfElementsExist)) {
          //console.log("This service has dependencies and they are in the cart:", service);
          hasDepsIssue = false;
        } else {
          //console.log("This service has dependencies but they are not in the cart:", service);
          hasDepsIssue = true;
        }

      } else if ( service.parentDependencies && service.parentDependencies.fullfilledBy ) {

        if (service.parentDependencies.fullfilledBy.some(checkIfElementsExist)) {
          hasDepsIssue = false;
        } else {
          hasDepsIssue = true;
        }

      }

    });

    this.setDepsIssue(hasDepsIssue);
    return !hasDepsIssue; // Add a return statement here
  }

  checkIfDepsAreOkForService( service: Service ) {
    const servicesInCart = this.services.map((service) => service.id);

    const checkIfElementsExist = (element) => {
      return servicesInCart.includes(element);
    };

    let hasDepsIssue = false;

    if (service.dependencies && service.dependencies.fullfilledBy) {
      if (service.dependencies.fullfilledBy.some(checkIfElementsExist)) {
        //console.log("This service has dependencies and they are in the cart:", service);
        hasDepsIssue = false;
      } else {
        //console.log("This service has dependencies but they are not in the cart:", service);
        hasDepsIssue = true;
      }
    } else if (service.parentDependencies && service.parentDependencies.fullfilledBy) {

      if (service.parentDependencies.fullfilledBy.some(checkIfElementsExist)) {
        hasDepsIssue = false;
      } else {
        hasDepsIssue = true;
      }

    }

    return !hasDepsIssue; // Add a return statement here
  }

  pickMaxServiceFilter( parentId: number, pickMax: number ) {

    if ( !pickMax || pickMax < 1 ) {
      return;
    }

    const categoryIndexArray: Array<number> = [];

    for( let i=0; i < this.services.length; i++ ) {

      if ( parentId === this.services[i].parentId ) {
        categoryIndexArray.push( i );
      }

      const diff = categoryIndexArray.length - pickMax

      if ( diff > 0 ) {
        this.services.splice( categoryIndexArray[0], diff )
      }

    }

  }

  findService( serviceId: number ) {

      for( let i=0; i < this.services.length; i++ ) {
        if ( this.services[i].id == serviceId ) {
          return this.services[i];
        }
      }

      return new Service();

    }

  toggleService( serviceData: Service, categoryData: Service, doNotRemove?: boolean ) {

    // console.log("toggleservice",serviceData);
    //console.info(serviceData._self);
    for( let i=0; i < this.services.length; i++ ) {
      if ( this.services[i].id == serviceData.id ) {
        if ( !doNotRemove ) {
          this.services.splice(i, 1);
        }
        return true;
      }
    }


    // console.log("serviceData",serviceData, serviceData.discount);
    const service = new Service({...serviceData});

    if ( categoryData.dependencies && categoryData.dependencies.fullfilledBy && categoryData.dependencies.fullfilledBy[0] ) {
      service.parentDependencies = categoryData.dependencies;
    }

    if ( serviceData.dependencies && serviceData.dependencies.fullfilledBy && serviceData.dependencies.fullfilledBy[0] ) {
      service.dependencies = serviceData.dependencies;
    }

    if ( serviceData.recommendations && serviceData.recommendations[0] ) {
      service.recommendations = serviceData.recommendations;
    }


    this.services.push(service);

    this.pickMaxServiceFilter( categoryData.id, categoryData.childrenPickMax ? categoryData.childrenPickMax: 0 );

    return service;

  }

  static revive(json: any): Attendee {
    const services = json.services.map( (service: Service) => Service.revive(service) );
    return new Attendee({...json,services});
  }
}