<div class="cart nosel"  [ngClass]="{'active': (cartService.getPick() && cartService.getPick().attendees.length>0),'expand-mobile':expandMobile}">
  <div class="carttitlemob">KOŠARICA<b (click)="toggleExpandMobile()"></b></div>
  <ul class="cartinner">
    <li class="cartblock" *ngFor="let attendee of cartService.getPick().attendees, index as i" [ngClass]="{'active': i===(cartService.selectedPerson),'collapse-items':(getTotalItems()>3),'collapse-attendee-items':(getAttendeeItems(i)>3),'stack-collapsed-items':(getTotalItems()>6)}">
      <div class="carttitle clickable" (click)="setSelectedPerson(i)">{{attendee.name}}
        <span class="boxedx" (click)="removeAttendee(i)">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon-square-x" width="24" height="24" viewBox="0 0 24 24"
            stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z" />
            <path d="M9 9l6 6m0 -6l-6 6" />
          </svg>
        </span>
        <!-- <span *ngIf="attendee.depsIssue" title="Košarica ima neizpolnjene zahteve!">
          ⚠️
        </span> -->
      </div>
      <div class="c"></div>
      <div class="cart_item" *ngFor="let service of attendee.services">
        <div class="calendar">
          <div class="calendarblock blue" [style.border-color]="( service.borderColor ) ? (service.borderColor) : ''" >
            <div class="header">
              <div class="close" (click)="cartService.removeService( service, i, $event )"></div>
              <img class="clickable" [routerLink]="['/', service?.fieldSlug, service?.categorySlug, service?.slug]" [src]="service?.avatarUrl()" alt="{{service?.name}} - {{service?.longDescription}} | dm Studio">
              <div class="info infoflex clickable" [routerLink]="['/', service?.fieldSlug || 'todo', service?.categorySlug || 'todo', service?.slug]">
                <b title="{{service?.name}}" *ngIf="attendee.checkIfDepsAreOkForService(service)">{{service?.name}}</b>
                <b title="{{service?.name}}" class="hasDepsIssue" *ngIf="!attendee.checkIfDepsAreOkForService(service)" (click)="$event.preventDefault();cartService.getDependencies(service);$event.stopImmediatePropagation()">{{service?.name}}</b>
                <ng-container *ngIf="(service.discount>0 || service.loyaltyCycle);else regularPrice">

                  <ng-container *ngIf="service.loyaltyCycle; else recommended">
                    <div class="discount recommendedDiscount">
                      {{service.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}} EUR
                      <div class="discountLabel">
                        - {{service.loyaltyCycleDiscountPercentage}}%
                      </div>
                    </div>
                    <div class="recommendedDiscountPrice">
                      <span>{{service.loyaltyCycleDiscountedPrice() | currency:'EUR':'':'1.2-2':'sl' | removeZeros }}</span> EUR
                    </div>
                  </ng-container>
                  <ng-template #recommended>
                    <div class="discount recommendedDiscount">
                      {{service.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}} EUR
                      <div class="discountLabel">
                        - {{service?.recommendationDiscount(provider)}}%
                      </div>
                    </div>
                    <div class="price">
                      <span>{{service?.recommendationDiscountedPrice(provider) | currency:'EUR':'':'1.2-2':'sl' | removeZeros }}</span> EUR
                    </div>
                  </ng-template>

                  <!-- <div class="discount recommendedDiscount">
                    {{service?.minimalPrice?.lowestPrice | currency: 'EUR':'code':'1.2-2':'sl' | removeZeros }}
                    <div class="discountLabel">
                      - {{service?.recommendationDiscount(provider)}}%
                    </div>
                  </div>
                  <div class="recommendedDiscountPrice">
                    <div>{{service?.recommendationDiscountedPrice(provider) | currency: 'EUR':'':'1.2-2':'sl' | removeZeros }}</div> EUR
                  </div> -->
                </ng-container>
                <ng-template #regularPrice>
                  <div class="discount" *ngIf="isDiscountAvailable(service)">{{ getHigherPrice(service?.regularPrice1) | currency: 'EUR':'code':'1.2-2':'sl' }}</div>
                  <div class="price">
                    <div>{{service?.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}}</div> EUR
                  </div>
                </ng-template>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart_item" *ngIf="attendee.services.length < 1"  (click)="setSelectedPerson(i)">
				<div class="gray" style="width:100%">Ni dodanih storitev</div>
			</div>

    </li>
  </ul>

  <div class="cartblock add-person" *ngIf="getAttendeeSize() < 4">
    <div class="button_plus clickable" (click)="addAttendee()">Dodaj osebo</div>
    <span *ngIf="getAttendeeSize() == 1 && getAttendeeItems(0) == 1">Naročate za več oseb?</span>
  </div>

  <div class="cartblock totals">

      <div class="total">
        <b>SKUPAJ</b>
        <div class="duration"><div>{{cartService.getMaxDuration()}}</div> min</div>
        <div class="price"><div>{{cartService.getTotal() | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}}</div> EUR</div>
        <div class="discount" *ngIf="cartService.getSavings() as savings">Prihranek s popusti: {{(savings.toString()) | currency: 'EUR':'code':'1.2-2':'sl' | removeZeros}}</div>
      </div>

    <div class="button disabled" *ngIf="cartService.getTotalItemsInCart() <= 0 || cartService.areThereDepIssues()">IZBERI LOKACIJO</div>
    <div class="button clickable" [routerLink]="['/', 'narocanje', 'izberi-lokacijo']" *ngIf="cartService.getTotalItemsInCart() > 0 && !cartService.areThereDepIssues()">IZBERI LOKACIJO</div>
  </div>
</div>
<div class="mobilecart nosel"  [ngClass]="{'active': (cartService.getPick() && cartService.getPick().attendees.length>0)}">
  <div class="carttitlemob" (click)="toggleExpandMobile()">KOŠARICA
    <b>(
      <ng-container *ngIf="cartService.getPick().attendees[0] && cartService.getPick().attendees[0]?.services ">
        <span>{{cartService.getPick().attendees[0].services.length}}</span>
      </ng-container>
      <ng-container *ngIf="cartService.getPick().attendees[1] && cartService.getPick().attendees[1]?.services ">
        +
        <span>{{cartService.getPick().attendees[1].services.length}}</span>
      </ng-container>
      <ng-container *ngIf="cartService.getPick().attendees[2] && cartService.getPick().attendees[2]?.services ">
        +
        <span>{{cartService.getPick().attendees[2].services.length}}</span>
      </ng-container>
      <ng-container *ngIf="cartService.getPick().attendees[3] && cartService.getPick().attendees[3]?.services ">
        +
        <span>{{cartService.getPick().attendees[3].services.length}}</span>
      </ng-container>
    )</b>
  </div>

  <div class="button disabled" *ngIf="cartService.getTotalItemsInCart() <= 0 || cartService.areThereDepIssues()">IZBERI LOKACIJO</div>
  <div class="button clickable" [routerLink]="['/', 'narocanje', 'izberi-lokacijo']" *ngIf="cartService.getTotalItemsInCart() > 0 && !cartService.areThereDepIssues()">IZBERI LOKACIJO</div>

</div>
<div class="cartSuggestions " #cartSuggestions [ngClass]="{dependencies:cartService.areThereDepIssues()}">
  <div class="cartSuggestionsClose" (click)="cartService.hideRecommended()" *ngIf="!cartService.serviceDependencies"></div>
  <div class="cartSuggestionsInner">
    <div class="cartSuggestionsTitle" *ngIf="!cartService.serviceDependencies && !cartService.areThereDepIssues()">Želite dodati še naslednje storitve?</div>
    <div class="cartSuggestionsTitle" *ngIf="cartService.serviceDependencies && cartService.areThereDepIssues()">Izbrana storitev zahteva kombinacijo z<br>vsaj eno od spodnjih storitev!</div>
    <div class="cartSuggestionsItems">
      <ng-container *ngIf="cartService.serviceDependencies && cartService.areThereDepIssues(); else recommendations">

        <!--mandatory pick one-->
        <div class="cart_item mandatory" *ngFor="let service of cartService.serviceDependencies;index as i">
          <div class="calendar">
            <div class="calendarblock blue" [style.border-color]="( service.borderColor ) ? (service.borderColor) : ''">
              <div class="header">
                <img class="clickable" [routerLink]="['/', service?.fieldSlug, service?.categorySlug, service?.slug]" [src]="service?.avatarUrl()"
                  loading="lazy">
                <div class="info infoflex clickable"
                  [routerLink]="['/', service?.fieldSlug || 'todo', service?.categorySlug || 'todo', service?.slug]">
                  <b title="{{service?.name}}">{{service?.name}}</b>
                  <div class="discount" *ngIf="isDiscountAvailable(service)">{{ getHigherPrice(service?.regularPrice1) | currency:
                    'EUR':'code':'1.2-2':'sl' | removeZeros }} EUR</div>
                  <div class="price">
                    <div>{{service?.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}}</div> EUR
                  </div>
                </div>
                <div class="cartSuggestionsItemAdd"
                  (click)="$event.stopPropagation();cartService.addService( service,0,true );hideRecommended($event);cartService.hideRecommended()">
                  <div class="button clickable"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
      <ng-template #recommendations>

        <div class="cart_item" *ngFor="let service of cartService.firstItemRecommendations;index as i">
          <!-- <output>{{service['discount'] ? service['discount'] : service['discount'] = provider?.recommendationsDiscount || 0 }}</output> -->

          <div class="calendar">
            <div class="calendarblock blue" [style.border-color]="( service.borderColor ) ? (service.borderColor) : ''">
              <div class="header">
                <img class="clickable" [routerLink]="['/', service?.fieldSlug, service?.categorySlug, service?.slug]" [src]="service?.avatarUrl()"
                  loading="lazy">
                <div class="info infoflex clickable"
                  [routerLink]="['/', service?.fieldSlug || 'todo', service?.categorySlug || 'todo', service?.slug]">
                  <b title="{{service?.name}}">{{service?.name}}</b>
                  <ng-container *ngIf="(service.discount>0 && cartService.getTotalItemsInCart()==1);else normalPrice">
                    <div class="discount recommendedDiscount">
                      {{service?.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}} EUR
                      <div class="discountLabel">
                        - {{service?.recommendationDiscount(provider)}}%
                      </div>
                    </div>
                    <div class="recommendedDiscountPrice">
                      <div>{{service?.recommendationDiscountedPrice(provider) | currency: 'EUR':'':'1.2-2':'sl' }}</div> EUR
                    </div>
                  </ng-container>
                  <ng-template #normalPrice>
                    <div class="discount " *ngIf="isDiscountAvailable(service)">{{ getHigherPrice(service?.regularPrice1) | currency:
                      'EUR':'code':'1.2-2':'sl'}} </div>
                    <div class="price">
                      <div>{{service?.minimalPrice?.lowestPrice | currency: 'EUR':'':'1.2-2':'sl' | removeZeros}}</div> EUR
                    </div>
                  </ng-template>
                </div>
                <div class="cartSuggestionsItemAdd"
                  (click)="$event.stopPropagation();cartService.addService( service,0,true );hideRecommended($event)">
                  <div class="button clickable"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</div>
