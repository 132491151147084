import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, combineLatest, of, switchMap, EMPTY, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Booking } from '../models/booking.model';
import { List } from '../models/list.model';
import { environment } from '../../../environments/environment';
import { Pick } from '../models/cart/pick.model';
import { User } from '../models/user.model';
import * as moment from 'moment';

enum View {
  Active = 'active',
  Archive = 'archive',
}

@Injectable({
  providedIn: 'root',
})
export class MyBookingsService {
  //pass data to child routes
  private bookingSub$: Subject<Booking> = new ReplaySubject<Booking>(1);

  booking$ = this.bookingSub$.asObservable();

  addBooking(booking: Booking) {
    this.bookingSub$.next(booking);
  }

  // writable stream subject
  private pastServicesSub$: Subject<Map<string, any>> = new ReplaySubject<Map<string, any>>();

  pastServices$ = this.pastServicesSub$.asObservable();

  // method to write data to the stream
  addPastService(data: Map<string, any>) {
    //console.log(data);
    this.pastServicesSub$.next(data);
  }
  pastServicesSubscription: Subscription | null = null;

  constructor(private http: HttpClient) {}

  getActiveBookings$(userId: string): Observable<List> {
    return this.getUserBookings$(userId, View.Active);
  }

  getArchiveBookings$(userId: string): Observable<List> {
    const archivebooking = this.getUserBookings$(userId, View.Archive);
    const loyaltyCycleMap = new Map<string, any>();

    if (this.pastServicesSubscription) {
      this.pastServicesSubscription.unsubscribe();
    }

    this.pastServicesSubscription = archivebooking.subscribe((response) => {
      response.items?.forEach((booking) => {
        // 4.3.2024 12:00:00 and 4.4.2024 18:45:00 are 999 bookings, should affect others
        if (
          !booking.canceled ||
          booking.start == '2024-03-04T12:00:00+0100' ||
          booking.start == '2024-04-04T18:45:00+0200'
        ) {
          booking.bookingAttendees?.forEach((bookingAttendee) => {
            bookingAttendee.bookingAttendeeBookingServices?.forEach(
              (bookingAttendeeBookingService) => {
                const service =
                  bookingAttendeeBookingService?.bookingService?.service;
                if (service?.loyaltyCycle) {
                  //console.log(bookingAttendeeBookingService.bookingService);

                  const serviceId = service.id + '';
                  const loyaltyCycleDiscountPercentage =
                    service.loyaltyCycleDiscountPercentage;
                  let start = '';
                  let bookingDeadline = '';
                  if (service.loyaltyCyclePeriodInDaysBeforeTheNextBooking) {
                    start =
                      bookingAttendeeBookingService.bookingService.createdAt;
                    bookingDeadline = moment(start)
                      .add(
                        service.loyaltyCyclePeriodInDaysBeforeTheNextBooking,
                        'days'
                      )
                      .toISOString();
                  } else if (
                    service.loyaltyCyclePeriodInDaysBeforeTheNextVisit
                  ) {
                    start = bookingAttendeeBookingService.bookingService.start;
                    bookingDeadline = moment(start)
                      .add(
                        service.loyaltyCyclePeriodInDaysBeforeTheNextVisit,
                        'days'
                      )
                      .toISOString();
                  }

                  // if deadline is after today
                  if (moment(bookingDeadline).isAfter(moment())) {
                    if (!loyaltyCycleMap.has(serviceId)) {
                      loyaltyCycleMap.set(serviceId, [
                        serviceId,
                        loyaltyCycleDiscountPercentage,
                        start,
                        bookingDeadline,
                      ]);
                    }
                  } else {
                    // console.log('Loyalty cycle expired', bookingDeadline);
                  }
                }
              }
            );
          });
        }

      });
      // console.info(loyaltyCycleMap);
      this.addPastService(loyaltyCycleMap);
    });

    return archivebooking;
  }

  clearPastServices() {
    this.pastServicesSub$.next(new Map<string, any>());
  }

  getUserBookings$(userId: string, view = View.Active): Observable<List> {
    const responseToList = (response): List => {
      const list = new List({
        totalCount: response.totalCount,
      });

      if (response._embedded && response._embedded.booking) {
        list.items = response._embedded.booking.map(
          (bookingApiJson) => new Booking(bookingApiJson)
        );
      }

      return list;
    };

    const url =
      environment.apiUrl +
      '/users/' +
      userId +
      '/bookings?view=' +
      view +
      '&expand=provider,bookingselectionitems&pageSize=100&sortBy=start&sortOrder=' +
      (view === View.Active ? 'asc' : 'desc');

    return this.http.get(url).pipe(map(responseToList));
  }

  getBooking$(userId: string, bookingId: string) {
    const url =
      environment.apiUrl +
      '/users/' +
      userId +
      '/bookings/' +
      bookingId +
      '?expand=provider,bookingselectionitems,bookingproducts';

    return this.http.get(url).pipe(
      map((responseApiJson) => new Booking(responseApiJson)),
      tap((booking: Booking) => this.addBooking(booking))
    );
  }

  hasSimilarBooking$(me$, pick: Pick, days: number = 3) {
    const flatPickServices = pick.attendees
      .map((attendee) => attendee.services.map((service) => service.id))
      .flat();

    const doesBookingHaveTheSameService = (booking, flatPickServices) => {
      return booking.bookingAttendees?.some((BookingAttendee) => {
        return BookingAttendee.bookingAttendeeBookingServices?.some(
          (bookingAttendeeService) => {
            return flatPickServices.includes(
              bookingAttendeeService.bookingService?.service?.id
            );
          }
        );
      });
    };

    const doesUserHaveSimilarBookings = (
      activeBookings,
      pick: Pick,
      days: number = 3
    ) => {
      // console.log(activeBookings);
      // console.log(pick);

      const pickDate = new Date(pick?.combination?.start || new Date());

      if (!activeBookings.items) {
        return false;
      }

      for (const booking of activeBookings.items) {
        console.log(booking);

        const bookingDate = new Date(booking.start);

        if (!this.isWithinDays(bookingDate, pickDate, days)) {
          continue;
        }

        console.log('je znotraj dneva');

        if (doesBookingHaveTheSameService(booking, flatPickServices)) {
          console.log('je isti service');

          return true;
        }
      }

      return false;
    };

    return me$.pipe(
      switchMap((user: User) => this.getActiveBookings$(user.id)),
      map((activeBookings: any) =>
        doesUserHaveSimilarBookings(activeBookings, pick, days)
      )
    );
  }

  isWithinDays(bookingDate: Date, pickDate: Date, days: number): boolean {
    const differenceInDays = Math.floor(
      (pickDate.getTime() - bookingDate.getTime()) / (1000 * 3600 * 24)
    );
    return differenceInDays >= -days && differenceInDays <= days;
  }

  cancelBooking(booking: Booking) {
    return this.http.patch(booking._self, { canceled: true });
  }

  getPastServices$(): Observable<Map<string, any>> {
    return this.pastServices$;
  }
}
