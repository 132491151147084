import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'awesome-phonenumber';

@Pipe({
  name: 'ppPhone'
})
export class PpPhonePipe implements PipeTransform {

  constructor() {

  }

  transform(value: string | undefined, ...args: unknown[]): any {
    
    if ( value ) {

      const phoneNumber = parsePhoneNumber( value );

      if ( phoneNumber.valid ) {

        if ( 386 === phoneNumber.countryCode ) {
          return phoneNumber.number.national.replace(/\(|\)/g, '');
        }

        return phoneNumber.number.national;
        

      } else {

        return '';

      }

    }

  }

}
