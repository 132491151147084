import * as moment from "moment";
import { Price } from "./price.model"

export class CombinationAttendeeService {

  public start: string;
  public end: string;
  public durationInSeconds: number;
  public price: Price | undefined;
  public discount: number = 0;
  public serviceHref: string;
  public locationHref: string;
  public employeeHref: string;
  public id: number;
  public specialOffer: boolean = false;

  constructor(
    obj?: any
  ) {
    this.start = obj && obj.start || null;
    this.end = obj && obj.end || null;
    this.durationInSeconds = obj && obj.durationInSeconds || null;
    this.specialOffer = obj && obj.specialOffer || false;

    if ( obj && (obj.price || obj.price === 0) ) {
      this.price = new Price( obj.price, obj.currency ? obj.currency : 'EUR' );
    }
    this.discount = obj && obj.discount || 0;

    this.serviceHref = obj && obj.service || obj.serviceHref || null;
    this.locationHref = obj && obj.location || obj.locationHref || null;
    this.employeeHref = obj && obj.employee || obj.employeeHref || null;
    if (obj && obj.service) {
      this.id = +obj.service.split('/').pop();
    } else if (obj && obj.serviceHref) {
      this.id = +obj.serviceHref.split('/').pop();
    }
  }

  static revive(json: any): CombinationAttendeeService {
    const price = json.price ? Price.revive(json.price) : undefined;
    const combinationAttendeeService = new CombinationAttendeeService({...json});
    combinationAttendeeService.price = price;
    return combinationAttendeeService;
    //return new CombinationAttendeeService({...json,price});
  }
}
