<swiper-container #salonSwiper class="salon-swiper" [pagination]="{clickable: true, bulletClass: 'swiper-pagination-bullet nav-bullet', bulletActiveClass:'swiper-pagination-bullet-active nav-active-bullet'}" >
  <swiper-slide>
    <div class="slider salon-slider">
      <figure>
        <img src="{{avatar?.url}}" alt="{{avatar?.document?.title}}">
        <figcaption>{{avatar?.document?.title}}</figcaption>
      </figure>
    </div>
  </swiper-slide>

  <swiper-slide *ngFor="let image of images; index as i">
    <div class="slider salon-slider">
      <figure>
        <img src="{{image?.url}}" alt="{{image?.document?.title}}">
        <figcaption>{{image?.document?.title}} #{{i+2}}</figcaption>

      </figure>
    </div>
  </swiper-slide>
</swiper-container>