import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GalleryLightboxService } from 'src/app/core/services/gallery-lightbox.service';

@Component({
  selector: 'app-gallery-lightbox',
  templateUrl: './gallery-lightbox.component.html',
  styleUrls: ['./gallery-lightbox.component.scss'],
})
export class GalleryLightboxComponent  implements OnInit {
  lightboxSubscription: Subscription = new Subscription();
  lightboxSrcSubscription: Subscription = new Subscription();
  lightboxAltSubscription: Subscription = new Subscription();
  lightboxHrefSubscription: Subscription = new Subscription();
  lightboxCategoryHrefSubscription: Subscription = new Subscription();
  lightboxCategorySubscription: Subscription = new Subscription();
  lightboxFieldHrefSubscription: Subscription = new Subscription();
  lightboxFieldSubscription: Subscription = new Subscription();

  isLightboxOpen: boolean = false;
  lightboxSrc: string = '';
  lightboxAlt: string = '';
  lightboxHref: string = '';
  lightboxCategoryHref: string = '';
  lightboxCategory: string = '';
  lightboxFieldHref: string = '';
  lightboxField: string = '';

  @ViewChild('innerlightbox') innerlightbox: ElementRef | undefined;


  constructor(private lightboxService: GalleryLightboxService) { }

  ngOnInit(): void {
    this.lightboxSubscription = this.lightboxService.isLightboxOpen().subscribe(isOpen => {
      this.isLightboxOpen = isOpen;
    });

    this.lightboxSrcSubscription = this.lightboxService.getLightboxSrc().subscribe(src => {
      this.lightboxSrc = src;
    });

    this.lightboxAltSubscription = this.lightboxService.getLightboxAlt().subscribe(alt => {
      this.lightboxAlt = alt;
    });

    this.lightboxHrefSubscription = this.lightboxService.getLightboxHref().subscribe(href => {
      this.lightboxHref = href;
    });

    this.lightboxCategoryHrefSubscription = this.lightboxService.getLightboxCategoryHref().subscribe(categoryHref => {
      this.lightboxCategoryHref = categoryHref;
    } );

    this.lightboxCategorySubscription = this.lightboxService.getLightboxCategory().subscribe(category => {
      this.lightboxCategory = category;
    } );

    this.lightboxFieldHrefSubscription = this.lightboxService.getLightboxFieldHref().subscribe(fieldHref => {
      this.lightboxFieldHref = fieldHref;
    } );

    this.lightboxFieldSubscription = this.lightboxService.getLightboxField().subscribe(field => {
      this.lightboxField = field;
    } );
  }

  closeLightbox(): void {
    this.lightboxService.closeLightbox();
  }

  nextImage(): void {
    this.innerlightbox?.nativeElement.classList.add('swipeup');
    setTimeout(() => {
      this.innerlightbox?.nativeElement.classList.remove('swipeup');
      this.lightboxService.nextImage();
    },300);
  }
  prevImage(): void {
    this.innerlightbox?.nativeElement.classList.add('swipedown');
    setTimeout(() => {
      this.innerlightbox?.nativeElement.classList.remove('swipedown');
      this.lightboxService.prevImage();
    },300);
  }

  ngOnDestroy(): void {
    this.lightboxSubscription.unsubscribe();
    this.lightboxSrcSubscription.unsubscribe();
    this.lightboxAltSubscription.unsubscribe();
    this.lightboxHrefSubscription.unsubscribe();
    this.lightboxCategoryHrefSubscription.unsubscribe();
    this.lightboxCategorySubscription.unsubscribe();
    this.lightboxFieldHrefSubscription.unsubscribe();
    this.lightboxFieldSubscription.unsubscribe();
  }
}