<!--<ion-header>
  <ion-toolbar mode="ios">
    <ion-title >Izberi lokacijo</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-list>
    <ion-item *ngFor="let place of places" (click)="onSelected(place)">
      <ion-label><span *ngIf="searchPreferences.place?.id === place.id">*</span>{{ place.name }}</ion-label>
    </ion-item>
    <ion-item (click)="onSelected( null )">
      <ion-label>Vse lokacije (ne vem kaj izbrati)</ion-label>
    </ion-item>
  </ion-list>
</ion-content>-->

<ion-header class="ion-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios" color="primary">
    <ion-title class="header-title ion-text-left">Lokacija</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding search-modal" color="primary">
  <ion-searchbar #searchBar (ionChange)="searchFilter$.next(searchBar!.value!)" placeholder="Poišči lokacijo…" class="search-modal__search" mode="md"></ion-searchbar>

  <ion-list lines="none" color="primary" class="search-modal__list">
    <ion-item *ngIf="false && !searchBar.value" color="primary" class="clickable search-modal__list-item search-modal__list-item--location">
      <ion-icon name="locate" mode="md"></ion-icon>
    Trenutna lokacija
    </ion-item>
    <ion-item *ngFor="let place of places$ | async" (click)="onSelected(place)" color="primary" class="clickable search-modal__list-item">{{ place.name }}</ion-item>
  </ion-list>
</ion-content>