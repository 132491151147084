<div [routerLink]="['/zaposleni',employee?.slug || 'todo']">
  <div class="spacer_5"></div>
  <img src="{{ employee?.avatarUrl() }}" loading="lazy" alt="{{employee?.name}} - {{employee?.selectionItems?.[0]?.title}} - {{employee?.selectionItems?.[0]?.locations?.[0]?.name}} | Ekipa dm Studio" />
  <b>{{ employee?.name }}</b>
  <ng-container *ngIf="employee?.selectionItems as items">
    <div class="locations">
      <ng-container *ngFor="let item of items">
        <i *ngFor="let location of item.locations" [routerLink]="['/','salon',provider?.getLocationUrl(location.name)]" (click)="$event.stopPropagation()">
          <a href="/salon/{{provider?.getLocationUrl(location.name)}}">{{ location.name }}</a>
        </i>
      </ng-container>
    </div>
  </ng-container>
  <span *ngIf="employee?.selectionItems as items">{{ items[0].title }}</span>
  <div class="info">
    {{ employee?.longDescription }}
  </div>
</div>