import { Pick } from "./pick.model"

export class Cart {

  public picks: Pick[];

  constructor(
    obj?: any
  ) {
    this.picks = obj && obj.picks || [];
  }

  addPick( pick: Pick ) {
    this.picks.push( pick );
  }

  getPickByShopId( shopId: number ) {
    for( const pick of this.picks ) {
      if ( shopId === pick.shopId ) {
        return pick;
      }
    }

    const p = new Pick( { 'shopId': shopId } );
    this.addPick( p );
    return p;

  }

  reset() {
    this.picks = [];
  }

  static revive(json: any): Cart {
    const cart = new Cart();

    json.picks.map( (pick: any) => cart.addPick(Pick.revive(pick)));

    return cart;
  }
}