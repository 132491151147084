<div class="content">
  <div class="zaupamo mt-50">
    <div class="spacer_1"></div>
    <h2>KOMU ZAUPAMO?</h2>
    <div class="inner">
      <!--div class="item"><img src="assets/img/brands/afrodita.jpg"><b>Afrodita Professional</b><span>Izdelki iz linije K18Peptide™ predstavljajo znanstveni preboj na področju molekularne nege las in klinično potrjeno odpravijo poškodbe las v samo nekaj minutah. Izdelki K18 poškodovane lase zdravijo od znotraj navzven.
</span></div>

      <div class="item"><img src="assets/img/brands/depileve.jpg"><b>Depilève</b><span>Pri Depilève verjamejo, da bi moral celovit profesionalni sistem za depilacijo pokrivati široko paleto kozmetičnih izdelkov, ki kožo na depilacijo pripravijo in po tretmaju razkrijejo njeno lepoto. Pri postopku izbire smole za Depilève voske, so izredno natančni, kar omogoča fantastičen oprijem, barve in stabilnosti.
</span></div-->

      <!--div class="item"><img src="assets/img/brands/cnd.jpg"><b>CND</b><span>CND je vodila svetovna blagovna znamka na področju profesionalne nege rok, nog in nohtov. Podjetje je zelo predano napredku v industriji nege nohtov, zato veliko vlagajo v razvoj inovativnih izdelkov, ki so izjemno kakovostni. Izdelki, združeni pod blagovno znamko CND, so za svojo kakovost pogosto nagrajeni.</span></div-->
      <div class="item"><img src="assets/img/brands/alterna.jpg" alt="Alterna Caviar | dm Studio"><b>Alterna Caviar</b><span>Alterna kot pionir luksuzne nege las nenehno razvija vrhunske formule, ki temeljijo na inovativnih sestavinah in uporabi napredne tehnologije. Preizkusite ciljno usmerjene formule, ki so med najbolj iskanimi med stilisti in strokovnjaki za nego las.</span></div>
      <div class="item"><img src="assets/img/brands/indigo.jpg" alt="Indigo Nails | dm Studio"><b>Indigo Nails</b><span>Kozmetična blagovna znamka Indigo Nails je znana kot strokovnjakinja na področju oblikovanja nohtov, barvnih trendov in SPA kozmetike. Izdelki Indigo Nails so primerni tudi za naravno nego, predvsem pa so znani za podaljševanje nohtov z UV geli.</span></div>
      <div class="item"><img src="assets/img/brands/wella.jpg" alt="Wella Professionals | dm Studio"><b>Wella Professionals</b><span>Wella Professionals je premijska blagovna znamka lasne kozmetike, ki nudi široko paleto prvovrstnih izdelkov za nego las. Blagovna znamka je vodilna na področju barvanja las, o kakovosti izdelkov pa pričajo tudi številne prestižne nagrade. Barve za lase znamke Wella Professionals zagotavljajo učinkovitost, razkošje in kakovost.</span></div>
      <div class="item"><img src="assets/img/brands/skeyndor.jpg" alt="Skeyndor | dm Studio"><b>Skeyndor</b><span>Blagovna znamka Skeyndor vas popelje v svet sofisticirane nege, saj s svojimi luksuznimi izdelki zagovarja eleganco ter z nežno teksturo krem koži zagotavlja udobje in dobre rezultate. Osnova delovanja izdelkov so izredno učinkovite formule, ki temeljijo na nanotehnologiji in so podprte s številnimi kliničnimi študijami skozi 45-letno tradicijo.</span></div>
      <div class="item"><img src="assets/img/brands/maria-nila.jpg" alt="Maria Nila | dm Studio"><b>Maria Nila</b><span>Maria Nila ponuja široko paleto ekskluzivnih 100% veganskih izdelkov za nego las, ki so namenjeni ljubiteljem narave po vsem svetu. Vsak izdelek je skrbno razvit, da bi uporabnikom zagotovili popolno lepotno izkušnjo, vključno s kakovostjo, zasnovo in enostavno uporabo.</span></div>
       <div class="item"><img src="assets/img/brands/heaven.jpg" alt="Heaven | dm Studio"><b>Heaven</b><span>Kozmetiko Heaven by Deborah Mitchell sestavlja paleta razkošnih, naravnih izdelkov za nego kože. Organske sestavine v izdelkih izkoriščajo moč narave in so v popolnem sožitju z zadnjimi odkritji v kozmetični tehnologiji. Vsi izdelki so zasnovani brez spornih parabenov, ki lahko povzročijo alergijske reakcije, ne vsebujejo nanotehnologije in niso testirani na živalih.</span></div>

    </div>
  </div>
</div>

