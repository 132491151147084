import { environment } from '../../../environments/environment';

export class Document   {

  public id: string;
  public type: string;
  public fileName: string;
  public title: string;
  public created: string;
  public updated: string;

  constructor(
    obj?: any
  ) {

    this.id = obj && obj.id || null;
    this.type = obj && obj.type || null;
    this.fileName = obj && obj.fileName || null;
    this.title = obj && obj.title || null;
    this.created = obj && obj.created || null;
    this.updated = obj && obj.updated || null;
  }

  static revive(json: any): any {
    return new Document(json);
  }

}