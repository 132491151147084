import { enableProdMode, importProvidersFrom } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { UnauthInterceptor } from './app/core/http-interceptors/unauth-interceptor';


import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import "hammerjs";

if (environment.production) {
  enableProdMode();
}

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { enable:true, direction: Hammer.DIRECTION_ALL },
  };
}


bootstrapApplication(AppComponent, {
  providers: [
     {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthInterceptor,
      multi: true,
     },
     {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, // Whether to auto-login using existing session
        // Specify the providers you want to use
        providers: [
          // ... your social auth providers with their respective configs
        ],
      } as SocialAuthServiceConfig,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      IonicModule.forRoot({}),
      HttpClientModule,
      TranslateModule.forRoot({
        // ... your TranslateLoader configuration
      }),
      HammerModule,

    ),
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig},
    provideRouter(routes),
  ],
});
