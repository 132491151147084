import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PPAuthService } from "../services/auth.service";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate  {
  
  constructor(private authService: PPAuthService, private router: Router) {};

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean | Observable<boolean> | Promise<boolean> {
 

    let navigateToLogin = () => {

      this.router.navigate( ['auth', 'prijava'], { 
        queryParams: {
          return: state.url
        }
      });

    }

    if ( this.authService.isLoggedIn() ) {
      return true;
    }

    if ( !this.authService.getAccessToken() ) {
      navigateToLogin();
      return false;
    }

    return true;


    /*return new Promise((resolve, reject) => {

      this.authService.getMe$().subscribe(
        user => resolve(true),
        error => {
          navigateToLogin();
          resolve(false);
        }

      )

    });*/
    

   /* return this.authService.getMe$().pipe(
      tap( user => console.log(user)),
      map( user => true ),
      catchError( (error):Observable<boolean> => {
        navigateToLogin();
        return of(false); 
      })  
    )*/

  }


}
