import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'onlyServices'
})
export class OnlyServicesPipe implements PipeTransform {
  transform(items: Array<any>): any {
    if (!items) {
      return items;
    }

    return items.filter(item => {
      if (item.children) {
        if (item.children.length > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  }
}