import { Image } from './image.model';
import { ExecutionTimeRange } from './executionTimeRange.model';
import * as moment from 'moment';

export class Real {

  public id: string;
  public public: string;
  public availableForBooking: string;
  public slug: string;
  public name: string;
  public nameTranslations: Object;
  public shortName: string;
  public avatar: Image | undefined;
  public galleryFirst: Image | undefined;
  public gallery: Image[] | undefined;
  public geoPoint: any;
  public address: string;
  public countryCode: string;
  public email: string;
  public phone1: string;
  public timezone: string;
  public selectionItems: any;
  public provider: any;
  public parkingInfo: string;
  public freeParking: boolean;
  public longDescription: string;
  public longDescriptionTranslations: Object;
  public newOnMarketplaceDescription: string;
  public exposedOnMarketplaceDescription: string;
  public specialOfferOnMarketplaceDescription: string;
  public specialOfferOnMarketplacePrice: number;
  public specialOfferOnMarketplaceGift: boolean;
  public exposedOnMarketplaceShortDescription: boolean;
  public parent: Real | undefined;
  public wineList: string;
  public wineListTranslations: Object;
  public suppliers: string;
  public suppliersTranslations: Object;
  public slogan: string;
  public sloganTranslations: Object;
  public website: string;
  public instagram: string;
  public facebook: string;
  public googleMaps: string;
  public openingHours: string;
  public openingHoursTranslations: Object;
  public gaultMillau0: boolean;
  public gaultMillau1: boolean;
  public gaultMillau2: boolean;
  public gaultMillau3: boolean;
  public gaultMillau4: boolean;
  public gaultMillau5: boolean;
  public michelinGuide2022: boolean;
  public michelin1Star2022: boolean;
  public michelinGreenStar2022: boolean;
  public michelinBib2022: boolean;
  public laListe: boolean;
  public falstaff: boolean;
  public jre: boolean;
  public productPickRequired: boolean;

  public confirmationNeededPartySize: number;
  public contactUsPartySize: number;

  public hideALaCarteOption: boolean;
  public hideCannotDecideOption: boolean;


  public noShowProtection: boolean;
  public noShowProtectionTimeRange: ExecutionTimeRange | undefined;
  public noShowMaxPenaltyPerPerson: number;

  public paymentRequired: boolean;
  public paymentRequiredTimeRange: ExecutionTimeRange | undefined;

  public minSpendRequired: boolean;
  public minSpendAmount: number;
  public minSpendRequiredTimeRange: ExecutionTimeRange | undefined;

  public cancellationPeriod: number | undefined;

  public aLaCarteMenu: string;

  public lastMinute: boolean;
  public lastMinuteDiscountPercentage: number;
  public lastMinutePeriodInMinutesFromNow: number;

  private hardcodedNames = {
    'slovenska': 'Ljubljanski brivec Slovenska',
    'hotel-union': 'Ljubljanski brivec Hotel Union',
    'trubarjeva': 'YMS Trubarjeva',
    'citypark': 'YMS Citypark',
    'aleja': 'YMS Aleja',
    'ljubljana-mostec': 'Simple Mostec',
    'frizerski-salon-maribor-europark': 'Estela frizerski salon Maribor',
    'frizerski-studio-a-ana-rajar-s-p': 'Frizerski studio A',
    'kozmeticni-salon-chill-room': 'Snip Snap Chill Room',
    'frizerski-salon-snip-snap-professional': 'Snip Snap Professional',
    'frizerski-salon-ljubljana-btc': 'Estela frizerski salon BTC',
    'kozmeticni-salon-ljubljana-hipermarket-mercator': 'Estela kozmetični salon Ljubljana',
    'frizerski-salon-ljubljana-hipermarket-mercator': 'Estela frizerski salon Ljubljana',
    'pedimed-pe-ljubljana': 'Pedimed Ljubljana',
    'pedimed-pe-novo-mesto': 'Pedimed Novo mesto',
    'viki-s-place': 'Viki\'s Place Trg Republike',
    'viki-s-bar-wolfova': 'Viki\'s Bar Wolfova',
    'beethovnova': 'Viki\'s Bar Beethovnova',
    'viki-s-place-vosnjakova': 'Viki\'s Place Dvořakova',
    'dm-studio-kranj-bleiweisova': 'dm Studio Kranj Bleiweisova',
  }

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.public = obj && obj.public || null;
    this.availableForBooking = obj && obj.availableForBooking || null;
    this.slug = obj && obj.slug || null;
    this.name = obj && obj.name || null;
    this.nameTranslations = obj && obj.nameTranslations || null;
    this.shortName = obj && obj.shortName || null;
    this.geoPoint = obj && obj.geoPoint || null;
    this.address = obj && obj.address || null;
    this.countryCode = obj && obj.countryCode || null;
    this.email = obj && obj.email || null;
    this.phone1 = obj && obj.phone1 || null;
    this.timezone = obj && obj.timezone || null;
    this.selectionItems = obj && obj.selectionItems || null;
    this.provider = obj && obj.provider || null;
    this.parkingInfo = obj && obj.parkingInfo || null;
    this.freeParking = obj && obj.freeParking || null;
    this.longDescription = obj && obj.longDescription || null;
    this.longDescriptionTranslations = obj && obj.longDescriptionTranslations || null;
    this.newOnMarketplaceDescription = obj && obj.newOnMarketplaceDescription || null;
    this.exposedOnMarketplaceDescription = obj && obj.exposedOnMarketplaceDescription || null;
    this.specialOfferOnMarketplaceDescription = obj && obj.specialOfferOnMarketplaceDescription || null;
    this.specialOfferOnMarketplacePrice = obj && obj.specialOfferOnMarketplacePrice || null;
    this.specialOfferOnMarketplaceGift = obj && obj.specialOfferOnMarketplaceGift || null;
    this.exposedOnMarketplaceShortDescription = obj && obj.exposedOnMarketplaceShortDescription || null;

    this.wineList = obj && obj.wineList || null;
    this.wineListTranslations = obj && obj.wineListTranslations || null;
    this.suppliers = obj && obj.suppliers || null;
    this.suppliersTranslations = obj && obj.suppliersTranslations || null;
    this.slogan = obj && obj.slogan || null;
    this.sloganTranslations = obj && obj.sloganTranslations || null;
    this.website = obj && obj.website || null;
    this.instagram = obj && obj.instagram || null;
    this.facebook = obj && obj.facebook || null;
    this.googleMaps = obj && obj.googleMaps || null;
    this.openingHours = obj && obj.openingHours || null;
    this.openingHoursTranslations = obj && obj.openingHoursTranslations || null;

    this.gaultMillau0 = obj && obj.gaultMillau0 || null;
    this.gaultMillau1 = obj && obj.gaultMillau1 || null;
    this.gaultMillau2 = obj && obj.gaultMillau2 || null;
    this.gaultMillau3 = obj && obj.gaultMillau3 || null;
    this.gaultMillau4 = obj && obj.gaultMillau4 || null;
    this.gaultMillau5 = obj && obj.gaultMillau5 || null;
    this.michelinGuide2022 = obj && obj.michelinGuide2022 || null;
    this.michelin1Star2022 = obj && obj.michelin1Star2022 || null;
    this.michelinGreenStar2022 = obj && obj.michelinGreenStar2022 || null;
    this.michelinBib2022 = obj && obj.michelinBib2022 || null;
    this.jre = obj && obj.jre || null;
    this.falstaff = obj && obj.falstaff || null;
    this.laListe = obj && obj.laListe || null;

    this.productPickRequired = obj && obj.productPickRequired || null;
    this.hideALaCarteOption = obj && obj.hideALaCarteOption || null;
    this.hideCannotDecideOption = obj && obj.hideCannotDecideOption || null;

    this.confirmationNeededPartySize = obj && obj.confirmationNeededPartySize || null;
    this.contactUsPartySize = obj && obj.contactUsPartySize || null;

    this.noShowProtection = obj && obj.noShowProtection || null;
    this.noShowMaxPenaltyPerPerson = obj && obj.noShowMaxPenaltyPerPerson || null;
    this.paymentRequired = obj && obj.paymentRequired || null;
    this.minSpendRequired = obj && obj.minSpendRequired || null;
    this.minSpendAmount = obj && obj.minSpendAmount || null;
    this.aLaCarteMenu = obj && obj.aLaCarteMenu || null;

    this.lastMinute = obj && obj.lastMinute || false;
    this.lastMinuteDiscountPercentage = obj && obj.lastMinuteDiscountPercentage || null;
    this.lastMinutePeriodInMinutesFromNow = obj && obj.lastMinutePeriodInMinutesFromNow || null;

    if ( obj && obj.avatar ) {
      this.avatar = ( obj.avatar instanceof Image ) ? obj.avatar: new Image( obj.avatar );
    }

    if ( obj && obj.parent ) {
      this.parent = ( obj.parent instanceof Real ) ? obj.parent: new Real( obj.parent );
    }

    if ( obj && obj.galleryFirst ) {
      this.galleryFirst = ( obj.galleryFirst instanceof Image ) ? obj.galleryFirst: new Image( obj.galleryFirst );
    }

    if ( obj && obj.gallery ) {

       this.gallery = [];

       for (const image of obj.gallery ) {

         if ( image && image.document && image.document.type !== 'gallery') { //do not include avatars
           continue;
         }

         if ( image instanceof Image ) {
           this.gallery.push( image );
         } else {
           this.gallery.push( new Image( image ) );
         }

       }

    }

  }

  hasSlotNoShowProtection( start: string ) {

    console.log(start);

    const startM = moment( start );

    const isFridayEvening = startM.day() === 5 && startM.hour() >= 18 && startM.hour() < 24;
    const isSaturdayEvening = startM.day() === 6 && startM.hour() >= 18 && startM.hour() < 24;

    if ( isFridayEvening || isSaturdayEvening ) {
      return true;
    }

    return false;

  }

  getUrlArr() {
    let url = ['restavracija'];

    if ( !this.provider  || !this.provider.id || !this.slug ) {
      return ['/'];
    }

    return ['restavracija', this.provider.id, this.slug];

  }

  getMetaTitleName() {
    const composedName = this.getComposedName();
    const townFromAddress = this.getTownFromAddress() || '';

    if (typeof composedName === 'string' && composedName.includes(townFromAddress)) {
      return composedName;
    } else {
      return composedName + ' ' + townFromAddress;
    }
  }


  getTownFromAddress() {

    if ( !this.address || this.address.length < 3 ) {
      return '';
    }

    return this.address.split(" ").pop();

  }

  getComposedName() {

    if ( this.slug && Object.prototype.hasOwnProperty.call(this.hardcodedNames, this.slug) ) {
      return Object.prototype.hasOwnProperty.call(this.hardcodedNames, this.slug);
    }



    if ( this.provider && this.provider.brandName && this.provider.brandName !== this.name ) {

      if ( this.provider.brandName === 'Rožmarin') {
        return this.name;
      }

      if ( this.provider.brandName === 'Boccaccio') {
        return this.name;
      }

      if ( this.provider.brandName === 'Gjerkeš') {
        return this.name;
      }

      if ( this.provider.brandName === 'Baščaršija') {
        return this.name;
      }

      return this.provider.brandName + ' ' +  this.name;

    } else {

      return this.name;
    }

  }


  getOrderedImages() {

    let files: Image[] = [];

    if ( this.galleryFirst?.document?.fileName ) {

      files.push( this.galleryFirst );

    }

    if ( this.gallery && this.gallery.length > 0 ) {

      for( let image of this.gallery ) {

        if ( !this.galleryFirst || this.galleryFirst?.document?.fileName !== image.document?.fileName ) {
          files.push( image );
        }

      }

    }


    if ( files.length === 0 ) {

      if (this.avatar?.document?.fileName ) {
        files.push( this.avatar );

      }

    }

    return files;

  }



}
