<div class="phone-modal">
  <ng-container *ngIf="me$ | async as me">

  <ng-container *ngIf="!me.phoneVerified && !!me.phone">

  <form class="phone-modal__form form" #codeF (ngSubmit)="onCodeFormSubmit( submitBtn )">

    <div  class="ion-padding">
    <h1 class="phone-modal__form-title">Vpišite potrditveno kodo</h1>

    <p class="phone-modal__form-desc">Na vaš telefon smo poslali sms s številko, ki jo morate vpisati v spodnje okno.</p>

    <ion-list>

      <ion-item class="ion-no-padding" mode="md">
        <ion-label position="stacked" mode="md" class="phone-modal__form-label">Sms potrditvena koda</ion-label>
        <ion-input class="phone-modal__form-input" name="code" [(ngModel)]="code" type="text" id="code" placeholder="Vpišite prejeto številko v SMS sporočilu."></ion-input>
      </ion-item>

     </ion-list>

    <ion-button class="btn--large" type="submit" expand="block">Potrdi kodo</ion-button>
    <p class="ion-text-center register-prompt">Niste prejeli kode? <a class="pp-link" (click)="resendCode(); $event.stopPropagation()">Pošlji še enkrat</a></p>

    </div>
  </form>

  <hr/>

  </ng-container>

  <form class="phone-modal__form form" #phoneF="ngForm"  (ngSubmit)="onPhoneFormSubmit( submitBtn )">

    <div  class="ion-padding">
    <h3 class="phone-modal__form-title">Vaša telefonska številka</h3>

    <p class="phone-modal__form-desc">Vpišite mobilno številko na katero vas lahko kontaktiramo v primeru spremembe rezervacije.</p>

    <ion-list>

      <ion-item class="ion-no-padding" mode="md">
        <ion-label mode="md" class="phone-modal__form-label" position="stacked" position="stacked">Telefonska številka</ion-label>
        <ion-input name="phone" class="phone-modal__form-input" [ngModel]="formatter(phone)" (ngModelChange)="phone = parser($event)" type="text" id="phone" placeholder="npr. 031 xxx xxx"></ion-input>
      </ion-item>

     </ion-list>

    <ion-button class="btn--large" [disabled]="phoneF.invalid || !isPhoneValid() || phone === originalPhone" type="submit" #submitBtn expand="block"> Shrani in pošlji potrditveni SMS</ion-button>
    <p class="ion-text-center register-prompt">Imate težave z rezervacijo? Pokličite na 031 312 039 za pomoč.</p>
    </div>
  </form>

</ng-container>
</div>


