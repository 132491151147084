import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, filter, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';

import { ParamMap } from '@angular/router';
import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { ProviderService } from '../models/provider-service.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceDetailService {
  [x: string]: any;

  constructor( private http: HttpClient ) { }

  getServiceBySlug$( slug: string | null ) {

    if (!slug) {
      return throwError('Invalid slug provided'); // Immediately throw an error if the slug is null or undefined
    }

    let url = environment.apiUrl + '/providers/' + environment.providerId + '/services?slug=' + slug + '&expand=children,recommendations,parentSlug,grandParentSlug,grandGrandParentSlug,grandParentId,grandGrandParentId';

    const responseToModel = ( response: any): any =>  {
      // console.log(response);

      if ( response._embedded && response._embedded.service && response._embedded.service[0] ) {
        return new ProviderService( response._embedded.service[0] );
      }

    }

    return this.http.get( url ).pipe(
      map( response => responseToModel( response ) )
    );

  }

  getServiceById$( id: number | null ) {

    if (!id) {
      return throwError('Invalid id provided'); // Immediately throw an error if the slug is null or undefined
    }

    let url = environment.apiUrl + '/providers/' + environment.providerId + '/services/' + id + '?expand=children,recommendations,parentSlug,grandParentSlug,grandGrandParentSlug,grandParentId,grandGrandParentId';

    const responseToModel = ( response: any): any =>  {
      // console.log(response);

      if ( response ) {
        return new ProviderService( response );
      }

    }

    return this.http.get( url ).pipe(
      map( response => responseToModel( response ) )
    );

  }


  getServiceByPageRoute$( params$: Observable<ParamMap> ) {

    //console.log('a');

    return params$.pipe(

      // Get slug from query params stream
      map(params => {
        if (params.has('serviceSlug')) return params.get('serviceSlug');
        if (params.has('categorySlug')) return params.get('categorySlug');
        if (params.has('fieldSlug')) return params.get('fieldSlug');
        return null;
      }),

      // Filter out null values
      filter(serviceEntitySlug => !!serviceEntitySlug),

      // Fetch data by slug
      switchMap( serviceEntitySlug => this.getServiceBySlug$(serviceEntitySlug) )

    );
  }


  getSpecialOfferServices$( ) {

    let url = environment.apiUrl + '/providers/' + environment.providerId + '/services?exclusiveEvent=1&pageSize=16&expand=children,recommendations,parentSlug,grandParentSlug,grandGrandParentSlug,grandParentId,grandGrandParentId'; //specialOffer=1
    // let url = environment.apiUrl + '/providers/' + environment.providerId + '/services?pageSize=16&expand=children,recommendations,parentSlug,grandParentSlug,grandGrandParentSlug,grandParentId,grandGrandParentId'; //specialOffer=1

    const responseToModel = ( response: any): any =>  {


      if ( response._embedded && response._embedded.service ) {
        //console.dir(response);
        return response._embedded.service.map(
          (service: any) => new ProviderService( service )
        ).filter((service: ProviderService)=>service.lvl>1) // filter out categories for now

      } else {
        return [];
      }

    }


    return this.http.get( url ).pipe(
      map( response => responseToModel( response ) ),
      //tap(console.log)
    );

  }


  getRecommendedServices$( ) {

    let url = environment.apiUrl + '/providers/' + environment.providerId + '/services?expand=recommendations&slug=zensko-strizenje-do-ramen-1';

    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.service ) {
        let responseArray: any = [];
        response._embedded?.service[0]?.recommendation1 && responseArray.push(new ProviderService(response._embedded.service[0].recommendation1));
        response._embedded?.service[0]?.recommendation2 && responseArray.push(new ProviderService(response._embedded.service[0].recommendation2));
        response._embedded?.service[0]?.recommendation3 && responseArray.push(new ProviderService(response._embedded.service[0].recommendation3));
        response._embedded?.service[0]?.recommendation4 && responseArray.push(new ProviderService(response._embedded.service[0].recommendation4));
        return responseArray;
      } else {
        return [];
      }
    }

    return this.http.get( url ).pipe(
      map( response => responseToModel( response ) ),
      tap(console.log)
    );

  }

}
