import { Component, OnInit, Input } from '@angular/core';
import { Provider } from '../../core/models/provider.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent  implements OnInit {

  @Input() provider?: Provider;

  constructor() { }

  ngOnInit() {}

}
