  <ion-menu contentId="main-content" >
    <ion-content>
      <ion-padding>
        <ion-menu-toggle>
          <div class="hamburger">
          </div>
        </ion-menu-toggle>
        <div class="logo"><a href="#" [routerLink]="['/']" (click)="closeMenu('')"><img class="clickable"  src="assets/img/logo_v1.png" alt="dm Studio"></a></div>
        <ul class="mobilemenu">
          <li [ngClass]="{'expanded': isExpanded('frizerske') }" >
           <a *ngIf="!isExpanded('frizerske')" (click)="toggleMenu('frizerske')">Frizerske storitve <b></b></a>
           <a *ngIf="isExpanded('frizerske')" [routerLink]="['/frizerske-storitve']" (click)="closeMenu('frizerske')">Frizerske storitve</a> <b (click)="$event.stopPropagation();toggleMenu('frizerske')"></b>
            <ul class="menu2 options">
              <!-- <li><a [routerLink]="['/frizerske-storitve', 'svetovanje']" href="#" (click)="closeMenu('frizerske')">Svetovanje</a></li> -->
              <li><a [routerLink]="['/frizerske-storitve', 'strizenje']" href="#" (click)="closeMenu('frizerske')">Striženje</a></li>
              <li><a [routerLink]="['/frizerske-storitve', 'oblikovanje-priceske']" href="#" (click)="closeMenu('frizerske')">Oblikovanje pričeske</a></li>
              <li><a [routerLink]="['/frizerske-storitve', 'nega-las-in-lasisca']" href="#" (click)="closeMenu('frizerske')">Nega las in lasišča</a></li>
              <li><a [routerLink]="['/frizerske-storitve', 'koloristika']" href="#" (click)="closeMenu('frizerske')">Koloristika</a></li>
              <li><a [routerLink]="['/frizerske-storitve', 'trajno-oblikovanje-priceske']" href="#" (click)="closeMenu('frizerske')">Trajno oblikovanje pričeske</a></li>
              <li><a [routerLink]="['/frizerske-storitve', 'podaljsevanje-las']" href="#" (click)="closeMenu('frizerske')">Podaljševanje las</a></li>
            </ul>
          </li>
          <li [ngClass]="{'expanded': isExpanded('kozmeticne') }">
            <a *ngIf="!isExpanded('kozmeticne')" (click)="toggleMenu('kozmeticne')" >Kozmetične storitve <b></b></a>
            <a *ngIf="isExpanded('kozmeticne')" [routerLink]="['/kozmeticne-storitve']"  (click)="closeMenu('kozmeticne')" >Kozmetične storitve</a> <b (click)="$event.stopPropagation();toggleMenu('kozmeticne')"></b>
            <ul class="menu2 options">
              <!-- <li><a [routerLink]="['/kozmeticne-storitve', 'svetovanje']" href="#" (click)="closeMenu('kozmeticne')">Svetovanje</a></li> -->
              <li><a [routerLink]="['/kozmeticne-storitve', 'manikura']" href="#" (click)="closeMenu('kozmeticne')">Manikura</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'podaljsevanje-nohtov']" href="#" (click)="closeMenu('kozmeticne')">Podaljševanje nohtov</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'pedikura']" href="#" (click)="closeMenu('kozmeticne')">Pedikura</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'napredna-kozmeticna-analiza-koze']" href="#" (click)="closeMenu('kozmeticne')">Analiza kože</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'nega-obraza']" href="#" (click)="closeMenu('kozmeticne')">Nega obraza</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'obrvi-in-trepalnice']" href="#" (click)="closeMenu('kozmeticne')">Obrvi in trepalnice</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'depilacija']" href="#" (click)="closeMenu('kozmeticne')">Depilacija</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'masaze-in-preoblikovanje-telesa']" href="#" (click)="closeMenu('kozmeticne')">Masaže in preoblikovanje telesa</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'licenje']" href="#" (click)="closeMenu('kozmeticne')">Ličenje</a></li>
              <!-- <li><a [routerLink]="['/kozmeticne-storitve', 'mikropigmentacija-long-time-liner']" href="#" (click)="closeMenu('kozmeticne')">Mikropigmentacija</a></li>
              <li><a [routerLink]="['/kozmeticne-storitve', 'vitaminski-mayerjev-koktajl-za-krepitev-imunskega-sistema']" href="#" (click)="closeMenu('kozmeticne')">Vitaminski koktajl</a></li> -->
            </ul>
          </li>
          <li [ngClass]="{'sel': isMenuItemActive('aktualno') }"><a [routerLink]="['/aktualno']" href="#"  (click)="closeMenu('aktualno')">Aktualno</a></li>
          <li [ngClass]="{'sel': isMenuItemActive('galerija') }"><a [routerLink]="['/galerija']" href="#"  (click)="closeMenu('galerija')">Galerija</a></li>
          <li [ngClass]="{'sel': isMenuItemActive('ekipa') }" ><a [routerLink]="['/ekipa']" href="#"  (click)="closeMenu('ekipa')">Ekipa</a></li>
          <li [ngClass]="{'sel': isMenuItemActive('saloni') }"><a [routerLink]="['/saloni']" href="#"  (click)="closeMenu('saloni')">Saloni</a></li>
          <li><div class="phone"><a href="{{ 'tel:'+(provider?.phone1 | ppPhone) }}">{{ provider?.phone1 | ppPhone }}</a></div></li>

        </ul>

      </ion-padding>
    </ion-content>
  </ion-menu>