import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Provider } from '../models/provider.model';
import { ReplaySubject } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  // private provider:Subject<Provider> = new ReplaySubject<Provider>(1);
  private providerSubject = new ReplaySubject<Provider>(1);
  // provider$ = this.provider.asObservable();
  provider$ = this.providerSubject.asObservable();

  constructor(private http: HttpClient) {
    // console.log('ProviderService constructor');
    this.fetchProvider();
  }

  // addProvider( provider: Provider ) {
  //   this.provider.next( provider );
  // }

  fetchProvider(lightweight = false) {
    const responseToModel = (response: any): any => {
      if (
        response._embedded &&
        response._embedded.provider &&
        response._embedded.provider[0]
      ) {
        return new Provider(response._embedded.provider[0]);
      }
    };

    // let expandQueryString = '&expand=reals,services,selectionItems,service';
    // let expandQueryString = '&expand=reals,services';
    let expandQueryString = '&expand=reals';

    if (lightweight) {
      expandQueryString = '';
    }

    const url =
      environment.apiUrl +
      '/providers?slug=' +
      environment.providerSlug +
      expandQueryString;

    return this.http
      .get(url)
      .pipe(
        map((response) => responseToModel(response)),
        // tap( r => console.log(r)),
        filter((provider) => provider.id !== null),
        tap((provider) => this.providerSubject.next(provider))
        // tap( provider => this.addProvider(provider) )
      )
      .subscribe();
  }

  getProvider$() {
    return this.provider$;
  }

  getProviderServices$() {
    const url =
      environment.apiUrl +
      '/providers/' +
      environment.providerId +
      '/services?slug=vse&pageSize=16&expand=children,parentSlug,grandParentSlug,grandGrandParentSlug,grandParentId,grandGrandParentId'; //specialOffer=1

    const responseToModel = (response: any): any => {
      if (response._embedded && response._embedded.service) {
        //console.dir(response);
        return response._embedded.service
          .map((service: any) => new ProviderService(service))
          // .filter((service: ProviderService) => service.lvl > 1); // filter out categories for now
      } else {
        return [];
      }
    };

    return this.http.get(url).pipe(
      map((response) => responseToModel(response))
      //tap(console.log)
    );
  }
}