<div class="calendar nosel">
<!--  <ng-container *ngIf="days$ | async as days; else loading">-->
<!--  <ng-container *ngIf="days$ | async as days; else loading">-->
  <ng-container *ngIf="currentList as days; else loading">

    <swiper-container #slidesElem pager="false" [options]="slideOpts" class="inner">
      <swiper-slide class="item slide clickable" *ngFor="let day of days.items" (click)="selectDay(day)" [ngClass]="{'sel': (selectedDay===day.date)}" title="{{title(day)}}">
        <div class="item_border">
          <i>{{getMonth(day.date) | substring:0:4}}</i>
          <b>{{getDayOfMonth(day.date)}}</b>
          <span>{{getDayOfWeek(day.date)}}</span>
          <div [ngClass]="{
          'silver': (!day.hasSlots && !day.isOpen),
          'green': (day.hasSlots),
          'red': (!day.hasSlots && day.isOpen),
          'lastMinute': (day.hasSlots && isLastMinute(day) && hasLastMinuteSlots?.get(day.date) !== false)}">
          </div>
        </div>
<!--        <div class="waitlist" *ngIf="hasUnavailableSlots() && !day.hasSlots && day.isOpen && isBeforeFirstAvailableDay(day)">-->
<!--        {{hasUnavailableSlots()}}-->
        <div class="waitlist" *ngIf="hasUnavailableSlots() && !day.hasSlots && day.isOpen">
          <ion-checkbox [checked]="isDayInWaitlist(day)" (ionChange)="onWaitlistChange($event, day);"></ion-checkbox>
        </div>
      </swiper-slide>
    </swiper-container>

  </ng-container>
  <div class="next" (click)="slideRight()"></div>
  <div class="prev" (click)="slideLeft()"></div>

  <ng-template #loading>
    <swiper-container #slidesElem pager="false" [options]="slideOpts" class="inner">
      <ng-container *ngFor="let _ of [].constructor(10)">
        <swiper-slide class="item slide clickable">
          <i><ion-skeleton-text style="width: 30px;display: inline-block;" [animated]="true"></ion-skeleton-text></i>
          <b><ion-skeleton-text style="width: 30px;display: inline-block;"  [animated]="true"></ion-skeleton-text></b>
          <span><ion-skeleton-text style="width: 20px;display: inline-block;"  [animated]="true"></ion-skeleton-text></span>
          <div class="silver"></div>
        </swiper-slide>
      </ng-container>


    </swiper-container>

  </ng-template>
</div>
