import { Employee } from './employee.model';
import { ProviderService } from './provider-service.model';
import { Real } from './real.model';

export class Provider {
  public id: string;
  public avatar: string;
  public slug: string;
  public brandName: string;
  public slogan: string;
  public description: string;
  public companyName: string;
  public companyAddress: string;
  public companyCountryCode: string;
  public timezone: string;
  public currency: string;
  public taxId: string;
  public vatRegistered: string;
  public registrationId: string;
  public transactionAccount1: string;
  public phone1: string;
  public email: string;
  public instagram: string;
  public employees: Employee[] = [];
  public reals: Real[] = [];
  public services: ProviderService[] = [];

  public recommendationsDiscount: number;

  constructor(obj?: any) {
    this.id = (obj && obj.id) || null;
    this.avatar = (obj && obj.avatar) || null;
    this.slug = (obj && obj.slug) || null;
    this.brandName = (obj && obj.brandName) || null;
    this.slogan = (obj && obj.slogan) || null;
    this.description = (obj && obj.description) || null;
    this.companyName = (obj && obj.companyName) || null;
    this.companyAddress = (obj && obj.companyAddress) || null;
    this.companyCountryCode = (obj && obj.companyCountryCode) || null;
    this.timezone = (obj && obj.timezone) || null;
    this.currency = (obj && obj.currency) || null;
    this.taxId = (obj && obj.taxId) || null;
    this.vatRegistered = (obj && obj.vatRegistered) || null;
    this.registrationId = (obj && obj.registrationId) || null;
    this.transactionAccount1 = (obj && obj.transactionAccount1) || null;
    this.phone1 = (obj && obj.phone1) || null;
    this.email = (obj && obj.email) || null;
    this.instagram = (obj && obj.instagram) || null;
    this.recommendationsDiscount = (obj && obj.recommendationsDiscount) || null;

    if (obj.services) {
      for (const s of obj.services) {
        if (s instanceof ProviderService) {
          this.services.push(s);
        } else {
          this.services.push(new ProviderService(s));
        }
      }
    }

    if (obj.employees) {
      for (const e of obj.employees) {
        if (e instanceof Employee) {
          this.employees.push(e);
        } else {
          this.employees.push(new Employee(e));
        }
      }
    }

    if (obj.reals) {
      for (const e of obj.reals) {
        if (e.public) {
          if (e instanceof Real) {
            // only use public?
            this.reals.push(e);
          } else {
            this.reals.push(new Real(e));
          }
        }
      }
    }
  }

  getLocationUrl(location: any) {
    for (const real of this.reals) {
      // console.info('real', real, real.name, location);
      if (real.name === location) {
        // console.warn("same");
        return real.slug;
      } else {
        // console.error("diff");
      }
    }
    return '';
  }
}