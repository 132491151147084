import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective {
  @Input('appTooltip') tooltipTitle: string;
  tooltip: HTMLElement | null = null;

  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.show();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltip) {
      this.hide();
    }
  }

  show() {
    this.create();
    this.setPosition();
    this.tooltip?.classList.add('ng-tooltip-show');
  }

  hide() {
    this.tooltip?.classList.remove('ng-tooltip-show');
    this.tooltip?.remove();
    this.tooltip = null;
  }

  create() {
    this.tooltip = document.createElement('span');
    this.tooltip.innerHTML = this.tooltipTitle;
    this.tooltip.className = 'ng-tooltip';
    document.body.appendChild(this.tooltip);
  }

  setPosition() {
    const elemRect = this.el.nativeElement.getBoundingClientRect();
    const tooltipRect = this.tooltip?.getBoundingClientRect();
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const topPos = elemRect.top - (tooltipRect?.height || 0) - 12 + scrollPos;
    const leftPos = elemRect.left + (elemRect.width - (tooltipRect?.width ||0)) / 2;
    this.tooltip!.style.top = `${topPos}px`;
    this.tooltip!.style.left = `${leftPos}px`;
  }
}
