import { ProviderService } from './provider-service.model';
import { Price } from './cart/price.model';
import { Worker } from './worker.model';
import { Location } from './location.model';
import * as moment from 'moment';

export class BookingService {

  public id: string;
  public price: Price;
  public start: string;
  public end: string;
  public service: ProviderService;
  public location: Location;
  public worker: Worker;
  public durationInSeconds: number;
  public discountPercentage: number;

  constructor(
    obj?: any
  ) {
    this.id = obj && obj.id || null;
    this.start = obj && obj.start || null;
    this.end = obj && obj.end || null;
    this.discountPercentage = obj && obj.discountPercentage || 0;

    if ( obj && undefined !== obj.price ) {
      this.price = ( obj.price instanceof Price ) ? obj.price : ( new Price( obj.price, obj.currency ? obj.currency : 'EUR' ) );
    }

    if ( obj && obj.location && obj.location instanceof Location ) {
      this.location = obj.location;
    }

    if ( obj && obj.worker && obj.worker instanceof Worker ) {
      this.worker = obj.worker;
    }

    if ( obj && obj.bookingServiceSelectionItems ) {

      for (const bssi of obj.bookingServiceSelectionItems ) {

        //smth wrong, data missing
        if ( !bssi.selectionItem || !bssi.selectionItem.selection || !bssi.selectionItem.selection.resourceType ) {
          continue;
        }

        if ( 'real' === bssi.selectionItem.selection.resourceType ) {
          this.location = new Location( bssi.selectionItem );
        } else if ( 'employee' === bssi.selectionItem.selection.resourceType ) {
          this.worker = new Worker( bssi.selectionItem );
        }

      }

    }

    if ( obj && obj.service ) {
      this.service = ( obj.service instanceof ProviderService ) ? obj.service : new ProviderService( obj.service ) ;
    }

    if ( this.start && this.end ) {
      const ms = moment(this.start);
      const me = moment(this.end);
      this.durationInSeconds =  me.diff(ms, 'seconds');
    }

  }

}