import { Inject, Injectable } from '@angular/core';
import { Cart } from '../models/cart/cart.model';
import { Attendee } from '../models/cart/attendee.model';
import { ModalService } from 'src/app/modal.service';
import { PPAuthService } from './auth.service';
import { Service } from '../models/cart/service.model';
import { ServiceDetailService } from './service-detail.service';
import { DOCUMENT } from '@angular/common';
import { ProviderService as ProviderServiceModel } from '../models/provider-service.model';
import { ProviderService } from './provider.service';
import { Provider as ProviderModel } from '../models/provider.model';
import { MyBookingsService } from './my-bookings.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import {WaitingSpotData} from "../interfaces/waiting-spot.interface";


@Injectable({
  providedIn: 'root',
})
export class CartService {
  cart: Cart;
  selectedPerson: number = 0;
  firstItemRecommendations: Service[] | undefined = undefined;
  serviceDependencies: Service[] | undefined = undefined;
  provider: ProviderModel;
  waitingSpot?: WaitingSpotData | undefined = undefined;
  public loyaltyServices: BehaviorSubject<Map<string, Service>> = new BehaviorSubject<Map<string, Service>>(new Map<string, Service>());

  constructor(
    private modalService: ModalService,
    private authService: PPAuthService,
    private serviceDetailService: ServiceDetailService,
    private providerService: ProviderService,
    private myBookingsService: MyBookingsService,

    @Inject(DOCUMENT) private document: Document
  ) {
    if (sessionStorage.getItem('cart')) {
      this.cart = this.restoreCartFromSession();
    } else {
      this.cart = new Cart();
    }
    // sync to session storage
    this.saveCartToSession(this.cart);

    this.providerService.getProvider$().subscribe((provider: ProviderModel) => {
      this.provider = provider;
    });
  }

  restoreCartFromSession(): Cart {
    const restoreJson = sessionStorage.getItem('cart') || '{}';
    const parsedJson = JSON.parse(restoreJson);
    const revived = Cart.revive(parsedJson);
    return revived;

    //return JSON.parse(restoreJson, Cart.revive);
    //return new Cart(JSON.parse(sessionStorage.getItem('cart') || '{}', Cart.revive));
  }

  saveCartToSession(cart: Cart) {
    //console.log('saveCartToSession pre', cart.picks[0]);
    // console.log(cart.getPickByShopId( 107 )?.attendees.length);
    // console.log(this.getPick().attendees.length);
    this.checkForUnjustDiscount();

    if (cart.getPickByShopId(107)?.attendees.length > 0) {
      // cart is not empty, save to session storage
      sessionStorage.setItem('cart', JSON.stringify(cart));
    } else {
      // cart is empty, remove from session storage
      sessionStorage.removeItem('cart');
    }
    //console.log('saveCartToSession post', this.restoreCartFromSession());
  }

  reset() {
    this.cart = new Cart();
    this.saveCartToSession(this.cart);
  }

  getCart() {
    return this.cart;
  }

  getPick() {
    return this.cart.getPickByShopId(107);
  }

  getWaitingSpot() {
    return this.waitingSpot;
  }

  setWaitingSpot(waitingSpot: WaitingSpotData | undefined) {
    this.waitingSpot = waitingSpot;
  }

  addAttendee() {
    if (this.getPick().attendees.length < 4) {
      this.getPick().addAttendee(
        new Attendee({ name: 'Oseba ' + (this.getPick().attendees.length + 1) })
      );
    }

    this.updateAttendeeNames();

    this.selectedPerson = this.getPick().attendees.length - 1;

    this.hideRecommended();
  }

  removeAttendee(i: number) {
    this.getPick().removeAttendee(this.getPick().attendees[i]);
    if (this.getPick().attendees.length < 1) {
      this.selectedPerson = 0;
      this.reset();
    } else {
      this.selectedPerson = this.getPick().attendees.length - 1;
    }
    this.updateAttendeeNames();

    this.hideRecommended();
  }

  getAttendeeSize() {
    return this.getPick().attendees?.length || 0;
  }

  async addService(
    service: any,
    attendeeIndex?: number,
    fromRecommended?: boolean
  ) {
    // reset service dependencies
    this.serviceDependencies = undefined;
    // console.info('addService', service, attendeeIndex);
    //console.log(service,attendeeIndex);
    let attendee: Attendee | undefined; //= this.getPick().attendees[attendeeIndex - 1];

    const pick = this.getPick();

    this.loyaltyServices.subscribe((services) => {
      service.loyaltyCycle = services.get(service.id + '') ? true : false;
    } );

    // set correct attendee index
    if (attendeeIndex) {
      this.setSelectedPerson(attendeeIndex - 1);
    }

    const attendeeSize = this.getAttendeeSize();
    this.setSelectedPerson(
      Math.max(0, Math.min(this.selectedPerson, attendeeSize - 1))
    );

    // pick the correct attendee
    if (pick.attendees.length < 1) {
      attendee = this.authService.isLoggedIn()
        ? new Attendee({
            name:
              this.authService.me?.firstName +
              ' ' +
              this.authService.me?.lastName,
          })
        : new Attendee({ name: 'Oseba 1' });
      pick.addAttendee(attendee);
      attendee = pick.attendees[0];
    } else {
      attendee = pick.attendees[this.selectedPerson];
    }

    // if cart has less than on more than 1 item in cart, remove discount form current service
    if (this.getTotalItemsInCart() > 1 && !service.loyaltyCycle) {
      // if (this.getTotalItemsInCart() > 1 ) {
      service.discount = 0;
    }

    // console.info('addService selectedPerson', this.selectedPerson);

    if (this.getAttendeeItemsInCart(this.selectedPerson) < 4) {
      attendee.toggleService(service, service, true);
    } else {
      // TODO maybe show a notification?
    }

    // dependencies and recommendations
    if (
      service.dependencies &&
      service.dependencies.fullfilledBy &&
      service.dependencies.fullfilledBy.length > 0
    ) {
      // get all dependency services from api

      this.getDependencies(service);

    } else if (
      service.parentDependencies &&
      service.parentDependencies.fullfilledBy &&
      service.parentDependencies.fullfilledBy.length > 0
    ) {
      this.getParentDependencies(service);
    } else {
      // show recommendations if this is the first item in cart
      if (
        this.getTotalItemsInCart() == 1 &&
        !sessionStorage.getItem('cartSuggestionsShown')
      ) {
        this.serviceDetailService
          .getServiceBySlug$(service.slug)
          .subscribe((service: Service) => {
            if (service.recommendations && service.recommendations.length > 0) {
              console.log('addService', service);
              this.getFirstItemRecommendations(service);

              document
                .querySelectorAll('.cartSuggestions')
                .forEach((el: Element) => {
                  el.classList.add('active');
                });

              // TODO in production? save boolean that we already showed cart suggestions
              //sessionStorage.setItem('cartSuggestionsShown', 'true');

              //this.modalService.presentModal(RecommendedModalComponent, 'recommended-modal', {service: service});
              // setTimeout(() => {
              //   document.querySelectorAll('.cartSuggestions').forEach((el:Element) => {
              //     el.classList.remove('active');
              //   });
              // }, 10000);
            }
          });
      } else {
        if (!fromRecommended) {
          this.hideRecommended();
        }
      }
    }

    this.saveCartToSession(this.cart);
  }

  async getDependencies(service) {
    const dependencyServices = await this.fetchServices(
      service.dependencies.fullfilledBy
    );
    this.serviceDependencies = [];
    if (dependencyServices && dependencyServices.length > 0) {
      // open modal with dependency services
      dependencyServices.forEach((service: ProviderServiceModel) => {
        if (!this.serviceDependencies) {
          this.serviceDependencies = [];
        }
        // if(!service.discount || service.discount == 0) {
        //   service.discount = this.provider.recommendationsDiscount;
        // }

        this.serviceDependencies.push(new Service(service));
      });
      // console.log(this.serviceDependencies);

      document.querySelectorAll('.cartSuggestions').forEach((el: Element) => {
        el.classList.add('active');
      });
    }
  }

  //@todo REFACTOR!!!
  async getParentDependencies(service) {
    const dependencyServices = await this.fetchServices(
      service.parentDependencies.fullfilledBy
    );
    this.serviceDependencies = [];
    if (dependencyServices && dependencyServices.length > 0) {
      // open modal with dependency services
      dependencyServices.forEach((service: ProviderServiceModel) => {
        if (!this.serviceDependencies) {
          this.serviceDependencies = [];
        }
        this.serviceDependencies.push(new Service(service));
      });
      // console.log(this.serviceDependencies);

      document.querySelectorAll('.cartSuggestions').forEach((el: Element) => {
        el.classList.add('active');
      });
    }
  }

  async fetchServices(serviceIds): Promise<ProviderServiceModel[]> {
    const dependencyServices: ProviderServiceModel[] = [];
    const promises = serviceIds.map((serviceId) =>
      fetch(`https://api.pricepilot.io/providers/107/services/${serviceId}`)
    );
    const responses = await Promise.all(promises);

    for (const response of responses) {
      const data = await response.json();
      dependencyServices.push(new ProviderServiceModel(data));
    }

    return dependencyServices;
  }

  removeService(service: any, attendeeIndex: number, event?: Event) {
    //console.log(service,attendeeIndex);
    const attendee: Attendee = this.getPick().attendees[attendeeIndex];
    const pick = this.getPick();

    // if event target exists, try to find the closest parent with class 'cart-item'
    // apply animation to that element
    let shouldUpdateAttendeeNames = false;
    if (event && event.target) {
      const element = event.target as HTMLElement;
      const cartItem = element?.closest('.cart_item');
      if (cartItem) {
        cartItem.classList.add('remove-from-cart');
        setTimeout(() => {
          cartItem.classList.remove('remove-from-cart');

          attendee.toggleService(service, service);
          if (attendee.services.length == 0) {
            pick.removeAttendee(attendee);
            shouldUpdateAttendeeNames = true;
          }
        }, 300);
      }
    } else {
      attendee.toggleService(service, service);
      if (attendee.services.length == 0) {
        pick.removeAttendee(attendee);
        shouldUpdateAttendeeNames = true;
      }
    }

    if (shouldUpdateAttendeeNames) {
      this.updateAttendeeNames();
    }

    this.selectedPerson = this.getPick().attendees.length - 1;

    //console.info('removeService selectedPerson', this.selectedPerson);
    this.firstItemRecommendations = [];
    this.serviceDependencies = [];
    this.hideRecommended();

    setTimeout(() => {
      this.saveCartToSession(this.cart);
    }, 350);
  }

  updateAttendeeNames() {
    for (let i = 0; i < this.getPick().attendees.length; i++) {
      if (i == 0) {
        // first attendee if logged in, use name from profile
        if (this.authService.isLoggedIn()) {
          this.getPick().attendees[i].name = this.authService.isLoggedIn()
            ? this.authService.me?.firstName +
              ' ' +
              this.authService.me?.lastName
            : 'Oseba 1';
        } else {
          this.getPick().attendees[i].name = 'Oseba ' + (i + 1);
        }
      } else {
        this.getPick().attendees[i].name = 'Oseba ' + (i + 1);
      }
    }
  }

  getMaxDuration() {
    let maxDuration = 0;
    const pick = this.getPick();
    for (const attendee of pick.attendees) {
      const attendee_maxDuration = attendee.services.reduce(
        (maxDuration, service) =>
          service.duration > maxDuration ? service.duration : maxDuration,
        0
      );
      maxDuration =
        attendee_maxDuration > maxDuration ? attendee_maxDuration : maxDuration;
    }
    return maxDuration;
  }

  getTotal() {
    //console.log('NewCartService getTotal...');

    let total = 0.0;
    const pick = this.getPick();
    for (const attendee of pick.attendees) {
      // attendee.services.forEach((service:Service) => {
      //   console.log('service', service.minimalPrice);
      // });

      const attendee_total = attendee.services.reduce(
        (total, service) =>
          service.minimalPrice?.lowestPrice
            ? total +
              (service.minimalPrice?.lowestPrice * (100 - service.discount)) /
                100
            : total,
        0
      );
      total += attendee_total;
    }
    return total;
  }

  getSavings() {
    let total,
      lower = 0.0;
    let savings = 0.0;
    const pick = this.getPick();
    for (const attendee of pick.attendees) {
      total = attendee.services.reduce(
        (total, service) => total + service.regularPrice1,
        0
      );
      lower = attendee.services.reduce(
        (lower, service) =>
          service.minimalPrice?.lowestPrice
            ? lower +
              (service.minimalPrice?.lowestPrice * (100 - service.discount)) /
                100
            : lower,
        0
      );
      const attendee_savings = total > 0 ? total - lower : 0;
      savings += attendee_savings;
    }
    if (savings < 0) {
      return false;
    } else {
      return savings;
    }
  }

  getTotalItemsInCart() {
    return this.cart.picks.reduce((total, pick) => {
      return (
        total +
        pick.attendees.reduce((attendeeTotal, attendee) => {
          return attendeeTotal + attendee.services.length;
        }, 0)
      );
    }, 0);
  }

  areThereDepIssues() {
    return this.getPick().areThereDepIssues();
  }

  checkForUnjustDiscount() {
    // TODO check why we have this
    this.getPick().attendees.forEach((attendee: Attendee) => {
      // if (attendee.services.length == 1) {
      if (
        attendee.services[0].loyaltyCycle &&
        attendee.services[0].loyaltyCycleDiscountPercentage ===
          attendee.services[0].discount
      ) {
        // No need to change the discount
      } else {
        attendee.services[0].discount = 0;
      }
      // }
    });
  }

  getAttendeeItemsInCart(attendeeIndex: number) {
    return this.getPick().attendees[attendeeIndex]?.services?.length || 0;
  }

  setSelectedPerson(index: number) {
    this.selectedPerson = index;
  }

  getFirstItemRecommendations(service: Service) {
    //const pick = this.getPick();
    //const recommendations = (pick.attendees && pick.attendees[0] && pick.attendees[0].services) ? pick.attendees[0].services[0].recommendations : [];
    const recommendations = service.recommendations;
    // console.log('getFirstItemRecommendations', recommendations);
    recommendations.forEach((s: Service) => {
      if (!s.discount || s.discount == 0) {
        s.discount = this.provider.recommendationsDiscount;
      }
    });

    const random_recommendations = recommendations
      .sort(() => 0.5 - Math.random())
      .slice(0, 2);
    this.firstItemRecommendations = random_recommendations;
  }

  hideRecommended() {
    document.querySelectorAll('.cartSuggestions').forEach((el: Element) => {
      el.classList.remove('active');
      el.querySelectorAll('.cartSuggestionsItem').forEach((child: Element) => {
        child.remove();
      });
    });
  }
}
