import { Attendee } from "./attendee.model"
import { Combination } from "./combination.model"
//import { Product } from "./product.model"
import { environment } from '../../../../environments/environment';
import { Service } from "./service.model";
import { User } from "../user.model";

export class Pick {

  public shopId: number;
  public customerNotes: string;
  //public menuStrelec: number;
  public attendees: Attendee[];
  //public products: Product[] = [];
  //public partySize: number = 2;
  public combination: Combination | null;
  public depsIssue: boolean = false;
  public total = 0.00;
  public payed = false;
  public allowReplacement = true;

  constructor(
    obj?: any
  ) {
    this.shopId = obj && obj.shopId || null;
    this.customerNotes = obj && obj.customerNotes || null;
    //this.menuStrelec = obj && obj.menuStrelec || null;
    //this.partySize = obj && obj.partySize || 2;
    this.attendees = obj && obj.attendees || [];
    this.combination = obj && obj.combination || null;
  }

  setCustomerNotes( cn: string ) {
    this.customerNotes = cn;
  }

  areServicesPicked() {

    const a = this.getAttendeeByIndex( 0 );
    if ( !a.services || a.services.length === 0 ) {
      return false;
    }
    return true;
  }

  areThereDepIssues() {
    return this.attendees.some(a => {
      a.checkIfDepsAreOk();
      //console.log('dependen', a,a.areServicesPicked(),a.depsIssue);
      if (a.areServicesPicked()) {
        if (a.depsIssue) {
          // console.log("attendee has deps issue");
          return true;
        } else {
          return false;
        }
      }
      return true;
    });

  }

  isCombinationPicked() {
    return !!this.combination;
  }

  getCompletedStepIndex() {

    let completedStepIndex: number = -1;

    if ( !this.attendees || !this.attendees[0] || !this.attendees[0].services ) {
      return -1;
    }

    if ( this.attendees[0].services.length > 0 ) {
      completedStepIndex = 0;
    }

    if ( this.combination && 0 === completedStepIndex ) {
      completedStepIndex = 1;
    }

    return completedStepIndex;

  }

  setCombination( combination: Combination ) {
    this.combination = combination;
    let total = 0;

    combination?.attendees?.forEach( (attendee,index) => {
      attendee?.services?.forEach( service => {
        const attendeesService = this.attendees[index].findService(service.id);
        if (attendeesService.discount>0) {
          service.discount = attendeesService.discount;
        }
        total = total + (service?.price?.amount || 0) * (100 - service.discount)/100;
      });
    });
    if (combination && combination.price && combination.price.amount && total !== combination.price.amount) {
      combination.price.amount = total;
    }
    //console.log("combination",combination);

  }

  unsetCombination() {
    this.combination = null;
  }

  // emptyProducts() {
  //   this.products = [];
  // }

  addAttendee( attendee: Attendee ) {
    this.attendees.push( attendee );
  }

  removeAttendee( attendee: Attendee ) {

    const index = this.attendees.indexOf( attendee );

    if ( index > -1 ) {
      this.attendees.splice(index, 1);
    }
  }

  getAttendeeByIndex( index:number ): Attendee {

    if ( this.attendees[index] ) {
      return this.attendees[index];
    } else {
      this.attendees[index] = new Attendee();
      return this.attendees[index];
    }
  }

  getDiscountedCartPrice( ) {
    let total = 0;
    this.combination?.attendees?.forEach( (attendee,index) => {
      attendee?.services?.forEach( service => {
        total = total + (service?.price?.amount || 0) * (100 - service.discount)/100;
      });
    });
    return total;
  }

  private getProviderHrefFromServiceHref( serviceHref: string ) {
    return serviceHref.substring( 0, serviceHref.indexOf('/services') );
  }

  private getProviderHrefFromSelectionItemHref( selectionItemHref: string ) {
    return selectionItemHref.substring( 0, selectionItemHref.indexOf('/services') );
  }


  // //PRODUCTS
  // areProductsPickedAndCombinationSet() {

  //   if ( this.isCombinationPicked() && this.areProductsPicked() && this.getMenusPickedCount() === this.partySize ) {



  //     return true;
  //   }

  //   return false;

  // }

  // areTooManyProductsPicked() {

  //   if ( this.areProductsPicked() && this.getMenusPickedCount() > this.partySize ) {
  //     return true;
  //   }

  //   return false;

  // }


  // getProductQtyById( productId ) {

  //   for( let i=0; i < this.products.length; i++ ) {

  //     if ( this.products[i].id === productId ) {
  //       return this.products[i].quantity;
  //     }

  //   }

  //   return 0;

  // }

  // getProductCategoriesPicked(): Array<number> {
  //   const ids = this.products.map( (value, index, array) => {
  //     return value.parentId;
  //   });

  //   const uniqueIds = [...new Set(ids)];
  //   return uniqueIds;
  // }

  setDepsIssue( hasIssue: boolean ) {
    this.depsIssue = hasIssue;
  }

  areProductsPicked() {
    return false;
    //return ( this.products.length > 0 );
  }


  // getMenusPickedCount() {

  //   let count = 0;

  //   for( let i=0; i < this.products.length; i++ ) {
  //     count += this.products[i].quantity;
  //   }

  //   return count;

  // }

  // pickMaxProductsFilter( parentId, pickMax ) {

  //   if ( !pickMax || pickMax < 1 ) {
  //     return;
  //   }

  //   let categoryIndexArray: Array<number> = [];

  //   for( let i=0; i < this.products.length; i++ ) {

  //     if ( parentId === this.products[i].parentId ) {
  //       categoryIndexArray.push( i );
  //     }

  //     const diff = categoryIndexArray.length - pickMax

  //     if ( diff > 0 ) {
  //       this.products.splice( categoryIndexArray[0], diff )
  //     }

  //   }

  // }

  // calculateTotal() {

  //   let total = 0.00;

  //   for( let i=0; i < this.products.length; i++ ) {

  //     total += ( this.products[i].price.amount * this.products[i].quantity );

  //   }

  //   this.total = total;
  //   return this.total;

  // }

  // toggleProduct( serviceData, categoryData, qty ) {


  //   for( let i=0; i < this.products.length; i++ ) {
  //     if ( this.products[i].id == serviceData.id ) {
  //       this.products.splice(i, 1);
  //     }
  //   }

  //   if ( qty === 0 ) {
  //     this.calculateTotal();
  //     return true;
  //   }

  //   //@todo for now this is ok, but in the future we need to introduce dynamic pricing and discounts, by using regular prices and discounts for picked combination
  //   let getPriceFromCombinationAndProduct = function( combination, serviceData ) {
  //     return serviceData.minimalPrice.lowestPrice;
  //   }

  //   const product = new Product({
  //     'id': serviceData.id,
  //     'quantity': qty,
  //     'url': serviceData._links.self.href,
  //     'name': serviceData.name,
  //     'duration': serviceData.duration,
  //     'price': getPriceFromCombinationAndProduct( this.combination, serviceData), //fixed prices only!!!, update ASAP
  //     'parentId': categoryData.id
  //   });

  //   if ( categoryData.dependencies && categoryData.dependencies.fullfilledBy && categoryData.dependencies.fullfilledBy[0] ) {
  //     product.parentDependencies = categoryData.dependencies;
  //   }

  //   if ( serviceData.dependencies && serviceData.dependencies.fullfilledBy && serviceData.dependencies.fullfilledBy[0] ) {
  //     product.dependencies = serviceData.dependencies;
  //   }

  //   this.products.push(product);

  //   this.pickMaxProductsFilter( categoryData.id, categoryData.childrenPickMax ? categoryData.childrenPickMax: 0 );

  //   this.calculateTotal();

  //   return product;

  // }

  get bookingPostObject() {
    const provider = (this.combination && this.combination.attendees && this.combination.attendees[0].services && this.combination.attendees[0].services[0].serviceHref) ? this.combination.attendees[0].services[0].serviceHref : "";

    const bookingObj = {
      "start": this.combination?.start,
      'allowReplacement': this.allowReplacement,
      'customerNotes': this.customerNotes,
      'site': environment.site,
      "provider": this.getProviderHrefFromServiceHref( provider ),
    };

    bookingObj['bookingAttendeesData'] = [];

    for( const attendee of this.combination?.attendees ? this.combination.attendees: [] ) {

      const bookingServices: { bookingService: { service: string; price: number; currency: string; start: string; end: string; bookingServiceSelectionItemsData: { selectionItem: string; }[]; }; }[] = [];

      for( const service of attendee?.services? attendee?.services : [] ) {


        // console.warn("service",service);

        const bookingService = {
          'service': service.serviceHref,
          'price': ( service.price && service.price.amount ) ? (service.price.amount * (100 - service.discount) / 100 ) : 0.00,
          'currency': ( service.price && service.price.currency ) ? service.price.currency: 'EUR',
          'start': service.start,
          'end': service.end,
          'discountPercentage': service.discount,
          'bookingServiceSelectionItemsData': [
             {
                "selectionItem": service.locationHref
             },
             {
                "selectionItem": service.employeeHref
             }
          ]
        }
        // console.log("bookingService",bookingService);

        bookingServices.push( {'bookingService' : bookingService } );
        // console.info(bookingServices);

      }
      // console.info(bookingServices);

      bookingObj['bookingAttendeesData'].push({
        'name': (attendee.name && attendee.name?.length>0) ? attendee.name : 'Brez imena',
        'bookingAttendeeBookingServicesData': bookingServices
      });
      // console.log("bookingObj",bookingObj);

    }
    // console.log("bookingObj",bookingObj);

    return bookingObj;

  }

  static revive(json: any): Pick {
    const attendees = json.attendees.map((attendee: any) => Attendee.revive(attendee));
    const combination = json.combination ? Combination.revive(json.combination) : null;
    const pick = new Pick({...json, attendees, combination});

    return pick;
  }

}
