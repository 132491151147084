import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

// GalleryLightboxService is a service that is used to control the state of the gallery lightbox.
// It is used in the gallery-lightbox.component.ts file.
// it has a boolean property that is used to control the state of the lightbox.
// it has a constructor that is used to inject the service into the component.
// it has a method that is used to close the lightbox.
// it has a method that is used to open the lightbox.
// it accepts two parameters lightboxUrl and lightboxAlt.
export class GalleryLightboxService {
  private lightboxSubject = new BehaviorSubject<boolean>(false);
  private lightboxSrcSubject = new BehaviorSubject<string>('');
  private lightboxAltSubject = new BehaviorSubject<string>('');
  private gallerySubject = new BehaviorSubject<any[]>([]);
  currentService: any;
  currentIndex: number;

  private lightboxCategoryHrefSubject = new BehaviorSubject<string>('');
  private lightboxCategorySubject = new BehaviorSubject<string>('');
  private lightboxFieldHrefSubject = new BehaviorSubject<string>('');
  private lightboxFieldSubject = new BehaviorSubject<string>('');


  private lightboxHrefSubject = new BehaviorSubject<string>('');
  activeServiceMap: Map<number, any>;
  currentPath: string;
  urlSubscription: Subscription;
  currentFieldSlug: string | null;
  currentCategorySlug: string | null;
  serviceArray: any[];

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit() {
  }

  humanize(value: string): string {
    if ((typeof value) !== 'string') {
      return value;
    }

    value = value.replace(/[-_0-9]+/g, ' ');
    //value = value.split(/(?=[A-Z])/).join(' ');
    value = value.toLocaleLowerCase();
    value = value[0].toUpperCase() + value.slice(1);
    return value;

  }

  isLightboxOpen(): Observable<boolean> {
    return this.lightboxSubject.asObservable();
  }

  openLightbox(src: string, alt: string): void {
    this.lightboxSubject.next(true);
    this.lightboxSrcSubject.next(src);
    this.lightboxAltSubject.next(alt);
  }

  setActiveServiceMap(serviceMap: Map<number, any>): void {
    this.activeServiceMap = serviceMap;
  }

  setCurrentPath(urlSegments: UrlSegment[]): void {
    urlSegments.forEach((segment, index) => {
      if (index===0) {
        this.currentFieldSlug = segment.path;
      } else if (index===1) {
        this.currentCategorySlug = segment.path;
      }
    });
  }

  // setCurrentLightboxData(service: any, i: number): void {

  //   if (this.activeServiceMap && this.activeServiceMap.has(service.id)) {
  //     const foundService = this.activeServiceMap.get(service.id);
  //     const field = this.humanize(foundService.fieldName);
  //     this.lightboxFieldSubject.next(field);
  //     const fieldHref = '/' + foundService.fieldSlug;
  //     this.lightboxFieldHrefSubject.next(fieldHref);

  //     const category = foundService.categoryName;
  //     this.lightboxCategorySubject.next(category);
  //     const categoryHref = '/' + foundService.fieldSlug + '/' + foundService.categorySlug;
  //     this.lightboxCategoryHrefSubject.next(categoryHref);

  //     const alt = foundService.name + ' (' + (i+1) + '/' + service.gallery.length + ')';
  //     this.lightboxAltSubject.next(alt);
  //     const href = '/' + foundService.fieldSlug + '/' + foundService.categorySlug + '/' + foundService.slug;
  //     this.lightboxHrefSubject.next(href);
  //   } else if (this.currentFieldSlug && this.currentCategorySlug) {
  //     const field = this.humanize(this.currentFieldSlug);
  //     this.lightboxFieldSubject.next(field);
  //     const fieldHref = '/' + this.currentFieldSlug;
  //     this.lightboxFieldHrefSubject.next(fieldHref);

  //     const category = this.humanize(this.currentCategorySlug);
  //     this.lightboxCategorySubject.next(category);
  //     const categoryHref = '/' + this.currentFieldSlug + '/' + this.currentCategorySlug;
  //     this.lightboxCategoryHrefSubject.next(categoryHref);

  //     const alt = service.name + ' (' + (i+1) + '/' + service.gallery.length + ')';
  //     this.lightboxAltSubject.next(alt);
  //     const href = '/' + this.currentFieldSlug + '/' + this.currentCategorySlug + '/' + service.slug;
  //     this.lightboxHrefSubject.next(href);
  //   } else {
  //     // TODO this part should be removed, but it needs proper alternative
  //     const field = ((service.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE');
  //     this.lightboxFieldSubject.next(field);
  //     const fieldHref = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve' : '/kozmeticne-storitve');
  //     this.lightboxFieldHrefSubject.next(fieldHref);

  //     const category = service.parentName;
  //     this.lightboxCategorySubject.next(category);
  //     const categoryHref = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + service.parentName.toLowerCase().replace(/ /g,'-') ;
  //     this.lightboxCategoryHrefSubject.next(categoryHref);

  //     const alt = service.name + ' (' + (i+1) + '/' + service.gallery.length + ')';
  //     this.lightboxAltSubject.next(alt);
  //     const href = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + service.parentName.toLowerCase().replace(/ /g,'-') + '/' + service.slug;
  //     this.lightboxHrefSubject.next(href);
  //   }
  // }

  openLightbox2(service: any, i: number, serviceMap?: Map<number, any>, urlSegments?: UrlSegment[]): void {
    //console.log(this.gallerySubject.value);
    // console.log('openLightbox2',service,i);
    !!serviceMap && this.setActiveServiceMap(serviceMap);
    !!urlSegments && this.setCurrentPath(urlSegments);

    this.lightboxSubject.next(true);
    this.gallerySubject.value.filter((item, index) => {
      if (item.service===service) {
        this.currentService = service;
        this.currentIndex = i;

        const src = 'https://docs.api.pricepilot.io/' + item.service.gallery[i].document.fileName;
        this.lightboxSrcSubject.next(src);

        // this.setCurrentLightboxData(item.service, i);

        // console.log(item.service);
      }
    });

  }

  openLightbox3(index: number): void {
    // console.log('openLightbox3',this.gallerySubject,index);
    this.serviceArray = this.gallerySubject.value;
    this.lightboxSubject.next(true);
    this.currentIndex = index;
    this.currentService = this.serviceArray[index].service;
    // console.log(this.currentService,this.currentIndex);
    const src = 'https://docs.api.pricepilot.io/' + this.currentService.gallery[0].document.fileName;
    this.lightboxSrcSubject.next(src);

    this.setCurrentLightboxData(this.currentService);

  }

  setCurrentLightboxData(service: any): void {
    // console.log(service);
    // const field = this.humanize(service.fieldName);
    // this.lightboxFieldSubject.next(field);
    // const fieldHref = '/' + service.fieldSlug;
    // this.lightboxFieldHrefSubject.next(fieldHref);

    // const category = service.categoryName;
    // this.lightboxCategorySubject.next(category);
    // const categoryHref = '/' + service.fieldSlug + '/' + service.categorySlug;
    // this.lightboxCategoryHrefSubject.next(categoryHref);

    // // const alt = service.name + ' (' + (i+1) + '/' + service.gallery.length + ')';
    // const alt = service.name;
    // this.lightboxAltSubject.next(alt);
    // const href = '/' + service.fieldSlug + '/' + service.categorySlug + '/' + service.slug;
    // this.lightboxHrefSubject.next(href);
    if (service.fieldSlug && service.categorySlug) {
      // const field = this.humanize(service.fieldSlug);
      const field = service.field;//--this.humanize(service.fieldSlug);
      this.lightboxFieldSubject.next(field);
      const fieldHref = '/' + service.fieldSlug;
      this.lightboxFieldHrefSubject.next(fieldHref);

      //const category = this.humanize(service.categorySlug);
      const category = (service.category) ? service.category : this.humanize(service.categorySlug);
      this.lightboxCategorySubject.next(category);
      const categoryHref = '/' + service.fieldSlug + '/' + service.categorySlug;
      this.lightboxCategoryHrefSubject.next(categoryHref);

      const alt = service.name ;
      this.lightboxAltSubject.next(alt);
      const href = '/' + service.fieldSlug + '/' + service.categorySlug + '/' + service.slug;
      this.lightboxHrefSubject.next(href);
    } else {
      // console.log(service);

      const field = ((service.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE');
      this.lightboxFieldSubject.next(field);
      const fieldHref = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve' : '/kozmeticne-storitve');
      this.lightboxFieldHrefSubject.next(fieldHref);

      const category = service.parentName;
      this.lightboxCategorySubject.next(category);
      const categoryHref = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + service.parentName.toLowerCase().replace(/ /g,'-') ;
      this.lightboxCategoryHrefSubject.next(categoryHref);

      const alt = service.name ;
      this.lightboxAltSubject.next(alt);
      const href = ((service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + service.parentName.toLowerCase().replace(/ /g,'-') + '/' + service.slug;
      this.lightboxHrefSubject.next(href);
    }

    // this.setCurrentLightboxData2(this.currentService, this.currentIndex-1);
    const src = 'https://docs.api.pricepilot.io/' + service.gallery[0].document.fileName;
    this.lightboxSrcSubject.next(src);

  }

  nextImage(): void {
    const gal = this.gallerySubject.value;
    if (this.currentIndex < gal.length) {
      // console.log(gal[this.currentIndex+1]);
      const nextService = gal[this.currentIndex+1].service;
      this.setCurrentLightboxData(nextService);
      this.currentService = nextService;
      this.currentIndex++;
    }
  }

  prevImage(): void {
    const gal = this.gallerySubject.value;
    if (this.currentIndex > 0) {
      // console.log(gal[this.currentIndex-1]);
      const prevService = gal[this.currentIndex-1].service;
      this.setCurrentLightboxData(prevService);
      this.currentService = prevService;
      this.currentIndex--;
    }
  }

  // nextImage(): void {
  //   //console.log('nextImage');
  //   //console.log(this.currentService);
  //   const gal = this.gallerySubject.value;
  //   gal.some((item, index) => {
  //     console.log(item, index, item.service.id,this.currentService.id);
  //     if (item.service.id===this.currentService.id) {
  //       console.log('nextImage',index,item.service.id, this.currentService.id,this.currentIndex, item.service.gallery.length);
  //       if (this.currentIndex < item.service.gallery.length - 1) { // for galleries with more than one image

  //         this.setCurrentLightboxData(item.service, this.currentIndex+1);
  //         const src = 'https://docs.api.pricepilot.io/' + item.service.gallery[this.currentIndex+1].document.fileName;
  //         this.lightboxSrcSubject.next(src);
  //         // const alt = ((item.service.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE') + ' / ' + item.service.parentName + ' / ' + item.service.name + ' (' + (this.currentIndex+2) + '/' + item.service.gallery.length + ')';
  //         // this.lightboxAltSubject.next(alt);
  //         // const href = ((item.service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + item.service.parentName.toLowerCase().replace(/ /g,'-') + '/' + item.service.slug;
  //         // this.lightboxHrefSubject.next(href);

  //         this.currentIndex++;
  //       }
  //       else if (index < gal.length - 1) { // for galleries with only one image
  //         console.log(gal, index, gal[index+1]);
  //         const nextService = gal[index+1].service;

  //         this.setCurrentLightboxData(nextService, 0);
  //         // //console.log(nextService);
  //         const src = 'https://docs.api.pricepilot.io/' + nextService.gallery[0].document.fileName;
  //         this.lightboxSrcSubject.next(src);
  //         // const alt = (nextService.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE' + ' / ' + nextService.parentName + ' / ' + nextService.name + ' (' + (0+1) + '/' + nextService.gallery.length + ')';
  //         // this.lightboxAltSubject.next(alt);
  //         // const href = (nextService.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/' + nextService.parentName.toLowerCase().replace(/ /g,'-') + '/' + nextService.slug;
  //         // this.lightboxHrefSubject.next(href);


  //         this.currentService = nextService;
  //         this.currentIndex = 0;
  //       }
  //       return true;
  //     }
  //     return false;
  //   });
  // }

  // prevImage(): void {
  //   const gal = this.gallerySubject.value;
  //   gal.some((item, index) => {
  //     if (item.service.id===this.currentService.id) {
  //       if (this.currentIndex > 0) {

  //         this.setCurrentLightboxData(item.service, this.currentIndex-1);

  //         const src = 'https://docs.api.pricepilot.io/' + item.service.gallery[this.currentIndex-1].document.fileName;
  //         this.lightboxSrcSubject.next(src);
  //         // const alt = ((item.service.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE') + ' / ' + item.service.parentName + ' / ' + item.service.name + ' (' + (this.currentIndex) + '/' + item.service.gallery.length + ')';
  //         // this.lightboxAltSubject.next(alt);

  //         // const href = ((item.service.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/') + item.service.parentName.toLowerCase().replace(/ /g,'-') + '/' + item.service.slug;
  //         // this.lightboxHrefSubject.next(href);

  //         this.currentIndex--;
  //       }
  //       else if (index > 0) {
  //         const prevService = gal[index-1].service;

  //         this.setCurrentLightboxData(prevService, prevService.gallery.length-1);

  //         const src = 'https://docs.api.pricepilot.io/' + prevService.gallery[0].document.fileName;
  //         this.lightboxSrcSubject.next(src);
  //         // const alt = (prevService.taxonomy?.substr(0,5)==='10_10') ? 'FRIZERSKE STORITVE' : 'KOZMETIČNE STORITVE' + ' / ' + prevService.parentName + ' / ' + prevService.name + ' (' + (0+1) + '/' + prevService.gallery.length + ')';
  //         // this.lightboxAltSubject.next(alt);
  //         // const href = (prevService.taxonomy?.substr(0,5)==='10_10') ? '/frizerske-storitve/' : '/kozmeticne-storitve/' + prevService.parentName.toLowerCase().replace(/ /g,'-') + '/' + prevService.slug;
  //         // this.lightboxHrefSubject.next(href);


  //         this.currentService = prevService;
  //         this.currentIndex = 0;
  //       }
  //       return true;
  //     }
  //     return false;
  //   });
  // }


  closeLightbox(): void {
    this.lightboxSubject.next(false);
    this.lightboxSrcSubject.next('');
    this.lightboxAltSubject.next('');
    this.lightboxHrefSubject.next('');
  }


  setGallery(gallery: any[]): void {
    // console.log('setGallery',gallery);
    this.gallerySubject.next(gallery);
  }
  setGalleryFromService(service: any): void {
    const tmpServices: [any?] = [];

      service.gallery.forEach(image => {
        if (image.document.type === 'gallery') {
          const newService = {...service};
          newService.gallery = [image];

          tmpServices.push({
            'fieldId': service.fieldId,
            'categoryId': service.categoryId,
            'cornerId': service.cornerId,
            'service':newService
          });
          // console.log("tmpservice.length",service.service.id,newService, tmpServices.length);
        }
      });

    // console.log("tmpservice.length",service,tmpServices);

    this.gallerySubject.next(tmpServices);
    // return tmpServices;

  }

  getLightboxSrc(): Observable<string> {
    return this.lightboxSrcSubject.asObservable();
  }

  getLightboxAlt(): Observable<string> {
    return this.lightboxAltSubject.asObservable();
  }

  getLightboxHref(): Observable<string> {
    return this.lightboxHrefSubject.asObservable();
  }

  getLightboxCategoryHref(): Observable<string> {
    return this.lightboxCategoryHrefSubject.asObservable();
  }

  getLightboxCategory(): Observable<string> {
    return this.lightboxCategorySubject.asObservable();
  }

  getLightboxField(): Observable<string> {
    return this.lightboxFieldSubject.asObservable();
  }

  getLightboxFieldHref(): Observable<string> {
    return this.lightboxFieldHrefSubject.asObservable();
  }
}
