import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PPAuthService } from '../../../core/services/auth.service';
import { User } from '../../../core/models/user.model';
import { Observable } from 'rxjs';
import { map, tap, filter, first} from 'rxjs/operators';
import { parse, format, AsYouType } from 'libphonenumber-js';
import { ToastController } from '@ionic/angular';
import { PhoneUtilsService } from 'angular-phone-utils-lib';
import { ModalController } from '@ionic/angular';
import { IonButton } from '@ionic/angular';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
})
export class PhoneFormComponent implements OnInit {

  @Input() bookingDialog: boolean = false;
  me$: Observable<User>;

  phoneSubmitDisabled = true;

  phone: string;
  code: string = '';

  toastRef: any;

  originalPhone: string;
  
  constructor( private authService: PPAuthService,  public toastController: ToastController, private phoneUtils: PhoneUtilsService, private modalCtrl: ModalController ) { 
    
    this.me$ = authService.me2$.pipe(
      filter( user => !!user ),
      
      // Filter out null values
      filter((user): user is User => user !== null),

      tap( user => this.phone = user.phone  ),
      tap( user => this.originalPhone = this.phone )
    );

  }

  onCancel() {
    this.modalCtrl.dismiss( null, 'cancel' );
  }

  ngOnInit() {
  }

  async presentToast( text ) {
    this.toastRef = await this.toastController.create({
      message: text,
      duration: 3000,
      color: 'primary'
    });
    this.toastRef.present();
  }

  onPhoneFormSubmit( submitBtn: IonButton ) {

    this.authService.updatePhoneNumber( this.phone ).subscribe( 
      result => {
        this.authService.setMe();
        if ( this.phone ) {
          this.authService.sendConfirmationCode().pipe(
            first()
          ).subscribe( result => this.presentToast( 'Poslali smo SMS s številko. Prepišite jo.' ) );
        }
      }
    )

  }

  onCodeFormSubmit( submitBtn: IonButton ) {

    this.authService.verifyPhoneNumber( this.code ).pipe(
      first(),
      tap( result => this.authService.setMe() )
    ).subscribe( 
      null, 
      error => this.presentToast( 'Vpisana koda je napačna.' )
    )

    this.code = '';

  }

  resendCode() {
    this.authService.sendConfirmationCode().pipe(
          first()
        ).subscribe( result => this.presentToast( 'Ponovno smo poslali SMS s številko. Prepišite jo.' ));
  }

  parser( value ) {

    if ( !value  || !value.substr(1) ) {
      return '';
    }

    var formattedInt = this.phoneUtils.getInternational( value, 'SI');

    var formattedIntArray = formattedInt.split(" ");

    var intPrefix = formattedIntArray.shift();

    return intPrefix + ' ' + formattedIntArray.join('');

  }

  formatter( value ) {

    if (value) {

      var formatted = this.phoneUtils.getNational( value, 'SI');


      if ( formatted && formatted.substr(0,1) !== '0' ) {
        return '0' + formatted;
      }

      return formatted;

    } else {
      
      return '';
    
    }

    /*if ( value ) { 
      return '0' + value.substr(5);
    } else {
      return ''
    }*/
  }

  isPhoneValid() {
    if (this.phone) {
      let parsed = parse( this.phone, 'SI' );
      return !!parsed.phone;  
    } else {
      return true;
    }
  }

}
