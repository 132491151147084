// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // apiUrl: 'https://api.pricepilot.io',
  // docsUrl: 'https://docs.api.pricepilot.io',
  // assetsUrl: 'https://assets.pricepilot.io',
  // authUrl: 'https://auth.pricepilot.io',
  apiUrl: 'https://testing.api.pricepilot.si',
  docsUrl: 'https://testing.docs.pricepilot.si',
  assetsUrl: 'https://testing.assets.pricepilot.si',
  authUrl: 'https://testing.auth.pricepilot.si',

  // providerId: 107,
  providerId: 3,
  providerSlug: 'dm-studio',
  //siteUrl: 'https://beta.dmstudio.pricepilot.si',
  // site: 'https://api.pricepilot.io/sites/123',
  site: 'https://testing.api.pricepilot.si/sites/8',
  siteUrl: 'https://dmstudio.si',
  siteName: 'dm Studio',
  siteTitle: 'dm Studio - Dobrodošli v objemu lepote',
  //domain: 'demo.dmstudio.priceplot.si',
  domain: 'saloni.dm.si',
  //domain: 'localhost:8100',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


