import { Component, Input, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProviderService as ProviderService } from '../../core/services/provider.service';
import { Provider } from 'src/app/core/models/provider.model';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  providers: []
})
export class MobileMenuComponent implements OnInit {
  @Input() currentURL: string | null = null;
  expandedMenu: string = '';
  expandedMenu$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  expandedMenuObs: Observable<string> = this.expandedMenu$.asObservable();
  provider$: Observable<Provider>;
  provider: Provider;
  providerSubscription: Subscription;

  constructor(private menuController: MenuController, private providerService: ProviderService) {
    this.provider$ = this.providerService.getProvider$();
  }
  ngOnInit() {
    this.setExpandedMenu();
    this.providerSubscription = this.provider$.subscribe(provider => {
      this.provider = provider;
    });
  }

  ngOnDestroy() {
    this.providerSubscription.unsubscribe();
  }

  closeMenu(from: string = '') {
    this.menuController.close();
    this.expandedMenu$.next(from);
    //this.setExpandedMenu();

  }


  isMenuItemActive(menuItem: string): boolean {
    if (this.currentURL?.includes(menuItem)) {
      return true;
    }
    return false;
  }

  isExpanded(menuItem: string): boolean {
    this.expandedMenu = this.expandedMenu$.value;
    return this.expandedMenu === menuItem ? true : false;
  }

  toggleMenu(menuItem: string) {
    this.expandedMenu$.next(this.expandedMenu$.value === menuItem ? '' : menuItem);
  }

  setExpandedMenu() {
    if (this.currentURL?.includes('frizerske-storitve')) {this.expandedMenu$.next('frizerske');}
    else if (this.currentURL?.includes('kozmeticne-storitve')) {this.expandedMenu$.next('kozmeticne');}
    else {this.expandedMenu$.next('');}
  }

}