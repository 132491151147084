import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ppImage'
})
export class ImagePipe implements PipeTransform {

    transform(file: string): string {
      if (!file) {
        return 'https://docs.api.pricepilot.io/652dadba274e4.jpeg';
      }
      return 'https://docs.api.pricepilot.io/' + file;
    }

  }