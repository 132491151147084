import { Routes } from '@angular/router';
import { LoggedInGuard } from './core/guards/logged-in.guard';

export const routes: Routes = [
  /*{
    path: 'home',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),
  },*/
  /*{
    path: '',
    redirectTo: ''landing'',
    pathMatch: 'full',
  },*/

  {
    path: '',
    loadComponent: () => import('./landing/landing.page').then( m => m.LandingPage)
  },

  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },

  { path: 'moj-profil', canActivate: [ LoggedInGuard ], loadChildren: () => import('./my-profile/my-profile.module').then(m => m.MyProfilePageModule) },

  {
    path: 'piskotki',
    loadComponent: () => import('./cookies/cookies.page').then( m => m.CookiesPage)
  },
  {
    path: 'varstvo-podatkov',
    loadComponent: () => import('./privacy/privacy.page').then( m => m.PrivacyPage)
  },
  {
    path: 'galerija',
    loadComponent: () => import('./gallery/gallery.page').then( m => m.GalleryPage)
  },
  {
    path: 'saloni',
    loadComponent: () => import('./salons/salons.page').then( m => m.SalonsPage)
  },
  {
    path: 'salon/:salonSlug',
    loadComponent: () => import('./salon/salon.page').then( m => m.SalonPage)
  },
  {
    path: 'aktualno',
    loadComponent: () => import('./magazin/magazin.page').then( m => m.MagazinPage)
  },
  {
    path: 'aktualno/:articleSlug',
    loadComponent: () => import('./article/article.page').then( m => m.ArticlePage)
  },
  {
    path: 'ekipa',
    loadComponent: () => import('./team/team.page').then( m => m.TeamPage)
  },
  {
    path: 'zaposleni/:employeeSlug',
    loadComponent: () => import('./employee/employee.page').then( m => m.EmployeePage)
  },
  {
    path: 'narocanje/izberi-lokacijo',
    loadComponent: () => import('./booking-steps/location-pick/location-pick.page').then( m => m.LocationPickPage)
  },
  {
    path: 'narocanje/izberi-termin',
    loadComponent: () => import('./booking-steps/slot-pick/slot-pick.page').then( m => m.SlotPickPage)
  },
  {
    path: 'narocanje/predogled',
    canActivate: [ LoggedInGuard ],
    loadComponent: () => import('./booking-steps/preview/preview.page').then( m => m.PreviewPage)
  },
  {
    path: 'narocanje/cakalna-vrsta',
    canActivate: [ LoggedInGuard ],
    loadComponent: () => import('./booking-steps/waitlist/waitlist.page').then( m => m.WaitlistPage)
  },
  {
    path: 'narocanje/uspesno/:bookingId',
    loadComponent: () => import('./booking-steps/done/done.page').then( m => m.DonePage)
  },
  {
    path: 'narocanje/cakalna-vrsta-uspesno',
    loadComponent: () => import('./booking-steps/waitlist-done/waitlist-done.page').then( m => m.WaitlistDonePage)
  },
  {
    path: ':fieldSlug',
    loadComponent: () => import('./field/field.page').then( m => m.FieldPage)
  },
  {
    path: ':fieldSlug/:categorySlug',
    loadComponent: () => import('./category/category.page').then( m => m.CategoryPage)
  },
  {
    path: ':fieldSlug/:categorySlug/:serviceSlug',
    loadComponent: () => import('./service/service.page').then( m => m.ServicePage)
  },


];
