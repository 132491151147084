export class List   {

  public totalCount: number;
  public items: any[];
  public latitude: number;
  public longitude: number;
  public radius: number;
  public _embedded: any;

  constructor(
    obj?: any
  ) {
    this.totalCount = obj && obj.totalCount || null;
    this.latitude = obj && obj.latitude || null;
    this.longitude = obj && obj.longitude || null;
    this.radius = obj && obj.radius || null;
    this.items = obj && obj.items || null;
    this._embedded = obj && obj._embedded || null;
  }

}