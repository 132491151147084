import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ParamMap } from '@angular/router';
import { Observable, combineLatest, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, flatMap, tap, switchMap, switchAll, take } from 'rxjs/operators';
import { ShopFull } from '../models/shop-full.model';
import { Real } from '../models/real.model';
import { Open } from '../models/open.model';
import { Employee } from '../models/employee.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopDetailService {

  //pass data to child routes
  //private real:Subject<Real> = new BehaviorSubject<Real>( null );
  private real:Subject<Real> = new ReplaySubject<Real>(1);
  real$ = this.real.asObservable();

  private employees:Subject<Array<Employee>> = new ReplaySubject<Array<Employee>>(1);
  employees$ = this.employees.asObservable();

  constructor( private http: HttpClient ) {
    this.getEmployeesByRealStream();
  }

  addReal( real: Real) {
    this.real.next( real );
    this.getEmployeesByRealStream();
  }

  addEmployees( employees: Array<Employee> ) {
    this.employees.next( employees );
  }

  generateEmployeesMap( employees: Array<Employee> ) {

    const map: Map<string, Employee> = new Map();

    for (const employee of employees ) {
      map.set( employee.id, employee );
    }

    return map;

  }

  getReal( params$: Observable<ParamMap> ) {

    const shopFullApiData$ = params$.pipe(

      //1. get api url stream from params stream
      map( params => {

        if ( params.has('lokacija') ) {
          return environment.apiUrl + '/providers/'+environment.providerId+'/reals?expand=selectionItems,provider,employees&slug=' + params.get('lokacija')
        }

        return null;

      }),

      map ( url => this.http.get( url! ) ),

      switchAll()

    );

    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.real && response._embedded.real[0] ) {
          return new Real( response._embedded.real[0] );
      }

    }

    return shopFullApiData$.pipe(

      map( response => responseToModel( response ) ),
      tap( real => this.addReal(real) )

    )

    //result.subscribe( data => console.log( data ) );

  }


  getOpen( providerId: string, itemId: string ) {

    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.open && response._embedded.open[0] ) {

        return new Open( response._embedded.open[0] );

      } else {

        return null;

      }

    }

    const url = environment.apiUrl + '/providers/' + providerId + '/opens?item=' + itemId;

    return this.http.get( url ).pipe(
       map ( response => responseToModel( response ) )
       //tap( employees => this.addEmployees( employees ) )
    );

  }

  getEmployeesByLocationId( realSelectionItemId: string ) {

    const responseToModel = ( response: any): any =>  {

      if ( response._embedded && response._embedded.employeesatlocation ) {
        return response._embedded.employeesatlocation.map(
          (employeeApiData: Employee) => new Employee( employeeApiData )
        )
      } else {
        return [];
      }

    }

    const url = environment.apiUrl + '/views/employees_at_location/' + realSelectionItemId
        + '?pageSize=100';

    return this.http.get( url ).pipe(
       map ( response => responseToModel( response ) )
       //tap( employees => this.addEmployees( employees ) )
    );

  }

  private getEmployeesByRealStream() {

    this.real$.pipe(
      take(1),
      switchMap( ( real:Real ) => this.getEmployeesByLocationId( real.selectionItems[0].id ) ),
    ).subscribe( result => this.addEmployees( result )  );

  }

}
