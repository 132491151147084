import { environment } from 'src/environments/environment';
import { Image } from './image.model';
import { PriceRange } from './price-range.model';
import { Dependencies } from './cart/dependencies.model';
import * as moment from 'moment';

export class ProviderService {
  public id: string;
  public parentId: string;
  public grandParentId: string;
  public grandGrandParentId: string;

  public url: string;
  public slug: string;
  public name: string;
  public nameTranslations: Object;
  public root: string;
  public taxonomy: string;

  public children: ProviderService[] = [];
  public childrenPickMin: number;
  public duration: number;
  public minimalPrice: PriceRange | undefined = undefined;
  public visibleForCustomers: string;
  public visibleForEditors: string;
  public visibleByDefaultInAdmin: string;
  public shortDescription: string;
  public longDescription: string;
  public descriptionButtonText: string;
  public descriptionButtonTextTranslations: Object;

  public avatar: Image | undefined;
  public gallery: Image[] | undefined;
  public menuOpen: number;
  public event: boolean;
  public exclusiveEvent: boolean;
  public specialOffer: boolean;
  public productPickRequired: boolean;
  public newService: boolean;

  public parentName: string;
  public backgroundColor: string;
  public borderColor: string;
  public color: string;

  public lft: number;
  public lvl: number;
  public rgt: number;

  public regularPrice1: number;
  public regularPrice2: number;
  public regularPrice3: number;
  public regularPrice4: number;
  public regularPrice5: number;
  public regularPrice6: number;
  public regularPrice7: number;
  public regularPrice8: number;
  public regularPrice9: number;

  public currency: string = 'EUR';

  public dependencies: Dependencies | undefined = undefined;
  public parentDependencies: Dependencies | undefined = undefined;

  public recommendations: ProviderService[] = [];
  public discount: number = 0;

  public noShowProtection: boolean;
  public noShowMaxPenaltyPerPerson: number;
  public paymentRequired: boolean;
  public minSpendRequired: boolean;
  public minSpendAmount: number;
  public cancellationPeriod: number;

  public confirmationNeededPartySize: number;
  public contactUsPartySize: number;

  public start: string;

  public _self: string | undefined;

  public field: string | undefined;

  public fieldSlug: string;
  public categorySlug: string;
  // for recommendations
  public avatarHref: string | undefined;

  public parentSlug: string | undefined;
  public grandParentSlug: string | undefined;
  public grandGrandParentSlug: string | undefined;

  public loyaltyCycle: boolean;
  public loyaltyCycleDiscountPercentage: number;
  public loyaltyCyclePeriodInDaysBeforeTheNextVisit: number | null;
  public loyaltyCyclePeriodInDaysBeforeTheNextBooking: number | null;
  public loyaltyExpirationDate: string;

  public noLastMinuteDiscount: boolean;

  constructor(obj?: any) {
    this.id = (obj && obj.id) || null;
    this.parentId = (obj && obj.parentId) || null;
    this.grandParentId = (obj && obj.grandParentId) || null;
    this.grandGrandParentId = (obj && obj.grandGrandParentId) || null;
    this.url = (obj && obj.url) || null;
    this.name = (obj && obj.name) || null;
    this.parentName = (obj && obj.parentName) || null;
    this.backgroundColor = (obj && obj.backgroundColor) || null;
    this.borderColor = (obj && obj.borderColor) || null;
    this.color = (obj && obj.color) || null;
    this.slug = (obj && obj.slug) || null;
    this.descriptionButtonText = (obj && obj.descriptionButtonText) || null;
    this.nameTranslations = (obj && obj.nameTranslations) || null;
    this.descriptionButtonTextTranslations =
      (obj && obj.descriptionButtonTextTranslations) || null;
    this.root = (obj && obj.root) || null;
    this.taxonomy = (obj && obj.taxonomy) || null;
    this.duration = (obj && obj.duration / 60) || 0;
    this.fieldSlug = (obj && obj.fieldSlug) || null;
    this.categorySlug = (obj && obj.categorySlug) || null;
    this.avatarHref = (obj && obj.avatarHref) || null;
    this.parentSlug = (obj && obj.parentSlug) || null;
    this.grandParentSlug = (obj && obj.grandParentSlug) || null;
    this.grandGrandParentSlug = (obj && obj.grandGrandParentSlug) || null;
    this.loyaltyCycle = (obj && obj.loyaltyCycle) || false;
    this.loyaltyCycleDiscountPercentage =
      (obj && obj.loyaltyCycleDiscountPercentage) || 0;
    this.loyaltyCyclePeriodInDaysBeforeTheNextVisit =
      (obj && obj.loyaltyCyclePeriodInDaysBeforeTheNextVisit) || null;
    this.loyaltyCyclePeriodInDaysBeforeTheNextBooking =
      (obj && obj.loyaltyCyclePeriodInDaysBeforeTheNextBooking) || null;

    // if (this.taxonomy && this.taxonomy.length >= 5 ) {
    //   if ( this.taxonomy.substr(0,5) === '10_10' ) {
    //     this.field = 'FRIZERSKE STORITVE';
    //     this.fieldSlug = 'kozmeticne-storitve';
    //   } else {
    //     this.field = 'KOZMETIČNE STORITVE';
    //     this.fieldSlug = 'kozmeticne-storitve';
    //   }
    // }

    if (this.grandGrandParentSlug && this.grandParentSlug && this.parentSlug) {
      if (this.grandGrandParentSlug !== 'vse') {
        // if great grand parent is not vse, field is great grand parent
        this.field = this.humanize(this.grandGrandParentSlug);
        this.fieldSlug = this.grandGrandParentSlug;
        this.categorySlug = this.grandParentSlug;
      } else {
        // else field is grand parent
        this.field = this.humanize(this.grandParentSlug);
        this.fieldSlug = this.grandParentSlug;
        this.categorySlug = this.parentSlug;
      }
    } else if (this.grandParentSlug && this.parentSlug) {
      // if great grand parent is not defined, field is grand parent
      if (this.grandParentSlug !== 'vse') {
        // if grandparent is not vse
        this.field = this.humanize(this.grandParentSlug);
        this.fieldSlug = this.grandParentSlug;
        this.categorySlug = this.parentSlug;
      } else {
        //otherwise set a fake field
        this.field = 'Akcija';
        this.fieldSlug = 'akcija';
        this.categorySlug = this.parentSlug;
      }
    } else {
      // the "service" is an anomaly (category or field)
      if (this.parentName === 'FRIZERSKE STORITVE') {
        this.field = 'FRIZERSKE STORITVE';
        this.fieldSlug = 'frizerske-storitve';
        this.categorySlug = this.slug;
      } else if (this.parentName === 'KOZMETIČNE STORITVE') {
        this.field = 'KOZMETIČNE STORITVE';
        this.fieldSlug = 'kozmeticne-storitve';
        this.categorySlug = this.slug;
      } else if (this.parentName === 'Vse') {
        this.fieldSlug = this.slug;
      }
    }

    if (obj?.children) {
      for (const e of obj.children) {
        if (e instanceof ProviderService) {
          this.children.push(e);
        } else {
          this.children.push(new ProviderService(e));
        }
      }
    }

    this.childrenPickMin = (obj && obj.childrenPickMin) || null;

    if (obj && obj.minimalPrice) {
      this.minimalPrice =
        obj.minimalPrice instanceof PriceRange
          ? obj.minimalPrice
          : new PriceRange(obj.minimalPrice);
    }

    if (obj && obj.dependencies) {
      this.dependencies =
        obj.dependencies instanceof Dependencies
          ? obj.dependencies
          : new Dependencies(obj.dependencies);
    }

    if (obj && obj.parentDependencies) {
      this.parentDependencies =
        obj.parentDependencies instanceof Dependencies
          ? obj.parentDependencies
          : new Dependencies(obj.parentDependencies);
    }

    this.visibleForCustomers = (obj && obj.visibleForCustomers) || null;
    this.visibleForEditors = (obj && obj.visibleForEditors) || null;
    this.visibleByDefaultInAdmin = (obj && obj.visibleByDefaultInAdmin) || null;
    this.longDescription = (obj && obj.longDescription) || null;
    this.shortDescription = (obj && obj.shortDescription) || null;
    this.descriptionButtonText = (obj && obj.descriptionButtonText) || null;
    this.menuOpen = (obj && obj.menuOpen) || false;

    this.event = (obj && obj.event) || false;
    this.exclusiveEvent = (obj && obj.exclusiveEvent) || false;

    this.specialOffer = (obj && obj.specialOffer) || false;
    this.productPickRequired = (obj && obj.productPickRequired) || false;
    this.newService = (obj && obj.newService) || false;

    this.lft = (obj && obj.lft) || null;
    this.lvl = (obj && obj.lvl) || null;
    this.rgt = (obj && obj.rgt) || null;

    this.regularPrice1 = (obj && obj.regularPrice1) || null;
    this.regularPrice2 = (obj && obj.regularPrice2) || null;
    this.regularPrice3 = (obj && obj.regularPrice3) || null;
    this.regularPrice4 = (obj && obj.regularPrice4) || null;
    this.regularPrice5 = (obj && obj.regularPrice5) || null;
    this.regularPrice6 = (obj && obj.regularPrice6) || null;
    this.regularPrice7 = (obj && obj.regularPrice7) || null;
    this.regularPrice8 = (obj && obj.regularPrice8) || null;
    this.regularPrice9 = (obj && obj.regularPrice9) || null;

    this.currency = obj?.currency || 'EUR';

    this.noLastMinuteDiscount = (obj && obj.noLastMinuteDiscount) || false;

    if (obj?.recommendation1) {
      if (obj?.recommendationDiscount1) {
        obj.recommendation1.discount = obj.recommendationDiscount1;
      }
      this.recommendations.push(new ProviderService(obj.recommendation1));
    }
    if (obj?.recommendation2) {
      if (obj?.recommendationDiscount2) {
        obj.recommendation2.discount = obj.recommendationDiscount2;
      }
      this.recommendations.push(new ProviderService(obj.recommendation2));
    }
    if (obj?.recommendation3) {
      if (obj?.recommendationDiscount3) {
        obj.recommendation3.discount = obj.recommendationDiscount3;
      }
      this.recommendations.push(new ProviderService(obj.recommendation3));
    }
    if (obj?.recommendation4) {
      if (obj?.recommendationDiscount4) {
        obj.recommendation4.discount = obj.recommendationDiscount4;
      }
      this.recommendations.push(new ProviderService(obj.recommendation4));
    }

    this.noShowProtection = (obj && obj.noShowProtection) || null;
    this.noShowMaxPenaltyPerPerson =
      (obj && obj.noShowMaxPenaltyPerPerson) || null;
    this.paymentRequired = (obj && obj.paymentRequired) || null;
    this.minSpendRequired = (obj && obj.minSpendRequired) || null;
    this.minSpendAmount = (obj && obj.minSpendAmount) || null;

    this.cancellationPeriod = (obj && obj.cancellationPeriod) || null;

    this.start = (obj && obj.start) || null;

    if (obj && obj.avatar) {
      this.avatar =
        obj.avatar instanceof Image ? obj.avatar : new Image(obj.avatar);
    }

    if (obj && obj.gallery) {
      this.gallery = [];

      for (const image of obj.gallery) {
        if (image && image.document && image.document.type !== 'gallery') {
          //do not include avatars
          continue;
        }

        if (image instanceof Image) {
          this.gallery.push(image);
        } else {
          this.gallery.push(new Image(image));
        }
      }
    }

    //@todo Please remove after api deploy
    if (obj?.shortHair) {
      obj.confirmationNeededPartySize = 1;
    }

    this.confirmationNeededPartySize =
      (obj && obj.confirmationNeededPartySize) || null;
    this.contactUsPartySize = (obj && obj.contactUsPartySize) || null;

    if (obj && obj._self) {
      this._self = obj._self;
    } else if (obj && obj._links && obj._links.self && obj._links.self.href) {
      this._self = obj._links.self.href;
    }
  }

  isDiscountAvailable() {
    return (
      this?.regularPrice1 &&
      this?.minimalPrice?.lowestPrice &&
      this?.minimalPrice?.lowestPrice < this?.regularPrice1
    );
  }

  getHigherPrice(price: any): number {
    return +price;
  }

  isService() {
    return this.children.length === 0;
  }

  avatarUrl() {
    if (this.avatarHref) {
      return environment.docsUrl + '/' + this.avatarHref;
    } else if (this.avatar && this.avatar.url) {
      return this.avatar.url;
    } else {
      return '/assets/img/logo_v1.png';
    }
  }

  serviceUrl() {
    const url = `${environment.siteUrl}/${this.fieldSlug}/${this.categorySlug}/${this.slug}`;
    return url;
  }

  humanize(value: string): string {
    if (typeof value !== 'string') {
      return value;
    }

    value = value.replace(/[-_0-9]+/g, ' ');
    //value = value.split(/(?=[A-Z])/).join(' ');
    value = value.toLocaleLowerCase();
    value = value[0].toUpperCase() + value.slice(1);
    return value;
  }

  isProbablyA(what: string) {
    const val = this.taxonomy?.length || 12;
    let elementIs = 'service';

    if (this.parentSlug && this.grandParentSlug && this.grandGrandParentSlug) {
      if (this.grandGrandParentSlug === 'vse') {
        elementIs = 'category';
      } else {
        elementIs = 'service';
      }
    } else if (
      (this.parentSlug && this.grandParentSlug) ||
      this.categorySlug == this.slug
    ) {
      elementIs = 'category';
    } else if (this.parentSlug) {
      elementIs = 'field';
    }

    // if (val < 3 || this.parentName == 'Vse') {
    //   elementIs = "field";
    // } else if (val < 6) {
    //   elementIs = "category";
    // } else if (val < 9) {
    //   elementIs = "corner";
    // } else {
    //   elementIs = "service";
    // }

    return elementIs === what;
  }

  recommendationDiscount(provider) {
    return provider?.recommendationsDiscount || this.discount;
  }

  recommendationDiscountedPrice(provider) {
    if (this.minimalPrice?.lowestPrice) {
      return (
        (this.minimalPrice?.lowestPrice || 0) -
        (this.recommendationDiscount(provider) *
          (this.minimalPrice?.lowestPrice || 0)) /
          100
      );
    } else {
      return 0;
    }
  }

  loyaltyDiscount() {
    return this.loyaltyCycleDiscountPercentage || this.discount;
  }

  loyaltyCycleDiscountedPrice() {
    if (this.loyaltyCycle) {
      return (
        (this.minimalPrice?.lowestPrice || this.regularPrice1) *
        (1 - (this.loyaltyCycleDiscountPercentage || 0) / 100)
      );
    } else {
      return this.regularPrice1;
    }
  }

  getDateStringForLoyaltyExpiration(dateString: string) {
    return moment(dateString).format('DD. MM. YYYY, HH:mm');
  }
}