import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  transform(value: number, singular: string, dual: string, plural: string): string {
    if (value === 1) {
      return singular;
    } else if (value === 2) {
      return dual;
    } else {
      return plural;
    }
  }
}
