import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Provider } from "../core/models/provider.model";

@Component({
  selector: "app-gallery-paginator-component",
  templateUrl: "./gallery-paginator.component.html",
  styleUrls: ["./gallery-paginator.component.scss"],
})
export class GalleryPaginatorComponent implements OnInit {
  @Input() page: number | 0;
  @Input() gallery?: any;

  active = 0;

  constructor() {
    this.page = 0;
  }
  ngOnInit(): void {
  }

  get currentPage () {
    return this.active;
  }

  get nextPage() {
    this.active = this.page + 1;
    return this.active;
  }
}
