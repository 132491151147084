import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Article } from '../../core/models/article.model';
import { ArticleService } from '../../core/services/article.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-relevant',
  templateUrl: './relevant.component.html',
  styleUrls: ['./relevant.component.scss'],
  providers: [ ArticleService ]
})
export class RelevantComponent  implements OnInit {


  articles$: Observable<Article[]>;

  constructor(
    private articleService: ArticleService,
  ) {
    this.articles$ = this.articleService.getArticles$( null );
  }

  ngOnInit() {}


  getImageUrl(id) {

    if (id === 1) {
      return 'https://docs.api.pricepilot.io/655b2a9f21e76.jpeg'; 
    }

    if (id === 3) {
      return 'https://docs.api.pricepilot.io/655b1d3703b36.jpeg'; 
    }

    if (id === 4) {
      return 'https://docs.api.pricepilot.io/655b1be684ca8.jpeg'; 
    }

    return 'https://docs.api.pricepilot.io/652dadba274e4.jpeg';

  }

}
