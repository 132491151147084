<div class="spacer_3"></div>

<footer>
  <div class="menu">
    <a href="/frizerske-storitve">Frizerske storitve</a>
    <a href="/kozmeticne-storitve">Kozmetične storitve</a>
    <a href="/aktualno">Aktualno</a>
    <a href="/galerija">Galerija</a>
    <a href="/ekipa">Ekipa</a>
    <a href="/saloni">Saloni</a>
  </div>
  <div class="phone"><a href="{{ 'tel:'+(provider?.phone1 | ppPhone) }}">{{ provider?.phone1 | ppPhone }}</a></div>
  <div class="c"></div>
  
  <a href="https://www.instagram.com/dmstudio.slo/" target="_blank"><div class="social instagram"></div></a>
  <a href="https://www.facebook.com/dm-Studio-101838195004853" target="_blank"><div class="social facebook"></div></a>
  <a href="https://www.tiktok.com/@dm_slovenija" target="_blank"><div class="social tiktok"></div></a>
  <div class="c"></div>
  <div class="copyright">v1.3.3 &copy; 2024 dm drogerie markt d.o.o.<span>&bull;</span>Moja drogerija za lepoto, frizuro in kozmetiko, nego, dojenčka & otroka in še mnogo več.<span>&bull;</span> <a href="/varstvo-podatkov">Varstvo podatkov</a></div>

  <div class="poweredby">powered by <a href="https://pricepilot.io">PricePilot</a></div>

  <!--div class="copyright" style="font-weight: bold; text-decoration: underline; padding-bottom: 20px;"><a href="https://saloni.dm.si">POVEZAVA NA STARO SPLETNO STRAN</a></div-->

</footer>
