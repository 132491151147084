import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'onlyVisibleForCustomer'
})
export class OnlyVisibleForCustomerPipe implements PipeTransform {
  transform(items: Array<any>): any {
    if (!items) {
      return items;
    }

    // return items.filter(item => item.visibleForCustomers === true && item.regularPrice1>0);
    return items.filter(item => item.visibleForCustomers === true);
  }
}
