import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/sl';
import {ShopDetailService} from '../../../core/services/shop-detail.service';
import {Employee} from '../../../core/models/employee.model';
import {Pick} from '../../../core/models/cart/pick.model';
import * as equal from 'fast-deep-equal';
import {Slot} from 'src/app/core/models/cart/slot.model';
import {Combination} from 'src/app/core/models/cart/combination.model';
import {CombinationAttendeeService} from 'src/app/core/models/cart/combination-attendee-service.model';
import {DOCUMENT} from '@angular/common';
import {Real} from "../../../core/models/real.model";

@Component({
  selector: 'app-slots, [app-slots]',
  templateUrl: './slots.component.html',
  styleUrls: ['./slots.component.scss'],
})
export class SlotsComponent implements OnInit {

  @Input() slots$!: Observable<Slot[]>;
  @Input() slotCombinations$: Observable<Combination[]> | undefined;
  @Input() selectedTime: string | undefined;
  @Input() pick: Pick | undefined;
  @Input() segment: string | undefined;
  @Input() real: Real | undefined;

  @Output() onSlotPicked = new EventEmitter<Slot>();

  employees: Employee[] | undefined;

  employeesMap: Map<string,Employee> = new Map();
  sortOrderAsc: boolean = true;

  constructor( private shopDetailService: ShopDetailService,
    @Inject(LOCALE_ID) protected localeId: string,
    @Inject(DOCUMENT) private document: Document)  {

    // console.log(localeId);

    shopDetailService.employees$.subscribe(
      employees => {
        this.employees = employees;
        this.employeesMap = this.shopDetailService.generateEmployeesMap( employees );
      }
    );

  }

  ngOnInit() {
  }


  isPickSlotStepCompleted() {
    return this.pick?.combination;
  }

  getTime( dateString: moment.MomentInput ) {
    return moment( dateString ).format('HH:mm');
  }

  isCombinationPicked( combination: Combination ) {
    if ( !this.pick?.combination || !combination.attendees || combination.attendees.length === 0 ) {
      return false;
    }
    return ( equal( this.pick.combination.attendees, combination.attendees ) );
  }

  isSlotPicked( slot: Slot ) {
    if ( !this.pick?.combination ) {
      return false;
    }
    const sameSlot = ( this.pick.combination.start == slot.start );
    return sameSlot;
    //return ( this.selectedTime == slot.start );
  }

  // determine if current slot.start time is a full hour using moment.js
  isDisplayedImmediately(_slot: Slot,_idx: number) {
    // idx for fallback, because some slots don't have full hour at all.. let's return every 4th slot by default for now
    //if (idx % 4 === 0) {
    return true;
    //}
    // Add a default return statement
    //return false;
  }

  hour(slot: Slot) {
    return moment(slot.start).hours();
  }
  expandHour(slot: Slot) {

    document.querySelectorAll('.line').forEach((line:Element) => {
      if (line instanceof HTMLElement) {
        if (parseInt(line.dataset.hour || "0") === this.hour(slot)) {
          line.classList.remove('collapsed');
        }
      }
    });
  }

  areThereMoreEmployeesPerAttendee( services: CombinationAttendeeService[] ) {

    if ( !services || services.length < 2 ) {
      return false;
    }

    let employee;

    for( const s of services ) {

      if (!employee) {
       employee = s.employeeHref;
      } else if ( employee !== s.employeeHref ) {
       return true;
      }

    }

    return false;

  }

  //@todo refactor -> same method in summary.component
  getAvatarFileFromEmployeeHref( href: string ) {

    if ( !href ) {
      return null;
    }

    const employeeId = href.substr( href.lastIndexOf("/") + 1 );
    if ( this.employeesMap?.get(employeeId) ) {
      return this.employeesMap?.get(employeeId)?.avatar?.url;
    }

    return null;
  }


  getNameFromEmployeeHref( href: string ) {

    if ( !href ) {
      return '';
    }

    const employeeId = href.substr( href.lastIndexOf("/") + 1 );
    if ( this.employeesMap?.get(employeeId) ) {
      return this.employeesMap?.get(employeeId)?.shortName;
    }

    return '';
  }

  toggleSlot( slot: Slot, event: Event ) {
    let slotEl = event.target as HTMLElement;
    if (!slotEl.classList.contains('line')) {
      slotEl = slotEl.closest('.line') as HTMLElement;
    }
    const gridPosition = this.getGridElementsPosition(this.getNodeIndex(slotEl));
    const quick = document.querySelector('.quick') as HTMLElement;
    quick.style.gridColumnStart = '1';
    quick.style.gridColumnEnd = '-1';
    quick.style.gridRowStart = (gridPosition.row+2).toString();

    this.onSlotPicked.emit( slot );

    if (!this.isPickSlotStepCompleted()) {
      quick.style.height = '0px';
      quick.style.opacity = '0';
      quick.style.padding = '0px';
      quick.style.border='none';
    } else {
      quick.style.height = 'auto';
      quick.style.opacity = '1';
      //quick.style.padding = '8px';
      //quick.style.border='2px solid #d9bb93';
    }

  }

  getGridElementsPosition(index) {
    const gridEl = document.querySelector("app-slots") as HTMLElement;

    // our indexes are zero-based but gridColumns are 1-based, so subtract 1
    const gridElement = gridEl.children[0] as HTMLElement;
    let offset = Number(window.getComputedStyle(gridElement).gridColumnStart) - 1;

    // if we haven't specified the first child's grid column, then there is no offset
    if (isNaN(offset)) {
      offset = 0;
    }
    const colCount = window.getComputedStyle(gridEl).gridTemplateColumns.split(" ").length;

    const rowPosition = Math.floor((index + offset) / colCount);
    const colPosition = (index + offset) % colCount;

    //Return an object with properties row and column
    return { row: rowPosition, column: colPosition };
  }

  getNodeIndex(elm) {
    let c = elm.parentNode.children, i = 0;
    for (; i < c.length; i++) if (c[i] == elm) return i;
    return -1; // Add a return statement to handle the case when the element is not found
  }

  toggleCombination( c: Combination ) {
    this.pick?.setCombination( c );
    //console.log(this.pick?.combination);

  }

  sortByDuration(slot: Combination[], field: string) {
    const sorted = slot.sort((a: Combination, b: Combination) => {
      return a[field] - b[field];
    });
    this.sortOrderAsc = !this.sortOrderAsc;
    return (this.sortOrderAsc) ? sorted : sorted.reverse();
  }

  sortByEmployee(slot: Combination[], attendeeIndex: number) {
    const sorted = slot.sort((a: Combination, b: Combination) => {
      return a.attendees![attendeeIndex].services![0].employeeHref.localeCompare(b.attendees![attendeeIndex].services![0].employeeHref);
    });
    this.sortOrderAsc = !this.sortOrderAsc;
    return (this.sortOrderAsc) ? sorted : sorted.reverse();
  }

  isLastMinute(slot: Slot) {
    if (this.real) {
      if (this.real.lastMinute) {
        // console.log('isLastMinuteSlot', isLastMinuteSlot, moment().add(this.real.lastMinutePeriodInMinutesFromNow,'minutes'));
        return moment(slot.start).isBefore(moment(moment().add(this.real.lastMinutePeriodInMinutesFromNow, 'minutes')));
      }
      return false;
    }
    return false;
  }
}
