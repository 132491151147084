import { Dependencies } from './dependencies.model';
import { Links } from './links.model';
import { PriceRange } from '../price-range.model';
import { Image } from '../image.model';
import { environment } from 'src/environments/environment';

export class Service {
  public id: number;
  public url: string;
  public name: string;
  public duration: number;
  public parentId: number;
  public dependencies: Dependencies;
  public executionOrder: number;
  public parent: Service;
  public parentDependencies: Dependencies;
  public childrenPickMax: number;
  public _links: Links;
  public avatar: Image | undefined;
  public regularPrice1: number | 0;
  public minimalPrice: PriceRange | undefined = undefined;
  public borderColor: string;
  public slug: string;
  public _self: string;
  public fieldSlug: string;
  public categorySlug: string;
  public recommendations: Service[] = [];
  public longDescription: string;
  // fore recommendations
  public avatarHref: string;
  public discount: number;
  public specialOffer: boolean = false;
  public loyaltyCycle: boolean | undefined;
  public loyaltyCycleDiscountPercentage: number | undefined;
  public loyaltyCyclePeriodInDaysBeforeTheNextVisit: number | null;
  public loyaltyCyclePeriodInDaysBeforeTheNextBooking: number | null;
  public loyaltyExpirationDate: string;

  constructor(obj?: any) {
    this.id = (obj && obj.id) || null;
    this.url = (obj && obj.url) || null;
    this.name = (obj && obj.name) || null;
    this.duration = (obj && obj.duration) || null;
    this.parentId = (obj && obj.parentId) || null;
    this.dependencies = (obj && obj.dependencies) || null;
    this.executionOrder = (obj && obj.executionOrder) || null;
    this.parentDependencies = (obj && obj.parentDependencies) || null;
    this.parent = (obj && obj.parent) || null;
    this.childrenPickMax = (obj && obj.childrenPickMax) || null;
    this._links = (obj && obj._links) || null;
    this.regularPrice1 = (obj && obj.regularPrice1) || null;
    this.borderColor = (obj && obj.borderColor) || null;
    this.slug = (obj && obj.slug) || null;
    this._self =
      (obj && obj._self) ||
      (obj && obj._links && obj._links.self && obj._links.self.href) ||
      null;
    this.fieldSlug = (obj && obj.fieldSlug) || null;
    this.categorySlug = (obj && obj.categorySlug) || null;
    this.longDescription = (obj && obj.longDescription) || null;
    this.avatarHref = (obj && obj.avatarHref) || null;
    this.discount = (obj && obj.discount) || null;
    this.specialOffer = (obj && obj.specialOffer) || false;
    this.loyaltyCycle = (obj && obj.loyaltyCycle) || false;
    this.loyaltyCycleDiscountPercentage =
      (obj && obj.loyaltyCycleDiscountPercentage) || null;
    this.loyaltyCyclePeriodInDaysBeforeTheNextVisit =
      (obj && obj.loyaltyCyclePeriodInDaysBeforeTheNextVisit) || null;
    this.loyaltyCyclePeriodInDaysBeforeTheNextBooking =
      (obj && obj.loyaltyCyclePeriodInDaysBeforeTheNextBooking) || null;

    if (obj && obj.avatar) {
      this.avatar =
        obj.avatar instanceof Image ? obj.avatar : new Image(obj.avatar);
    }

    if (obj && obj.minimalPrice) {
      this.minimalPrice =
        obj.minimalPrice instanceof PriceRange
          ? obj.minimalPrice
          : new PriceRange(obj.minimalPrice);
    }

    if (obj && obj.dependencies) {
      this.dependencies =
        obj.dependencies instanceof Dependencies
          ? obj.dependencies
          : new Dependencies(obj.dependencies);
    }

    if (obj && obj.parentDependencies) {
      this.parentDependencies =
        obj.parentDependencies instanceof Dependencies
          ? obj.parentDependencies
          : new Dependencies(obj.dependencies);
    }

    if (obj?.recommendations) {
      for (const e of obj.recommendations) {
        if (e instanceof Service) {
          this.recommendations.push(e);
        } else {
          this.recommendations.push(new Service(e));
        }
      }
    }
  }

  avatarUrl() {
    if (this.avatarHref) {
      return environment.docsUrl + '/' + this.avatarHref;
    } else if (this.avatar && this.avatar.url) {
      return this.avatar.url;
    } else {
      return '/assets/img/logo_v1.png';
    }
  }

  static revive(json: any): Service {
    const dependencies = json.dependencies
      ? Dependencies.revive(json.dependencies)
      : undefined;
    const parentDependencies = json.parentDependencies
      ? Dependencies.revive(json.parentDependencies)
      : undefined;
    const parent = json.parent ? Service.revive(json.parent) : undefined;
    const _links = json._links ? Links.revive(json._links) : undefined;
    const avatar = json.avatar ? Image.revive(json.avatar) : undefined;
    const minimalPrice = json.minimalPrice
      ? PriceRange.revive(json.minimalPrice)
      : undefined;
    const recommendations = json.recommendations
      ? json.recommendations.map((e: any) => Service.revive(e))
      : undefined;
    return new Service({
      ...json,
      dependencies,
      parentDependencies,
      parent,
      _links,
      avatar,
      minimalPrice,
      recommendations,
    });
  }

  isDiscountAvailable() {
    return (
      this?.regularPrice1 &&
      this?.minimalPrice?.lowestPrice &&
      this?.minimalPrice?.lowestPrice < this?.regularPrice1
    );
  }

  getHigherPrice(price: any): number {
    return +price;
  }

  recommendationDiscount(provider) {
    return provider?.recommendationsDiscount || this.discount;
  }

  recommendationDiscountedPrice(provider) {
    if (this.minimalPrice?.lowestPrice) {
      return (
        (this.minimalPrice?.lowestPrice || 0) -
        (this.recommendationDiscount(provider) *
          (this.minimalPrice?.lowestPrice || 0)) /
          100
      );
    } else {
      return 0;
    }
  }

  loyaltyDiscount() {
    return this.loyaltyCycleDiscountPercentage || this.discount;
  }

  serviceUrl() {
    const url = `${environment.siteUrl}/${this.fieldSlug}/${this.categorySlug}/${this.slug}`;
    return url;
  }

  loyaltyCycleDiscountedPrice() {
    if (this.loyaltyCycle) {
      return (
        (this.minimalPrice?.lowestPrice || 0) *
        (1 - (this.loyaltyCycleDiscountPercentage || 0) / 100)
      );
    } else {
      return this.regularPrice1;
    }
  }
}
