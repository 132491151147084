<div class="aktualno mt-40" *ngIf="articles$ | async as articles">
  <div class="spacer_2 mt-20"></div>
  <h2 style="text-transform: none !important;">AKTUALNO</h2>
  <div
    *ngFor="let article of articles.slice(0, 3)"
    class="item clickable"
    [routerLink]="['/', 'aktualno', article?.slug]"
  >
    <div class="img">
      <img
        src="{{
          article?.avatar?.url ||
            getImageUrl(article?.id) ||
            'https://docs.api.pricepilot.io/652dadba274e4.jpeg'
        }}"
        alt="{{ article?.topic }} - {{ article?.name }} - {{ article?.shortLead }} | dm Studio"
      />
    </div>
    <i class="uppercase">{{ article.topic }}</i
    ><b>{{ article.name }}</b
    ><span>{{ article.shortLead }}</span>
  </div>
  <div class="buttons">
    <a href="/aktualno" class="button clickable" [routerLink]="['/aktualno']">Prikaži več vsebin</a>
  </div>


  <!--div class="item"><div class="img"><img src="assets/img/img_3.png"></div><i>NEGA KOŽE</i><b>Paž frizure 2023: sveže ideje za preobrazbo</b><span>Paž frizure se vračajo v velikem slogu z dodatkom prefinjenih detajlov.</span></div>
  <div class="item"><div class="img"><img src="assets/img/img_4.png"></div><i>NEGA KOŽE</i><b>Privoščite si nego las s pilingom lasišča in fen frizuro</b><span>Paž frizure se vračajo v velikem slogu z dodatkom prefinjenih detajlov.</span></div-->
</div>
