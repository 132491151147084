import { Component, OnInit, Input, ElementRef, ViewChild, Inject, OnChanges } from '@angular/core';
import { Image } from 'src/app/core/models/image.model';
import { PriceRange } from 'src/app/core/models/price-range.model';
import { ProviderService } from 'src/app/core/models/provider-service.model';
import { Document as DocumentModel } from 'src/app/core/models/document.model';
import { DOCUMENT } from '@angular/common';
import { CartService } from 'src/app/core/services/cart.service';
import { Price } from 'src/app/core/models/cart/price.model';
import { MyBookingsService } from 'src/app/core/services/my-bookings.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss'],
})
export class ServiceCardComponent  implements OnInit, OnChanges {
  @ViewChild('itemToBeAdded') itemToBeAdded: ElementRef | undefined;
  @Input() isNew: boolean = false;
  @Input() field: string = "";
  @Input() name: string = "";
  @Input() longDescription: string = "";
  @Input() image: string = "";
  @Input() regularPrice: number = 0;
  @Input() price: number = 0;
  @Input() currency: string = "EUR";
  @Input() service: ProviderService | undefined = undefined;
  @Input() categorySlug: string = "";
  @Input() fieldSlug: string = "";
  @Input() recommendedDiscount: number = 0;
  recommendedDiscountedPrice: number = 0;
  isReady: boolean = false;
  loyaltyCycleDiscountedPrice: number = 0;

  private loyaltySubscription: Subscription | null = null;

  jsonLD: any;

  constructor(public cartService: CartService,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    private myBookingsService: MyBookingsService) {}

  ngOnInit(): void {
    if(!this.service) {
      this.service = new ProviderService();

      this.service.newService = this.isNew;
      this.service.field = this.field;
      this.service.name = this.name;
      this.service.longDescription = this.longDescription;
      this.service.avatar = new Image({document: new DocumentModel({fileName: this?.image || '652dadba274e4.jpeg'})});
      this.service.regularPrice1 = this.price;
      this.service.minimalPrice = new PriceRange({lowestPrice: this.regularPrice});
      this.service.currency = this.currency;

    }
    //this.service.categorySlug = this.categorySlug;
    //this.service.fieldSlug = this.fieldSlug;
    this.isReady = true;


    if (this.recommendedDiscount > 0) {
      this.service.discount = this.recommendedDiscount;
    }
    this.recommendedDiscountedPrice = (this.service?.minimalPrice?.lowestPrice || 0) * (1 - (this.recommendedDiscount) / 100);
    this.setJsonLd(this.service);

    this.loyaltySubscription = this.myBookingsService.pastServices$.subscribe((services) => {
      this.cartService.loyaltyServices.next(services);

      const hasLoyaltyDiscount = services.get(this.service?.id+"") ;
      // console.log('hasLoyaltyDiscount', hasLoyaltyDiscount);
      if (this.service && hasLoyaltyDiscount) {
        //console.log('hasLoyaltyDiscount', hasLoyaltyDiscount);
        this.service.loyaltyCycle = true;
        this.service.loyaltyCycleDiscountPercentage = hasLoyaltyDiscount[1];
        this.service.loyaltyExpirationDate = moment(hasLoyaltyDiscount[3]).format('LLLL');
        try {
          this.service.discount = hasLoyaltyDiscount[1];
          // this.service.minimalPrice!.lowestPrice =
          //   (this.service?.regularPrice1 || 0) *
          //   (1 - hasLoyaltyDiscount[1] / 100);
        } catch (e) {
           console.error('Error setting loyalty discount', e);
        }
        // console.log('this.service', this.service.minimalPrice?.lowestPrice, this.service.regularPrice1, this.service.discount);
        this.loyaltyCycleDiscountedPrice =
          (this.service?.minimalPrice?.lowestPrice || this.service?.regularPrice1) *
          (1 -
            (this.service?.loyaltyCycleDiscountPercentage || 0) / 100);

      }
    });

  }

  ngOnDestroy() {
    if (this.loyaltySubscription) {
      this.loyaltySubscription.unsubscribe();
    }

  }

  ngOnChanges(changes: any) {
    if (changes.recommendedDiscount && changes.recommendedDiscount.currentValue > 0) {
      if(this.service) {
        this.service.discount = changes.recommendedDiscount.currentValue;
      }
      this.recommendedDiscountedPrice =
      (this.service?.minimalPrice?.lowestPrice || 0) *
      (1 - this.recommendedDiscount / 100);
    }
  }

  animateAddToCart() {
    const r = document.querySelector(':root');
    //const itemToBeAdded = document.getElementById('itemToBeAdded');
    //const itemToBeAdded = this.itemToBeAdded?.nativeElement;
    const itemToBeAdded = document.querySelectorAll('.itemToBeAdded')[document.querySelectorAll('.itemToBeAdded').length-1] as HTMLElement;
    //console.log("animateAddToCart", itemToBeAdded);

    if (itemToBeAdded) {
      // use card image
      (itemToBeAdded as HTMLImageElement).src=this.elementRef.nativeElement.querySelector('.img img').src;
      // set position
      itemToBeAdded.style.top = (this.elementRef.nativeElement.getBoundingClientRect().top+this.elementRef.nativeElement.getBoundingClientRect().height/2) + 'px';
      itemToBeAdded.style.left = (this.elementRef.nativeElement.getBoundingClientRect().left+this.elementRef.nativeElement.getBoundingClientRect().width/2) + 'px';

      // set starting position for animation
      (r as HTMLElement).style.setProperty('--moveToCartX', (this.elementRef.nativeElement.getBoundingClientRect().left+this.elementRef.nativeElement.getBoundingClientRect().width/2)+'px');
      (r as HTMLElement).style.setProperty('--moveToCartY', (this.elementRef.nativeElement.getBoundingClientRect().top+this.elementRef.nativeElement.getBoundingClientRect().height/2)+'px');

      // apply animation
      itemToBeAdded.classList.add('drop-into-cart');
      // remove animation after 2 seconds
      setTimeout(() => {
        itemToBeAdded.classList.remove('drop-into-cart');
        itemToBeAdded.style.top = -100+'px';
      }, 2000);
    }
  }

  setJsonLd(service: ProviderService) {

    this.jsonLD = {
      "@context": "https://schema.org",
      "@type": "Product",
      "serviceType": service.name,
      "name": service.name,
      "image": service.avatarUrl(),
      "description": service.longDescription,
      "logo": "https://www.dmstudio.si/assets/images/logo_v1.png",
      "provider": {
        "@type": "Organization",
        "name": "dm Studio"
      },
      "offers": {
        "@type": "Offer",
        "url": service.serviceUrl(),
        "priceCurrency": "EUR",
        "price": service.minimalPrice?.lowestPrice || 0,
        "priceValidUntil": moment().add(1, 'days').format('YYYY-MM-DD'),
        "availability": "https://schema.org/InStock",
        "seller": {
          "@type": "Organization",
          "name": "dm Studio"
        }
      }
    };
  }
}
