import { Component, Input } from "@angular/core";
import { Employee } from "../core/models/employee.model";
import { Provider } from "../core/models/provider.model";


@Component({
  selector: 'employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent {
  @Input() employee: Employee | undefined = undefined;
  @Input() provider: Provider | undefined = undefined;

  constructor() {}
}


