import { Price } from "./price.model"
import { CombinationAttendeeService } from "./combination-attendee-service.model"
import * as moment from 'moment';

export class CombinationAttendee {
  public services: CombinationAttendeeService[] | undefined;
  public start: string | undefined;
  public end: string | undefined;
  public durationInSeconds: number | undefined;
  public total: Price | undefined;
  public name: string | undefined;

  constructor(
    obj?: any
  ) {
    if ( obj && obj.services ) {
      this.services = [];
      this.name = obj.name;

      for (let service of obj.services ) {
        // console.log('service', service);
        let s = ( service instanceof CombinationAttendeeService ) ? service: new CombinationAttendeeService( service );

        if ( !this.start || moment(s.start) < moment(this.start) ) {
          this.start = s.start;
        }

        if ( !this.end || moment(s.end) > moment(this.end) ) {
          this.end = s.end;
        }

        let ms = moment(this.start);
        let me = moment(this.end);

        this.durationInSeconds = me.diff(ms, 'seconds');

        let priceAmount = (s.price && s.price.amount) ?  s.price.amount: 0.00;

        if (!s.price || !s.price.amount || !s.price.currency) {
          new Price( 0, 'EUR' );
          //continue;
          if (!this.total) {
            this.total = new Price( s.price?.amount || 0, s.price?.currency || 'EUR' );
          } else {
            this.total.amount += s.price?.amount || 0;
          }
        }

        this.services.push( s );
      }
    }
  }

  static revive(json: any): CombinationAttendee {
    // console.log("services",json);
    const services = json.services.map( (service: any) => CombinationAttendeeService.revive(service) );
    const total = json.total ? Price.revive(json.total) : undefined;
    const combinationAttendee = new CombinationAttendee({...json});
    combinationAttendee.services = services;
    combinationAttendee.total = total;
    //console.log(combinationAttendee);
    return combinationAttendee;
    //return new CombinationAttendee({...json, services, total});
  }
}
