import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({
  name: 'removeZeros'
})
export class RemoveZerosPipe implements PipeTransform {

  transform(value: string | null): any {
    if (!value) {
      return value;
    }

    // remove zeros from the end of the string if there is '.00'
    value = value.replace(/\,00$/, '');
    value = value.replace(/\.00$/, '');


    return value;
  }
}