import { ExecutionTimeRange } from './executionTimeRange.model';

export class Open   {
  
  public id: string;
  public executionTimeRange: ExecutionTimeRange;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.executionTimeRange = obj && obj.executionTimeRange || null;
  }
  
}