<ion-header class="ion-padding header header-modal -primary" mode="md">
  <ion-toolbar class="ion-no-padding" mode="ios" color="primary">
    <ion-title class="header-title ion-text-left">Storitev</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()">
        <ion-icon name="close" mode="md"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding search-modal" color="primary">
  <ion-searchbar #serviceSearchBar value="" (ionChange)="searchFilter$.next(serviceSearchBar!.value!)" placeholder="Poišči storitev..." class="search-modal__search" mode="md"></ion-searchbar>

  <ion-list lines="none" color="primary" class="search-modal__list">
    <ng-container *ngFor="let service of services$ | async">
      <ion-item  *ngIf="service.level===1" (click)="onSelected(service)" color="primary" class="clickable search-modal__list-item search-modal__list-item--main">
      <b>{{ service.name }}</b>
      </ion-item>
      <ion-item *ngIf="service.level > 1 && ( service.default || serviceSearchBar!.value!.length > 0 )" (click)="onSelected(service)" color="primary" class="clickable search-modal__list-item">{{ service.name }}</ion-item>
    </ng-container>
  </ion-list>

</ion-content>
<!--
<ion-header>
  <ion-toolbar mode="ios">
    <ion-title >Izberi storitev</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCancel()"><ion-icon name="close"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>
<ion-content>
  <ion-list>
    <ion-item *ngFor="let service of services" (click)="onSelected(service)">
      <ion-label><span *ngIf="service.parentId" style="padding-left: 25px; padding-right: 10px;">-</span><span *ngIf="searchPreferences.normalizedService?.id === service.id">*</span>{{ service.name }}</ion-label>
    </ion-item>
    <ion-item (click)="onSelected( null )">
      <ion-label>Vse storitve (ne vem kaj izbrati)</ion-label>
    </ion-item>
  </ion-list>
</ion-content>-->