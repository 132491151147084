import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform

{
  transform(items: any[]): any[] {
    const uniqueItems = new Set();
    const filteredItems: any[] = [];
    console.log('items', items);

    for (const item of items) {
      const itemId = item.selectionItem.id; // Assuming the item has an 'id' property

      console.log(uniqueItems);
      if (!uniqueItems.has(itemId)) {
        uniqueItems.add(itemId);
        filteredItems.push(item);
      }
    }

    return filteredItems;
  }
}