import { Employee } from './employee.model';

export class Worker {
  
  public id: string;
  public title: string;
  public employee: Employee;
  public _self: string;

  constructor(
    obj?: any 
  ) {
    this.id = obj && obj.id || null;
    this.title = obj && obj.title || null;

    if ( obj && obj.item ) {
      this.employee =  new Employee( obj.item );
    }

    if ( obj && obj.employee && obj.employee instanceof Employee ) {
      this.employee =  obj.employee;
    }

    if ( obj && obj._self ) {
      this._self = obj._self;
    } else if ( obj && obj._links && obj._links.self && obj._links.self.href ) {
      this._self = obj._links.self.href;
    }

  }
  
}